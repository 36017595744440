import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

const useUnSavedChanges = (message) => {
  const [unSaved, setUnSaved] = useState(false);

  useEffect(() => {
    window.onbeforeunload = unSaved && (() => message);

    return () => {
      window.onbeforeunload = null;
    };
  }, [unSaved]);

  const routerPrompt = <Prompt when={unSaved} message={message} />;

  return [routerPrompt, () => setUnSaved(true), () => setUnSaved(false)];
};

export default useUnSavedChanges;
