import React from 'react';
import { useTranslation } from 'react-i18next';

const useConstants = () => {
  const { t } = useTranslation('common');
  const formHasError = {
    1.3: false,
    3: false,
    4: false,
    2.1: false,
    2.3: false,
    2.5: false,
    5: false,
    6: false,
    11: false,
  };

  const validateForm = (form) => {
    let res = formHasError;
    if (!form[1.3]) {
      res = { ...res, 1.3: true };
    }
    if (!form[3]) {
      res = { ...res, 3: true };
    }
    if (!form[4]) {
      res = { ...res, 4: true };
    }
    if (!form[2.1]) {
      res = { ...res, 2.1: true };
    }
    if (!form[2.3]) {
      res = { ...res, 2.3: true };
    }
    if (!form[2.5]) {
      res = { ...res, 2.5: true };
    }
    if (!form[5]) {
      res = { ...res, 5: true };
    }
    if (!form[6]) {
      res = { ...res, 6: true };
    }
    if (!form[11]) {
      res = { ...res, 11: true };
    }

    let keys = Object.keys(res).filter((k) => res[k] === true);
    for (let i = 0; i < keys.length; i++) {
      if (res[keys[i]]) {
        return { errors: res, isValid: false };
      }
    }
    return { errors: res, isValid: true };
  };

  const validateEmail = (email) => {
    let reg = /\S+@\S+\.\S+/;
    return reg.test(email);
  };

  const validateDate = (date) => {
    let reg = /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/i;
    return reg.test(date);
  };

  const validatePhone = (phoneNo) => {
    let reg = /^[+]([0-9]{12})$/;
    if (phoneNo.match(reg)) return true;
    return false;
  };

  return {
    taskDetails: {
      id: Math.floor(Math.random() * 99999),
      1.3: '',
      3: '',
      4: '',
      2.1: '',
      2.3: '',
      2.5: '',
      13: t('task_form.person'),
      5: '',
      6: '',
      11: '',
      7: '',
      9: t('task_form.not_urgent'),
      14: '',
      15: '',
      16: '',
      17: '',
    },
    subscribers: [
      {
        id: 1,
        value: t('task_form.person'),
        label: t('task_form.person'),
      },
      {
        id: 2,
        value: t('task_form.company'),
        label: t('task_form.company'),
      },
    ],
    urgency: [
      {
        id: 1,
        value: t('task_form.not_urgent'),
        label: t('task_form.not_urgent'),
      },
      {
        id: 2,
        value: t('task_form.bit_urgent'),
        label: t('task_form.bit_urgent'),
      },
      {
        id: 3,
        value: t('task_form.urgent'),
        label: t('task_form.urgent'),
      },
    ],
    formHasError: formHasError,
    validateForm: validateForm,
    validateEmail: validateEmail,
    validateDate: validateDate,
    validatePhone: validatePhone,
  };
};

export { useConstants };
