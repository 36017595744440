import React from 'react'
import './Status.scss'

const Status = ({status, statusClass}) => {
    return (
        <div className='white-border'>
            <div className={`status-div ${statusClass}`}>
            {status}
            </div>
        </div>
    )
}

export default Status