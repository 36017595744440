import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchProduct, fetchUsers, fetchTasks } from '../../actions';
import List from '../shared/Tasks/List';
import './task.scss';
import { useTranslation } from 'react-i18next';
import PreLoader from '../shared/PreLoader';
import CachedIcon from '@material-ui/icons/Cached';
import { savedChanges } from '../../actions/helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';

const EmployeeTask = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const helper = useSelector((state) => state.helper);

  const taskListRefresh = () => {
    if (helper.unSavedChanges) {
      const getAlert = () => (
        <SweetAlert
          danger
          showCancel
          closeOnClickOutside={true}
          onCancel={() => {
            setAlert(null);
          }}
          title={`${t('task.details.messages.delete_modal_title')}`}
          onConfirm={() => {
            dispatch(fetchTasks());
            dispatch(savedChanges());
            setAlert(null);
          }}
          confirmBtnText={t('button.confirm')}
          cancelBtnText={t('button.cancel')}
          confirmBtnStyle={{ background: '#71BF5D', color: 'white' }}
          cancelBtnStyle={{ background: '#D43B3B', color: 'white', textDecoration: 'none' }}
        >
          {t('task.details.messages.unsaved')}
        </SweetAlert>
      );
      setAlert(getAlert());
    } else {
      dispatch(fetchTasks());
    }
  };

  useEffect(() => {}, [helper.unSavedChanges]);

  useEffect(() => {
    if (props.employees.employeesList.length > 0 && props.products.productsList.length > 0) {
      setIsLoading(false);
    } else {
      dispatch(fetchUsers());
      dispatch(fetchProduct());
      setIsLoading(true);
    }
  }, [props.employees.employeesList, props.products.productsList]);

  return (
    <div className='task-main-container'>
      <div className='d-flex align-items-center headerContainer'>
        <h1 className='taskHeading'>{t('task.heading')}</h1>
        {alert}
        <div className='d-flex align-items-center'>
          <div className='newTaskImgContainer'>
            <Link to='/task/create'>
              <img src='/images/new_task.svg' alt='new task' />
            </Link>
          </div>
          <div>
            <button className={'refreshBtn'} onClick={taskListRefresh}>
              <CachedIcon style={{ color: '#707070' }} />
            </button>
          </div>
        </div>
      </div>
      <div className='task-list-container'>
        {isLoading || props.loader ? (
          <PreLoader />
        ) : (
          <List
            type={'employee'}
            employee_id={props.userDetails.id}
            employees={props.employees}
            products={props.products.productsList}
          />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  employees: state.employees,
  products: state.products,
  userDetails: state.login,
  loader: state.response.isLoading,
});
export default connect(mapStateToProps)(EmployeeTask);
