import React, {useLayoutEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux'
import { markAllNotificationsRead, fetchNotifications } from '../../actions'
import PreLoader from '../shared/PreLoader';
import List from './List';
import './notification.scss';

const Notifications = ({notifications, isAdmin, loader}) => {
    const dispatch = useDispatch();
    const{t} = useTranslation('common')
    
    useLayoutEffect(() => {
      dispatch(fetchNotifications())
    }, [])
    return (
      <div className='notification-container'>
        {isAdmin === 0 || isAdmin === '0' ? (
          <h1>{t('nav.notifications')}</h1>
        ) : (
          <h1>{t('nav.announcement')}</h1>
        )}
        {
          loader ? <PreLoader />
          :
          <>
              <div className='text-right'>
                <button
                  className='mark-btn'
                  onClick={() => dispatch(markAllNotificationsRead())}
                >
                  {t('notifications.mark_all_read')}
                </button>
              </div>
              <div>
                <List notifications={notifications} />
                {/* {notifications.map((notification) => (
                  <div className='notification d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                      <div className={notification.read_at === null ? 'notification-icon' : ''}>
                        <img
                          src='/images/ionic-ios-notifications.svg'
                          alt='notification_icon'
                        />
                      </div>
                      <p className='message'>
                        {notification.data.data}
                        &nbsp;
                        {
                          notification.read_at === null ? <button
                            className='mark-btn'
                            onClick={() => dispatch(markSingleNotificationsRead(notification.id))}
                          >
                            {t('notifications.mark_as_read')}
                          </button>
                          :
                          ""
                        }
                      </p>
                      <div className="loader-container">
                        {
                          notificationId === notification.id && notificationUpdateSuccess ? "" 
                          : notificationId === notification.id && notificationUpdateSuccess === false ? <PreLoader /> : ""
                        }
                      </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <p>{new Date(notification.created_at).toLocaleDateString()}</p>
                      <p>{new Date(notification.created_at).toLocaleTimeString()}</p>
                    </div>
                  </div>
                ))} */}
              </div>
          </>
        }
      </div>
    );
}

const mapStateToProps = state => ({
    notifications: state.notifications.notificationLists,
    isAdmin: state.login.isAdmin,
    loader: state.response.isLoading
})

export default connect(mapStateToProps)(Notifications)