/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import PreLoader from '../../../shared/PreLoader';
import PlusCircleFillSvg from '../../../../svgs/plusCircleFill';
import { markTaskComplete, requestForAttention, removeTaskProduct, updateTaskTime, deleteKmServiceDriven, updateTaskVisitsAndProducts } from '../../../../actions';

import './employee-task-details.scss';
import { Capitalize } from '../../../../Utility/Capitalize';

import moment from 'moment';
import Products from '../../../Products/Products';
import DeleteXCircleFillSvg from '../../../../svgs/xCircleFill';
import Check2CircleSvg from '../../../../svgs/check2Circle';
import PersonCheckSvg from '../../../../svgs/personCheck';
import { PromptOnRouteChangeInUnsavedInfo } from '../../../PromptOnRouteChangeInUnsavedInfo/PromptOnRouteChangeInUnsavedInfo';
import useUnSavedChanges from '../../../hooks/useUnSavedChanges';
import { savedChanges, unSavedChanges } from '../../../../actions/helper';

const Details = ({
  row: { client, description, kilometers_driven, service_hours, product_model, driving_hours, product_purchase_date_str, product_serial, urgency, id, status, require_attention, products, require_attention_desc, employee_id, timings, created_at, kilometer_service_driven },
  productLists,
  toggle,
  setToggle,
}) => {
  const [drivenList, setDrivenList] = useState([]);
  const isLoading = useSelector((state) => state.response.isLoading);
  const productId = useSelector((state) => state.response.productId);
  const taskProductId = useSelector((state) => state.response.taskProductId);
  const productPriceSuccess = useSelector((state) => state.response.productPriceSuccess);
  const hoursPriceSuccess = useSelector((state) => state.response.hoursPriceSuccess);
  const requestAttentionSuccess = useSelector((state) => state.response.requestAttentionSuccess);
  const type = useSelector((state) => state.response.type);
  const [attention, setAttention] = useState(require_attention);

  const [attentionDesc, setAttentionDesc] = useState(() => (require_attention_desc ? require_attention_desc : ''));
  const [attentionDescDisplay, setAttentionDescDisplay] = useState(() => (require_attention === 1 ? { display: 'block' } : { display: 'true' }));
  const [attentionDescError, setAttentionDescError] = useState('');
  const [taskProducts, setTaskProducts] = useState(products);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [saveBtnState, setSaveBtnState] = useState(true);
  const [modal, setModal] = useState({ show: false });
  const [alert, setAlert] = useState(false);

  const { isTaskVisitAndProductsActionInProgress } = useSelector((state) => state.response);

  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [Prompt, setUnSaved, setSaved] = useUnSavedChanges(t('task.details.messages.unsaved'));
  //on component mount just make a new row for add new visit fields

  const addDrivenInput = React.useCallback(() => {
    const unsavedVisits = drivenList.filter(({ preloaded }) => !preloaded)
    setDrivenList([...unsavedVisits, ...kilometer_service_driven.map((item) => ({ ...item, preloaded: true }))]);
  }, [kilometer_service_driven]);

  const addNewRow = React.useCallback(
    (id) => {
      let modifiedVisits = [
        {
          driving_hours: null,
          service_hours: null,
          kilometers_driven: null,
          taskId: id,
          type: '',
          comment: '',
          preloaded: false,
          updated: true,
        },
        ...drivenList,
      ];
      setDrivenList(modifiedVisits);

      enabledSaveBtn(undefined, modifiedVisits);
    },
    [drivenList, kilometer_service_driven]
  );

  useEffect(() => {
    dispatch(savedChanges());
  }, []);

  useEffect(() => {
    addDrivenInput();
  }, [kilometer_service_driven]);

  useEffect(() => {
    if (attention === 1) {
      setAttentionDescDisplay({ display: 'block' });
    } else {
      setAttentionDescDisplay({ display: 'none' });
    }
  }, [attention]);

  useEffect(() => {
    if (productPriceSuccess) {
      setTaskProducts(products);
    }
  }, [productPriceSuccess, products, taskProductId]);

  const handleRequestAttention = (event) => {
    let value = attention;
    if (event.target.type === 'checkbox' && event.target.checked) {
      value = 1;
    } else {
      value = 0;
    }
    setAttention(value);
    if (value === 0) {
      dispatch(requestForAttention({ require_attention: value, taskId: id }));
    }
  };

  const handleAttentionMessage = (e) => {
    if (attentionDesc.length === 0) {
      setAttentionDescError(t('task.employee_task_details.attention_desc_error'));
      return false;
    }
    dispatch(
      requestForAttention({
        require_attention: attention,
        require_attention_desc: attentionDesc,
        taskId: id,
      })
    );
  };

  const onSend = () => {
    if (!saveBtnState) {
      const getAlert = () => (
        <SweetAlert
          danger
          showCancel
          closeOnClickOutside={true}
          onCancel={() => {
            setAlert(null);
          }}
          title={`${t('task.details.messages.delete_modal_title')}`}
          onConfirm={() => {
            validateDate();
            dispatch(savedChanges());
            setAlert(null);
          }}
          confirmBtnText={t('button.confirm')}
          cancelBtnText={t('button.cancel')}
          confirmBtnStyle={{ background: '#71BF5D', color: 'white' }}
          cancelBtnStyle={{ background: '#D43B3B', color: 'white', textDecoration: 'none' }}
        >
          {t('task.details.messages.unsaved')}
        </SweetAlert>
      );
      setAlert(getAlert());
    } else {
      validateDate();
    }
  };

  const validateDate = async () => {
    let startedAt = '';
    if (timings.length > 0 && timings[0].started_at !== '') {
      startedAt = timings[0].started_at;
    } else {
      startedAt = moment(new Date(created_at)).toDate();
    }

    let stoppedAt = '';
    if (timings.length > 0 && timings[0].stopped_at !== '') {
      stoppedAt = timings[0].stopped_at;
    } else {
      stoppedAt = moment().toDate();
    }

    //if timings exsist & its length is 0 i.e. empty obj
    if (!timings || timings.length === 0 || (timings.length > 0 && (!timings[0].started_at || !timings[0].stopped_at))) {
      if (startedAt && stoppedAt) {
        await dispatch(
          updateTaskTime({
            started_at: startedAt,
            completed_at: stoppedAt,
            taskId: id,
            selectedEmployee: employee_id,
          })
        );
      }
    }
    await dispatch(markTaskComplete({ taskId: id }));
  };

  const validateVistsAndProductsForm = (productsToBeUpdated) => {
    let valid = true;
    let populatedProductList = [...productsToBeUpdated];
    populatedProductList = populatedProductList.map((product) => {
      let item = product;
      let errors = { product: null, quantity: null };

      if (!parseInt(item.product) || parseInt(item.product) === 'NaN' || item.name === '') {
        errors.product = t('task.employee_task_details.form.product.error');
        valid = false;
      }
      if (!item.quantity || parseFloat(item.quantity) === 'NaN' || parseFloat(item.quantity) <= 0) {
        errors.quantity = t('task.employee_task_details.form.quantity.error');
        valid = false;
      }
      return { ...item, errors };
    });

    if (!valid) {
      setSelectedProducts(populatedProductList);
      return false;
    }
    return true;
  };

  const enabledSaveBtn = (newProductsValues, newVisitsValues) => {
    let modifiedProductsValues = newProductsValues ? newProductsValues : [];
    let modifiedVisitsValues = newVisitsValues ? newVisitsValues : [];
    let values = [...modifiedProductsValues, ...modifiedVisitsValues];
    let updatedFlag = false;

    if (values?.length > 0) {
      values.map((value) => {
        if (value.updated) updatedFlag = true;
      });
    }
    if (updatedFlag) {
      setSaveBtnState(false);
      setUnSaved();
      dispatch(unSavedChanges());
    } else {
      setSaveBtnState(true);
      setSaved();
      dispatch(savedChanges());
    }
  };

  const onVisitsAndPRoductsDelete = (type, inputIndex) => {
    let item = null;
    if (type === 'visit') {
      item = drivenList[inputIndex];
    } else if (type === 'product') {
      item = selectedProducts[inputIndex];
    }
    if (item) {
      if (item.preloaded) {
        const getAlert = () => (
          <SweetAlert
            danger
            showCancel
            closeOnClickOutside={true}
            onCancel={() => setAlert(null)}
            title={`${t('task.details.messages.delete_modal_title')}`}
            onConfirm={() => {
              if (type === 'visit') {
                onVisitRemove(inputIndex);
              }
              if (type === 'product') {
                onProductRemove(inputIndex);
              }
              setAlert(null);
            }}
            confirmBtnText={t('button.confirm')}
            cancelBtnText={t('button.cancel')}
            confirmBtnStyle={{ background: '#71BF5D', color: 'white' }}
            cancelBtnStyle={{ background: '#D43B3B', color: 'white', textDecoration: 'none' }}
          >
            {t('task.details.messages.delete')}
          </SweetAlert>
        );
        setAlert(getAlert());
      } else {
        if (type === 'visit') {
          onVisitRemove(inputIndex);
        }
        if (type === 'product') {
          onProductRemove(inputIndex);
        }
      }
    }
  };

  const onProductRemoveModalChange = (e, item, index) => {
    onVisitsAndPRoductsDelete('product', index);
  };

  const onVisitRemove = (index) => {
    const itemToBeRemoved = drivenList[index];
    const updatedDrivenList = [...drivenList.slice(0, index), ...drivenList.slice(index + 1)];
    setDrivenList(updatedDrivenList);
    if (itemToBeRemoved.preloaded) dispatch(deleteKmServiceDriven({ taskId: id, id: itemToBeRemoved.id }));
    setModal({ show: false });
    enabledSaveBtn(selectedProducts, updatedDrivenList);
  };

  const onVisitFormChange = (e, InputIndex) => {
    let visitFormValues = [...drivenList];
    visitFormValues[InputIndex][e.target.name] = e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value;
    visitFormValues[InputIndex].updated = true;
    setDrivenList(visitFormValues);
    enabledSaveBtn(undefined, visitFormValues);
  };

  const onProductRemove = (inputIndex) => {
    const itemToBeRemoved = selectedProducts[inputIndex];
    const modifiedProducts = [...selectedProducts.slice(0, inputIndex), ...selectedProducts.slice(inputIndex + 1)];
    setSelectedProducts(modifiedProducts);
    if (itemToBeRemoved.preloaded) dispatch(removeTaskProduct({ taskProductId: itemToBeRemoved.task_product_id, taskId: id }));
    setModal({ show: false });
    enabledSaveBtn(modifiedProducts, drivenList);
  };

  const onVisitAndProductSave = () => {
    let payloads = {};
    const visitsToBeUpdated = drivenList;
    const productsToBeUpdated = selectedProducts;
    if (validateVistsAndProductsForm(productsToBeUpdated, visitsToBeUpdated)) {
      payloads = {
        ...payloads,
        taskId: id,
        visitsToBeUpdated: visitsToBeUpdated.map((visit) => ({
          kilometers_driven: visit.kilometers_driven ?? 0,
          service_hours: visit.service_hours ?? 0,
          driving_hours: visit.driving_hours ?? 0,
          comment: visit.comment ?? '',
          taskId: id,
          price: visit.price ?? 0,
          task_kilometer_service_driving_hours_id: visit.id,
        })),
        productsToBeUpdated: productsToBeUpdated.map((product) => ({
          quantity: product.quantity,
          product_id: product.product,
          comment: product.comment,
          taskId: id,
          task_product_id: product.preloaded === true ? product.task_product_id : null,
        })),
      };
      dispatch(updateTaskVisitsAndProducts(payloads));
      setSaveBtnState(true);
      setToggle(false);
      setSaved();
      dispatch(savedChanges());
      setDrivenList(drivenList.filter(({ preloaded }) => preloaded))
      setSelectedProducts(selectedProducts.filter(({ preloaded }) => preloaded))
    }
  };

  const mapRowObjects = () => {
    const rows = kilometer_service_driven.map((row, index) => {
      return (
        <div className='driven-container d-flex justify-content-between customMappedRow customMappedRow-kmDrivenList' key={index}>
          <React.Fragment>
            <div className='column'>
              <h2>
                {t('task.details.kilometer_driven')}
                <span className='driven-value'>
                  {row.kilometers_driven}
                  {t('task.details.km')}
                </span>
              </h2>
            </div>
            <div className='column'>
              <h2>
                {t('task.employee_task_details.hours_used')}
                <span className='driven-value'>
                  {row.service_hours}
                  {t('task.details.hrs')}
                </span>
              </h2>
            </div>
            <div className='column'>
              <h2>
                {t('task.employee_task_details.driving_hours_used')}
                <span className='driven-value'>
                  {row.driving_hours}
                  {t('task.details.hrs')}
                </span>
              </h2>
            </div>
            <div className='column'>
              <h2>
                {t('task.employee_task_details.comment')}
                <span className='driven-value'>{row.comment}</span>
              </h2>
            </div>
          </React.Fragment>
        </div>
      );
    });

    return rows;
  };

  return (
    <div className={isLoading ? `employee-details-container loader-padding-container` : `employee-details-container`}>
      {isLoading ? (
        <div className='detail-loader'>
          <PreLoader />
        </div>
      ) : (
        <>
          {alert}
          {Prompt}
          <PromptOnRouteChangeInUnsavedInfo when={!saveBtnState} onConfirm={() => true} onCancel={() => false} title={t('task.details.messages.delete_modal_title')} confirmText={t('button.confirm')} cancelText={t('button.cancel')} message={t('task.details.messages.unsaved')} />
          <p>{t('task.details.customer')} : </p>
          <div className='details-content d-flex justify-content-between'>
            <div className='column'>
              <h3>
                {t('task.details.email')}: {client.email}
              </h3>
              <h3>
                {t('task.details.phone')}: {client.phone}
              </h3>
              <h3>
                {t('task.details.location')}: {Capitalize(client.address)}
              </h3>
              <h3>
                {t('task.details.zip')}: {client.zipcode}
              </h3>
              <h3>
                {t('task.details.city')}: {Capitalize(client.postal)}
              </h3>
              <h3>
                {t('task.details.invoice_address')}: {Capitalize(client.einvoicing_address)}
              </h3>
              <h3>
                {t('task.details.broker_id')}: {client.einvoicing_operator_id}
              </h3>
            </div>
            <div className='column'>
              <h3>
                {t('task.details.model_heat_pump')}: {Capitalize(product_model)}
              </h3>
              <h3>
                {t('task.details.serial_number')}: {Capitalize(product_serial)}{' '}
              </h3>
              <h3>
                {t('task.details.purchase')}: {product_purchase_date_str}{' '}
              </h3>
            </div>

            <div className='column'>
              <h3>{t('task.details.maintenence')}</h3>
              <p>{Capitalize(description)}</p>
            </div>
            <div className='column'>
              <h3>{t('task.employee_task_details.urgency')}</h3>
              <h3 className='urgency'>{Capitalize(urgency)}</h3>
            </div>
          </div>

          {/* { toggle && kilometers_driven !== 0 && service_hours !== 0  && driving_hours !== 0 && kilometer_service_driven.length === 0 ? (
                <div className="driven-container d-flex justify-content-between">
                  <React.Fragment>
                    <div className="column">
                      <h2>
                        {t("task.details.kilometer_driven")}
                        <span className="driven-value">{kilometers_driven}km</span>
                      </h2>
                    </div>
                    <div className="column">
                      <h2>
                        {t("task.employee_task_details.hours_used")}
                        <span className="driven-value">{service_hours}hrs</span>
                      </h2>
                    </div>
                    <div className="column">
                      <h2>
                        {t("task.employee_task_details.driving_hours_used")}
                        <span className="driven-value">{driving_hours}hrs</span>
                      </h2>
                    </div>
                  </React.Fragment>
                </div>
                ): (
                  mapRowObjects()
                ) 
          } */}

          <div className='driven-container d-flex justify-content-between'>
            <div className='req-title'>
              <button onClick={() => addNewRow(id)}>
                {t('task.employee_task_details.add_new_visit')}
                <PlusCircleFillSvg className='product-visit-add-btn plusIcon' />
              </button>
            </div>
          </div>
          {drivenList.map((el, InputIndex) => {
            return (
              <div className='driven-container d-flex justify-content-between customMappedRow drivenList' key={InputIndex}>
                <div className='d-flex justify-content-between driven-content-row'>
                  <div className='d-flex justify-content-between custom-drivenrow-container'>
                    <div className='column d-flex'>
                      <h2>{t('task.employee_task_details.hours_used')}</h2>
                      <div className='driven-value'>
                        <input
                          type='number'
                          name='service_hours'
                          onChange={(e) => onVisitFormChange(e, InputIndex, id)}
                          value={el.service_hours ? el.service_hours : ''}
                          // onBlur={() => handleSaveHours("service")}
                          className='input-field'
                        />
                        <p>{t('task.details.hrs')}</p>
                      </div>
                    </div>
                    <div className='column d-flex'>
                      <h2>{t('task.employee_task_details.kilometer_driven')}</h2>
                      <div className='driven-value'>
                        <input
                          type='number'
                          name='kilometers_driven'
                          value={el.kilometers_driven ? el.kilometers_driven : ''}
                          onChange={(e) => onVisitFormChange(e, InputIndex, id)}
                          // onBlur={handleSaveKilometer}
                          className='input-field'
                        />
                        <p>{t('task.details.km')}</p>
                      </div>
                    </div>
                    <div className='column d-flex'>
                      <h2>{t('task.employee_task_details.driving_hours_used')}</h2>
                      <div className='driven-value'>
                        <input
                          type='number'
                          name='driving_hours'
                          onChange={(e) => onVisitFormChange(e, InputIndex, id)}
                          value={el.driving_hours ? el.driving_hours : ''}
                          // onBlur={() => handleSaveHours("driving")}
                          className='input-field'
                        />
                        <p>{t('task.details.hrs')}</p>
                      </div>
                    </div>
                    <div className='column d-flex column-40'>
                      <h2>{t('task.employee_task_details.comment')}</h2>
                      <div className='driven-value driven-value-40'>
                        <textarea
                          name='comment'
                          onChange={(e) => onVisitFormChange(e, InputIndex, id)}
                          value={el.comment ? el.comment : ''}
                          // onBlur={() => handleSaveHours("driving")}
                          className='input-field'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='d-flex align-items-start button-container justify-content-around'>
                    <button onClick={() => onVisitsAndPRoductsDelete('visit', InputIndex)} className='removeInput project-btn'>
                      <DeleteXCircleFillSvg className='remove-cross-btn' />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}

          <div className='driven-container d-flex  required-driven-container'>
            <div className='column d-flex align-items-center'>
              <h2 className='req-title'>{t('task.employee_task_details.request_for_attention')}</h2>
              <div className='driven-value'>
                <input type='checkbox' checked={attention ? 'checked' : ''} className='' name='attention' onChange={(e) => handleRequestAttention(e)} />
              </div>
              <div className='loader-container'>{requestAttentionSuccess ? <PreLoader /> : ''}</div>
            </div>
            <div className='column d-flex align-items-center custom-require-content'>
              <h2 style={attentionDescDisplay} className='req-title'>
                {t('task.employee_task_details.request_attention_desc')}
              </h2>
              <div className='driven-value' style={attentionDescDisplay}>
                <textarea
                  type='text'
                  onChange={(e) => {
                    setAttentionDesc(e.target.value);
                    setAttentionDescError('');
                  }}
                  value={attentionDesc}
                  name='request_attention_desc'
                  onBlur={(e) => handleAttentionMessage(e)}
                  className='input-field'
                ></textarea>
                <div className='requried-text'>{attentionDescError}</div>
              </div>
            </div>
          </div>

          {toggle ? (
            <div className='product-container'>
              <h2>{t('task.employee_task_details.products.heading')}</h2>
              <div className='product-content d-flex justify-content-between'>
                <div className='column-container w-100'>
                  {products.length > 0
                    ? products.map((product, index) => {
                        return (
                          <div key={index} className='column d-flex align-items-center justify-content-between'>
                            <div>
                              <p>{product.name}</p>
                            </div>
                            <div>
                              <p>
                                {t('task.employee_task_details.products.model')}: {product.model}
                              </p>
                            </div>
                            <div>
                              <p>
                                {t('task.employee_task_details.products.quantity')}: {product.pivot.quantity}
                              </p>
                            </div>
                            <div>
                              <p>
                                {t('task.employee_task_details.products.comment')}: {product.pivot.comment}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    : ''}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <Products
            allProductsList={productLists}
            products={products}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            setSaveBtnState={setSaveBtnState}
            onProductRemove={onProductRemoveModalChange}
            taskId={id}
            productId={productId}
            productPriceSuccess={productPriceSuccess}
            enabledSaveBtn={enabledSaveBtn}
          />
          <div className='total-container d-flex align-items-center'>
            {isTaskVisitAndProductsActionInProgress ? (
              <PreLoader />
            ) : (
              <div className='d-flex'>
                <div>
                  <button className={`project-btn d-flex align-items-center ${saveBtnState ? 'disabled disabled-btn' : ''}`} disabled={saveBtnState} onClick={onVisitAndProductSave}>
                    <div>
                      <Check2CircleSvg className='btnIcon' />
                    </div>{' '}
                    {t('button.save')}
                  </button>
                </div>
                <div>
                  <button className='project-btn  d-flex align-items-center ml-3' onClick={onSend}>
                    <div>
                      <PersonCheckSvg className='btnIcon' />
                    </div>{' '}
                    {t('button.send')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Details;
