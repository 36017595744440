import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { invite } from '../../actions';
import Input from '../shared/Input';
import Select from '../shared/Select';
import './invite.scss';
import { fetchEmployees } from '../../actions';

const Invite = ({ setModalOpen }) => {
  const [email, setEmail] = useState('');
  const [area, setArea] = useState('');
  const [admin, setAdmin] = useState('');
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const validation = () => {
    let formIsValid = true;
    let errorArray = [];
    const validEmailRegex = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (email.length === 0) {
      formIsValid = false;
      errorArray.push({ email: t('invite.form.email.error.empty') });
    } else if (!validEmailRegex.test(email)) {
      formIsValid = false;
      errorArray.push({ email: t('invite.form.email.error.invalid') });
    }

    if (area.length === 0) {
      formIsValid = false;
      errorArray.push({ area: t('invite.form.area.error.empty') });
    }

    if (
      isNaN(parseInt(admin)) ||
      (parseInt(admin) !== 0 && parseInt(admin) !== 1)
    ) {
      formIsValid = false;
      errorArray.push({ is_admin: t('invite.form.admin.error.empty') });
    }

    if (formIsValid) {
      return true;
    } else {
      setErrors(errorArray);
      return false;
    }
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    const validForm = validation();
    if (!validForm) {
      return false;
    }
    setModalOpen(false);
    dispatch(invite({ email, area, admin }));
    dispatch(fetchEmployees());
  };

  const options = [
    {
      name: t('invite.form.admin.options.select_options'),
      value: '',
    },
    {
      name: t('invite.form.admin.options.admin'),
      value: 1,
    },
    {
      name: t('invite.form.admin.options.employee'),
      value: 0,
    },
  ];

  const updateError = (name) => {
    const updatedErrors = errors.map((error) => {
      return {
        ...error,
        [name]: '',
      };
    });
    setErrors(updatedErrors);
  };

  return (
    <>
      <form onSubmit={(e) => formSubmitHandler(e)}>
        <Input
          className='input-field employee-input'
          type={'text'}
          name={'email'}
          id={'email'}
          placeholder={t('invite.form.email.placeholder')}
          value={email}
          label={t('invite.form.email.label')}
          errors={errors}
          onChangeHandle={(e) => {
            setEmail(e.target.value);
            updateError(e.target.name);
          }}
        />
        <Input
          className='input-field employee-input'
          type={'text'}
          name={'area'}
          id={'area'}
          placeholder={t('invite.form.area.placeholder')}
          value={area}
          label={t('invite.form.area.label')}
          errors={errors}
          onChangeHandle={(e) => {
            setArea(e.target.value);
            updateError(e.target.name);
          }}
        />
        <Select
          className='input-field employee-input'
          type={'text'}
          name={'is_admin'}
          id={'is_admin'}
          placeholder={t('invite.form.admin.placeholder')}
          options={options}
          value={admin}
          label={t('invite.form.admin.label')}
          errors={errors}
          onChangeHandle={(e) => {
            setAdmin(parseInt(e.target.value));
            updateError(e.target.name);
          }}
        />
        <div className=''>
          <input
            className='project-btn'
            type='submit'
            value={t('invite.form.button')}
          />
        </div>
      </form>
    </>
    // <div className='invite-main-container d-flex align-items-center justify-content-center'>
    //   <div className='invite-container text-center'>
    //     <h3>New Employee</h3>
    //     <form onSubmit={(e) => formSubmitHandler(e)}>
    //       <div className=''>
    //         <label className='input-label' htmlFor='Email'>
    //           Email
    //         </label>
    //         <input
    //           className='input-field'
    //           type='email'
    //           onChange={(e) => {
    //             setEmail(e.target.value);
    //             setErrors({ email: '' });
    //           }}
    //           name='email'
    //           placeholder='Enter Employee E-mail'
    //           value={email}
    //           id='Email'
    //         />
    //         <div className='error-message'>{errors.email}</div>
    //       </div>
    //       <div className=''>
    //         <label className='input-label' htmlFor='Area'>
    //           Delegated To Area
    //         </label>
    //         <input
    //           className='input-field'
    //           type='text'
    //           onChange={(e) => {
    //             setArea(e.target.value);
    //             setErrors({ area: '' });
    //           }}
    //           name='area'
    //           placeholder='Helsinki'
    //           value={area}
    //           id='Area'
    //         />
    //         <div className='error-message'>{errors.email}</div>
    //         <div className='error-message'> {errors.area}</div>
    //       </div>
    //       <div className=''>
    //         <input
    //           className='project-btn'
    //           type='submit'
    //           value='Send Invitation'
    //         />
    //       </div>
    //     </form>
    //   </div>
    // </div>
  );
};

const mapStateToProps = (state) => ({
  errors: state.response.errors,
});
export default connect(mapStateToProps)(Invite);
