/** @format */

import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import Details from '../Details';
import './task-list.scss';
import { useDispatch, useSelector, connect } from 'react-redux';
import { assignTask } from '../../../actions';
import moment from 'moment';
import Datatable from '../../../containers/shared/Datatable';
import Status from '../../../containers/shared/Status';
import { useTranslation } from 'react-i18next';
import PreLoader from '../../shared/PreLoader';
import Input from '../../shared/Input';
import DropDown from '../../shared/DropDown';
import { Constant, StatusLabel } from '../../shared/StatusLabel';
import { Capitalize } from '../../../Utility/Capitalize';
import CaretUpFillSvg from '../../../svgs/caretUpFill';
import CaretDownFillSvg from '../../../svgs/caretDownFill';
import { deleteTask } from '../../../actions';
import DeleteIcon from '@material-ui/icons/Delete';
import SweetAlert from 'react-bootstrap-sweetalert';
import D from '../../shared/Tasks/Details/index';
import BoxArrowLeftSvg from '../../../svgs/boxArrowLeft';
import PencilSquareSvg from '../../../svgs/pencilSquare';

import { fetchProduct } from '../../../actions';
import { savedChanges } from '../../../actions/helper';

const List = (props) => {
  const dispatch = useDispatch();
  const employees = props.employees;
  const [data, setData] = useState([]);
  const [employeesList, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchedUser, setSearchedUser] = useState('');
  const { t } = useTranslation('common');
  const [alert, setAlert] = useState(false);
  const [detailsPage, setDetailsPage] = useState({
    index: null,
    checked: false,
  });
  const [toggle, setToggle] = useState(false);

  const tasksData = useSelector((state) => state.taskList.tasks);

  const taskId = useSelector((state) => state.response.taskId);
  const assignTaskSuccess = useSelector((state) => state.response.assignTaskSuccess);
  const helper = useSelector((state) => state.helper);

  useLayoutEffect(() => {
    setEmployees(employees.employeesList);
  }, [employees.employeesList]);

  useEffect(() => {}, [helper]);

  useEffect(() => {
    if (props.employees.employeesList.length > 0 && props.products.productsList.length > 0) {
      setIsLoading(false);
    } else {
      dispatch(fetchProduct());
      setIsLoading(true);
    }
  }, [props.employees.employeesList.length, props.products.productsList]);

  useEffect(() => {
    if (data.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [data]);

  useEffect(() => {
    if (searchedUser.length > 0) {
      let array = employees.employeesList.filter((employee) => {
        return employee.name && employee.name.length > 0 && employee.name.toLowerCase().includes(searchedUser);
      });
      setEmployees(array);
    }
  }, [searchedUser]);

    // This useEffect is used to explicitly add few fields in data so that the sorting can be done on the columns
  useEffect(() => {
    if (tasksData) {
      setData(
        tasksData.map((td) => ({
          ...td,
          status: td.status?.toLowerCase(),
          worker: td.employee_id
            ? employees?.employeesList
                ?.find(({ id }) => id === td.employee_id)
                ?.name?.toLowerCase()
            : null,
          client_name: td.client?.name?.toLowerCase(),
        }))
      );
    }
  }, []);

  const employeesArray = (employeesData) => {
    if (employeesData.length > 0) {
      if (searchedUser.length > 0) {
        let array = employeesData.filter((employee) => {
          if (employee.name && employee.name.length > 0) {
            return employee.name.toLowerCase().includes(searchedUser);
          }
        });
        setEmployees(array);
      } else {
        setEmployees(employeesData);
      }
    }
  };

  const handleSearch = (row, value) => {
    setSearchedUser(value);
  };

  const handleDelete = (id) => {
    const getAlert = (id) => (
      <SweetAlert
        danger
        closeOnClickOutside={true}
        showCancel
        cancelBtnText={t('task.delete.cancel')}
        cancelBtnBsStyle='light'
        onCancel={() => setAlert(null)}
        title={`${t('task.delete.heading')}`}
        confirmBtnText={t('task.delete.confirm')}
        onConfirm={() => {
          dispatch(deleteTask({ taskId: id }));
          setAlert(null);
        }}
        confirmBtnStyle={{ background: '#71BF5D' }}
      >
        {t('task.delete.text')}
      </SweetAlert>
    );
    setAlert(getAlert(id));
  };

  const onBack = () => {
    if (helper.unSavedChanges) {
      const getAlert = () => (
        <SweetAlert
          danger
          showCancel
          closeOnClickOutside={true}
          onCancel={() => {
            setAlert(null);
          }}
          title={`${t('task.details.messages.delete_modal_title')}`}
          onConfirm={() => {
            setToggle(false);
            dispatch(savedChanges());
            setAlert(null);
          }}
          confirmBtnText={t('button.confirm')}
          cancelBtnText={t('button.cancel')}
          confirmBtnStyle={{ background: '#71BF5D', color: 'white' }}
          cancelBtnStyle={{ background: '#D43B3B', color: 'white', textDecoration: 'none' }}
        >
          {t('task.details.messages.unsaved')}
        </SweetAlert>
      );
      setAlert(getAlert());
    } else {
      setToggle(false);
    }
  };

  const onRowCollapse = ({ onClick }) => {
    if (helper.unSavedChanges) {
      const getAlert = () => (
        <SweetAlert
          danger
          showCancel
          closeOnClickOutside={true}
          onCancel={() => {
            setAlert(null);
          }}
          title={`${t('task.details.messages.delete_modal_title')}`}
          onConfirm={() => {
            setToggle(false);
            onClick();
            dispatch(savedChanges());
            setAlert(null);
          }}
          confirmBtnText={t('button.confirm')}
          cancelBtnText={t('button.cancel')}
          confirmBtnStyle={{ background: '#71BF5D', color: 'white' }}
          cancelBtnStyle={{ background: '#D43B3B', color: 'white', textDecoration: 'none' }}
        >
          {t('task.details.messages.unsaved')}
        </SweetAlert>
      );
      setAlert(getAlert());
    } else {
      onClick();
    }
  };

  const handleChecked = (e, row) => {
    row.toggleRowExpanded(e.target.checked);
    setDetailsPage({ index: row.index, checked: e.target.checked });
  };

  const renderRowSubComponent = React.useCallback(
    ({ row }) => {
      if (!toggle && row.isExpanded) {
        return <Details {...row.original} />;
      } else {
        return <D row={row.original} productLists={props.products.productsList} kilometer_service_driven={row.original.kilometer_service_driven} setToggle={setToggle} />;
      }
    },
    [props.products.productsList, toggle, helper]
  );

  const changeEmployee = (taskId, employeeId) => {
    dispatch(assignTask(taskId, employeeId));
  };
  const selectedEmployee = (taskEmployeeId) => {
    const selectedEmployee = employees.employeesList.find((employee) => employee.id === taskEmployeeId);
    return selectedEmployee ? selectedEmployee.name : '';
  };

  const statusCol = (props) => {
    let statusClass = 'status-default';
    let status = 'Unassigned';
    switch (props.row.original.status.toUpperCase()) {
      case 'completed'.toUpperCase():
        statusClass = 'status-complete';
        status = StatusLabel(t).completed;
        break;
      case 'assigned'.toUpperCase():
        statusClass = 'status-progress';
        status = StatusLabel(t).assigned;
        break;
      case 'running'.toUpperCase():
        statusClass = 'status-progress';
        status = StatusLabel(t).running;
        break;
      case 'invoiced'.toUpperCase():
        statusClass = 'status-invoiced';
        status = StatusLabel(t).invoiced;
        break;
      default:
        statusClass = 'status-default';
        status = StatusLabel(t).unassigned;
    }
    return <Status status={status} statusClass={statusClass} />;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => {
          return `${t('task.list.heading_one')}`;
        },
        accessor: 'client_name',
        className: 'Tasks',
        Cell: (props, { row }) => (
          <div className='text-left custom-task-width pl-3'>
            <div
              className={
                props.row.original.require_attention === 1
                  ? `mb-2 d-flex align-items-center  required-column custom-required-column`
                  : `mb-2 d-flex align-items-center`
              }
            >
              <h3 className='d-flex flex-row'>
                {Capitalize(props.row.original.client.name)}
                <span>
                  <div
                    {...props.row.getToggleRowExpandedProps()}
                    onClick={() =>
                      onRowCollapse(props.row.getToggleRowExpandedProps())
                    }
                    className='custom-toggle-icon'
                  >
                    {props.row.isExpanded ? (
                      <CaretUpFillSvg />
                    ) : (
                      <CaretDownFillSvg className='ml-2' />
                    )}
                  </div>
                </span>
              </h3>

              {props.row.isExpanded &&
                props.row.original.status.toUpperCase() ===
                  Constant.completed &&
                (toggle ? (
                  <div
                    className='edit-icon d-flex align-items-center'
                    onClick={onBack}
                  >
                    <button className='edit-icon-title'>
                      {t('button.back')}{' '}
                      <BoxArrowLeftSvg className='primary-btn-fill editIcon' />
                    </button>
                  </div>
                ) : (
                  <div
                    className='edit-icon d-flex align-items-center'
                    onClick={() => setToggle(true)}
                  >
                    <button className='edit-icon-title'>
                      {t('task.details.edit_as_employee')}{' '}
                      <PencilSquareSvg className='primary-btn-fill editIcon' />
                    </button>
                  </div>
                ))}

              {props.row.original.require_attention === 1 ? (
                <div
                  id={props.row.isExpanded ? 'custom-require-text' : ''}
                  className='require-text'
                >
                  {t('require_attention')}
                </div>
              ) : (
                ''
              )}
            </div>

            <div>
              {props.row.original.client.address
                ? `${Capitalize(props.row.original.client.address)}`
                : ''}
              {props.row.original.client.zipcode
                ? `, ${props.row.original.client.zipcode}`
                : ''}
              {props.row.original.client.postal
                ? `, ${props.row.original.client.postal}`
                : ''}
            </div>
          </div>
        ),
      },
      {
        Header: () => {
          return `${t('task.list.heading_two')}`;
        },
        className: 'Arrived',
        accessor: 'created_at',
        Cell: (props) => (
          <div className='white-border custom-task-w-15 custom-arrived'>
            <h6 className='d-md-none d-block'>{t('task.list.heading_two')}</h6>
            <div>
              {new Date(props.row.original.created_at).toLocaleDateString()}
            </div>
          </div>
        ),
      },
      {
        Header: () => {
          return `${t('task.list.heading_three')}`;
        },
        className: 'Completed',
        accessor: 'updated_at',
        Cell: (props) => {
          let local = null;
          if (props.row.original.timings.length > 0) {
            const utc = moment.utc(props.row.original.timings[0].stopped_at);
            local = utc.local();
          }
          return (
            <div className='white-border custom-task-w-15 custom-completed'>
              <h6 className='d-md-none d-block'>
                {t('task.list.heading_three')}
              </h6>
              <div>
                {props.row.original.timings.length > 0 &&
                props.row.original.status.toLowerCase() === 'completed'
                  ? local.format('DD.MM.YYYY HH:mm')
                  : '----'}
              </div>
            </div>
          );
        },
      },
      {
        Header: () => {
          return `${t('task.list.heading_four')}`;
        },
        className: 'Worker',
        accessor: 'worker',
        Cell: (props) => (
          <div className='white-border custom-task-w-15 custom-worker'>
            <h6 className='d-md-none d-block'>{t('task.list.heading_four')}</h6>
            {props.row.original.status.toUpperCase() === 'COMPLETED' ||
            props.row.original.status.toUpperCase() === 'INVOICED' ? (
              <span>{selectedEmployee(props.row.original.employee_id)}</span>
            ) : (
              <>
                <div className='loader-container'>
                  {taskId === props.row.original.id && assignTaskSuccess ? (
                    <DropDown
                      items={employees.employeesList}
                      searchBy={'name'}
                      searchType={'string'}
                      inputClass={'user-search'}
                      selected={selectedEmployee(
                        props.row.original.employee_id
                      )}
                      placeholder={'Hae käyttäjää'}
                      onChangeHandle={(id) =>
                        changeEmployee(props.row.original.id, id)
                      }
                    />
                  ) : taskId === props.row.original.id &&
                    assignTaskSuccess === false ? (
                    <PreLoader />
                  ) : (
                    <DropDown
                      items={employees.employeesList}
                      searchBy={'name'}
                      searchType={'string'}
                      inputClass={'user-search'}
                      selected={selectedEmployee(
                        props.row.original.employee_id
                      )}
                      placeholder={'Hae käyttäjää'}
                      onChangeHandle={(id) =>
                        changeEmployee(props.row.original.id, id)
                      }
                    />
                  )}
                </div>
              </>
            )}
          </div>
        ),
      },
      {
        Header: () => {
          return `${t('task.list.heading_five')}`;
        },
        className: 'Status',
        accessor: 'status',
        Cell: (props) => (
          <div className='custom-task-w-15 custom-status'>
            <h6 className='d-md-none d-block'>{t('task.list.heading_five')}</h6>
            {statusCol(props)}
          </div>
        ),
      },
      {
        Header: () => {
          return <div className='d-none'></div>;
        },
        className: 'Delete',
        accessor: 'delete',
        Cell: (props) => (
          <div className='white-border custom-task-w-7-5 custom-delete'>
            <h6 className='d-md-none d-block'>{t('task.list.heading_six')}</h6>
            {props.row.original.status.toUpperCase() !== Constant.invoiced ? (
              <div className='px-2'>
                <DeleteIcon
                  className='icon-hover trash-icon'
                  onClick={(e) => handleDelete(props.row.original.id)}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        ),
      },
    ],
    [taskId, assignTaskSuccess, employeesList, alert, toggle, helper]
  );


  const ourGlobalFilterFunction = useCallback((rows, ids, query) => {
    return rows.filter((row) => {
      const name = row.original.client.name.toLowerCase();
      const address = row.original.client.address.toLowerCase();
      const status = row.original.status.toLowerCase();
      const employee_id = row.original.employee_id;
      const employeeIds = employees.employeesList
      .filter((emp) => emp.name?.toLowerCase().includes(query?.toLowerCase()))
      .map((emp) => emp.id)
      
      return (
        name.includes(query.toLowerCase()) ||
        address.includes(query.toLowerCase()) ||
        status.includes(query.toLowerCase()) ||
        employeeIds.includes(employee_id)
      )
      });
          
  }, [employeesList]);

  return (
    <React.Fragment>
      {isLoading ? (
        <PreLoader />
      ) : (
        <>
          {alert} <Datatable detailsPage={detailsPage} isFiltered={true} isPaginated={true} globalFilterFunction={ourGlobalFilterFunction} tableClass={'task-list'} renderRowSubComponent={renderRowSubComponent} data={data} isExpanded={true} columns={columns} />
        </>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  products: state.products,
});

export default connect(mapStateToProps)(List);
