const notifications = (state = {notificationLists: []}, action) => {
    switch(action.type){
        case 'NOTIFICATIONS' :
            return {
                ...state,
                notificationLists: action.payloads.data
            }
        case 'UPDATE_NOTIFICATION' : 
            return {
                ...state,
                notificationLists: state.notificationLists.map(notification => notification.id === action.payloads.notificationId ? {
                    ...notification,
                    ...action.payloads.data
                }
                : notification)
            }    
        default :
            return state;    
    }
}

export default notifications