import { combineReducers } from 'redux';
import login from '../reducers/login';
import register from './register';
import invite from './invite';
import employees from './employees';
import response from './reponse';
import tasks from './tasks';
import products from './products';
import notifications from './notifications';
import helper from './helper';

const allStore = combineReducers({
  login,
  register,
  invite,
  employees,
  response,
  taskList: tasks,
  products,
  notifications,
  helper,
});

const initialState = allStore({}, {});
const rootReducer = (state, action) => {
  if (action.type === 'IS_LOGGED_OUT' || action.type === 'IS_SESSION_EXPIRED') {
    localStorage.setItem('isloggedIn', 'false');
    localStorage.setItem('isAdmin', 0);
    state = initialState;
    if (localStorage.getItem('isloggedIn')) {
      state.login.isLoggedIn = false;
    }
  }
  return allStore(state, action);
};

export default rootReducer;
