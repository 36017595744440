import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.scss';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import allStore from '../src/reducers';
import App from '../src/App';
import thunk from 'redux-thunk';
import { fetchUser } from './actions';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import common_tr from './translations/tr/common.json';
import common_en from './translations/en/common.json';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(allStore, composeEnhancer(applyMiddleware(thunk)));

if (window.location.pathname !== '/register' && window.location.pathname !== '/reset-password') store.dispatch(fetchUser());

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    tr: {
      common: common_tr,
    },
  },
});

const currentLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

i18next.changeLanguage(currentLang);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <link
          href='https://db.onlinewebfonts.com/c/0a6ee448d1bd65c56f6cf256a7c6f20a?family=Bahnschrift'
          rel='stylesheet'
          type='text/css'
        /> */}
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
