/** @format */

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Invite from '../Invite';
import ModalPopup from '../shared/ModalPopup';
import Details from './Details';
import { deleteEmployees, fetchEmployees, reinvite } from '../../actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import './Employee.scss';
import { useTranslation } from 'react-i18next';
import PreLoader from '../shared/PreLoader';
import EnvelopeSvg from '../../svgs/envelope';
import { Dropdown } from 'react-bootstrap';

const Employee = (props) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEmployeeOpen, setModalEmployeeOpen] = useState({});
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(null);
  const [area, setArea] = useState(null);
  const [alert, setAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation('common');
  const [empFilter, setEmpFilter] = useState('1');

  const empFilterOptions = [
    {
      id: 0,
      value: 0,
      label: t('employee.filter.active'),
    },
    {
      id: 1,
      value: 1,
      label: t('employee.filter.admin'),
    },
    {
      id: 2,
      value: 2,
      label: t('employee.filter.pending'),
    },
  ];

  useLayoutEffect(() => {
    dispatch(fetchEmployees());
  }, []);

  // useEffect(() => {
  //   if(props.employeesList.length > 0){
  //     setIsLoading(false)
  //   }else{
  //     setIsLoading(true)
  //   }
  // },[props.employeesList]);

  useEffect(() => {
    setLoader(props.isLoading);
  }, [props.isLoading]);

  useEffect(() => {
    if (props.modalClose) {
      handleModalOpen();
    }
  }, [props.modalClose]);

  const handleDelete = (empId) => {
    const getAlert = () => (
      <SweetAlert
        danger
        closeOnClickOutside={true}
        onCancel={() => setAlert(null)}
        title={`${t('employee.delete.heading')}`}
        onConfirm={() => {
          dispatch(deleteEmployees(empId));
          setAlert(null);
        }}
        confirmBtnStyle={{ background: '#71BF5D' }}
      >
        {t('employee.delete.text')}
      </SweetAlert>
    );
    setAlert(getAlert());
  };

  const handleModalOpen = () => {
    setEdit(false);
    setModalEmployeeOpen({});
  };

  const handleChangeName = (name) => setName(name);
  const handleChangeArea = (area) => setArea(area);

  const filteredEmps = (emps) => {
    if (empFilter === '0') return emps.filter((emp) => emp.admin === 0 && emp.joined_at !== null);

    if (empFilter === '1') return emps.filter((emp) => emp.admin === 1);

    if (empFilter === '2') return emps.filter((emp) => emp.joined_at === null);
    return emps;
  };

  const EmployeeDetailHeader = ({ employee, edit }) => {
    return (
      <div className='modal-employee-header d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center '>
          <div className='employee-img-container mr-3'>
            <img src={employee.avatar} className='employee-img' alt='Employee' title='Employee' />
          </div>
          <div className='dec-container'>
            <h3 className='modal-name'>{name !== null ? name : employee.name}</h3>
            <p className='employee-dec modal-dec'>
              {t('employee.details.delegated')}: {area !== null ? area : employee.area}
            </p>
          </div>
        </div>

        <div className='header-icon-container d-flex align-items-center'>
          <div>{employee.joined_at === null && <EnvelopeSvg onClick={() => dispatch(reinvite(employee.email))} />}</div>
          <div>
            <svg className='mx-3' xmlns='http://www.w3.org/2000/svg' width='28.748' height='23' viewBox='0 0 28.748 23' onClick={() => setEdit(true)}>
              <path
                id='Icon_awesome-user-edit'
                data-name='Icon awesome-user-edit'
                d='M10.062,11.5a5.75,5.75,0,1,0-5.75-5.75A5.749,5.749,0,0,0,10.062,11.5Zm4.025,1.437h-.75a7.82,7.82,0,0,1-6.549,0h-.75A6.039,6.039,0,0,0,0,18.975v1.869A2.157,2.157,0,0,0,2.156,23H14.5a2.157,2.157,0,0,1-.117-.957l.305-2.736.054-.5.355-.355,3.472-3.472a5.976,5.976,0,0,0-4.488-2.044Zm2.035,6.527-.305,2.74a.715.715,0,0,0,.791.791l2.736-.305,6.195-6.195-3.221-3.221-6.195,6.19Zm12.313-7.385-1.7-1.7a1.076,1.076,0,0,0-1.518,0l-1.7,1.7-.184.184,3.225,3.221L28.435,13.6a1.082,1.082,0,0,0,0-1.523Z'
                fill='#c5c5c5'
              />
            </svg>
          </div>
          <div>
            <svg xmlns='http://www.w3.org/2000/svg' width='20.125' height='23' viewBox='0 0 20.125 23' onClick={() => handleDelete(employee.id)}>
              <path
                id='Icon_awesome-trash-alt'
                data-name='Icon awesome-trash-alt'
                d='M1.438,20.844A2.156,2.156,0,0,0,3.594,23H16.531a2.156,2.156,0,0,0,2.156-2.156V5.75H1.438Zm12.219-11.5a.719.719,0,0,1,1.438,0V19.406a.719.719,0,0,1-1.438,0Zm-4.313,0a.719.719,0,0,1,1.438,0V19.406a.719.719,0,0,1-1.437,0Zm-4.313,0a.719.719,0,0,1,1.438,0V19.406a.719.719,0,0,1-1.438,0ZM19.406,1.437H14.016L13.593.6a1.078,1.078,0,0,0-.966-.6H7.493a1.066,1.066,0,0,0-.961.6l-.422.84H.719A.719.719,0,0,0,0,2.156V3.594a.719.719,0,0,0,.719.719H19.406a.719.719,0,0,0,.719-.719V2.156A.719.719,0,0,0,19.406,1.437Z'
                fill='#c5c5c5'
              />
            </svg>
          </div>
        </div>
        {/* <span>{employee.name}</span>
      <span>Delegated To : {employee.area}</span> */}
      </div>
    );
  };

  return (
    <div className='employee-main-container'>
      <h1>{t('employee.heading')}</h1>
      {loader ? (
        <PreLoader />
      ) : (
        <>
          <div className='btn-container d-flex justify-content-end '>
            <button
              className='project-btn'
              onClick={() => {
                setModalOpen(!modalOpen);
              }}
            >
              + {t('button.add')}
            </button>

            <Dropdown className='selectElement' bsPrefix={'dropdown lang-dropdown'}>
              <div className='dropdown-btn-container'>
                <Dropdown.Toggle id='dropdown-basic'>{empFilterOptions[parseInt(empFilter)].label}</Dropdown.Toggle>
              </div>
              <Dropdown.Menu bsPrefix={'dropdown-menu langauge-dropdown'}>
                <Dropdown.Item onClick={() => setEmpFilter('1')}>{t('employee.filter.admin')}</Dropdown.Item>
                <Dropdown.Item onClick={() => setEmpFilter('0')}>{t('employee.filter.active')}</Dropdown.Item>
                <Dropdown.Item onClick={() => setEmpFilter('2')}>{t('employee.filter.pending')}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <ModalPopup open={modalOpen} header={t('employee.details.heading')} setModalClose={setModalOpen}>
              <Invite setModalOpen={setModalOpen} />
            </ModalPopup>
          </div>
          <div className='container-fluid p-0'>
            <div className='row' aria-hidden='true'>
              {filteredEmps(props.employeesList).length !== 0
                ? filteredEmps(props.employeesList).map((employee, index) =>
                    employee.id !== props.loginId ? (
                      <React.Fragment key={index}>
                        <div className='col-12 col-md-4 mb-3' onClick={(e) => setModalEmployeeOpen({ [employee.id]: true })}>
                          <div className='employee-container d-flex align-items-center justify-content-between'>
                            {parseInt(employee.admin) === 1 ? (
                              <div className='admin-check-container'>
                                <img src='/images/admin.svg' className='admin-check' alt='Employee' title='Employee' />
                              </div>
                            ) : (
                              ''
                            )}
                            <div className='employee-img-container'>
                              <img src={employee.avatar} className='employee-img' alt='Employee' title='Employee' />
                            </div>
                            <div className='dec-container'>
                              <h3>{employee.name || employee.email}</h3>
                              <p className='employee-dec'>
                                {t('employee.details.delegated')}: {employee.area}
                              </p>
                            </div>
                          </div>
                        </div>
                        {alert}
                        <ModalPopup
                          dialogClass={'employee-modal'}
                          open={modalEmployeeOpen[employee.id] || false}
                          header={<EmployeeDetailHeader edit={edit} employee={employee} />}
                          setModalClose={() => {
                            setModalEmployeeOpen({ [employee.id]: false });
                            setEdit(false);
                            setName(null);
                            setArea(null);
                          }}
                        >
                          <Details employee={employee} changeName={(name) => handleChangeName(name)} changeArea={(area) => handleChangeArea(area)} edit={edit} />
                        </ModalPopup>
                      </React.Fragment>
                    ) : (
                      ''
                    )
                  )
                : t('employee.no_employees')}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  employeesList: state.employees.employeesList,
  modalClose: state.response.modalClose,
  isLoading: state.response.isLoading,
  loginId: state.login.id,
});

export default connect(mapStateToProps)(Employee);
