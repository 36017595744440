const inviteReducer = (state = { closeModal: false }, action) => {
  switch (action.type) {
    case 'SENT_INVITE': {
      return {
        ...state,
        closeModal: true,
      };
    }
    default:
      return state
  }
};

export default inviteReducer;
