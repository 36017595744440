/** @format */

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Nav from '../components/Nav';
import Login from '../components/Login';
import Register from '../components/Register';
import { history } from './history';
import Home from '../components/Home';
import { connect } from 'react-redux';
import Invite from '../components/Invite';
import InvitedRoute from './InvitedRoute';
import Task from '../components/Task';
import AdminRoute from './AdminRoute';
import Products from '../components/Product';
import Employee from '../components/Employee';
import InProgress from '../components/InProgress';
import EmployeeTask from '../components/EmployeeTask';
import Notifications from '../components/Notifications';
import Setting from '../components/Setting';
import Summary from '../components/Summary';
import { logout } from '../actions';
import NavLink from '../containers/shared/NavLink';
import ForgetPassword from '../components/ForgetPassword';
import ResetPassword from '../components/ResetPassword';
import ForgetPasswordRoute from './ForgetPasswordRoute';
import NotFound from '../components/NotFound';
import MyTasks from '../components/MyTasks';
import TaskForm from '../components/TaskForm';
import { useTranslation } from 'react-i18next';

const Routers = ({ isLoggedIn, isAdmin, username, avatar, location }) => {
  const [toggleClass, setToggleClass] = useState(false);
  const currentPath = window.location.pathname;
  const currentUrl = window.location.href;
  const [activeLink, setActiveLink] = useState('');
  const [navSelected, setNavSelected] = useState(false);
  const { t, i18n } = useTranslation('common');
  const logoutJson = {
    nav: {
      url: 'login',
      dispatch: logout(),
      src: '/images/logout.svg',
      order: 0,
      text: '',
    },
  };

  useEffect(() => {
    if (toggleClass) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }
  }, [toggleClass]);

  useEffect(() => {
    if (navSelected) {
      setToggleClass(false);
    }
  }, [navSelected]);

  return (
    <Router history={history} getUserConfirmation={(message, callback) => {}}>
      <Redirect from='/:url*(/+)' to={currentPath.substr(-1) === '/' ? currentPath.slice(0, -1) : `${currentPath}${window.location.search}`} />
      {isLoggedIn === 'true' || isLoggedIn === true ? currentPath === '/' ? history.push('/tasks') : <Redirect to={history.location.pathname} /> : currentPath === '/register' ? history.push('/register') : currentPath === '/reset-password' ? history.push(currentUrl) : <Redirect to='/login' />}
      {isLoggedIn === 'true' || isLoggedIn === true ? (
        <main className='main-container d-flex'>
          <div className='mobile-nav'>
            <div>
              <div
                className={toggleClass ? `wrapper-menu active` : `wrapper-menu`}
                onClick={() => {
                  setToggleClass(!toggleClass);
                  setNavSelected(false);
                }}
              >
                <div className='line-menu half start'></div>
                <div className='line-menu second'></div>
                <div className='line-menu half end'></div>
              </div>

              <img src='/images/logo.png' />

              <div className='profile-container d-flex align-items-center'>
                <img src={avatar} alt='avatar' className='profile-img-container' />
                <p className='user-name'>{username}</p>
                <NavLink url='/login' type='logout' classNames={'logout-link ml-auto'} text={''} svg={logoutJson.nav.src} />
              </div>
            </div>
          </div>
          <aside className={toggleClass ? `sidebar-container openMenu` : `sidebar-container`}>
            <Nav onChangeNav={() => setNavSelected(true)} />
          </aside>
          <section className='section-main-container'>
            <Switch>
              <Route path='/invite' component={Invite}>
                {isLoggedIn === 'true' || isLoggedIn === true ? <Invite /> : <Redirect to='/login' />}
              </Route>
              <Route path='/product' component={Products}>
                {isLoggedIn === 'true' || isLoggedIn === true ? <Products /> : <Redirect to='/login' />}
              </Route>
              <Route path='/employees' component={Employee}>
                {isLoggedIn === 'true' || isLoggedIn === true ? <Employee /> : <Redirect to='/login' />}
              </Route>
              <Route path='/inprogress' component={InProgress}>
                {isLoggedIn === 'true' || isLoggedIn === true ? <InProgress /> : <Redirect to='/login' />}
              </Route>
              <Route path='/notifications' component={Notifications}>
                {isLoggedIn === 'true' || isLoggedIn === true ? <Notifications /> : <Redirect to='/login' />}
              </Route>
              <Route path='/announcement' component={Notifications}>
                {isLoggedIn === 'true' || isLoggedIn === true ? <Notifications /> : <Redirect to='/login' />}
              </Route>
              <Route path='/summary' component={Summary}>
                {isLoggedIn === 'true' || isLoggedIn === true ? <Summary /> : <Redirect to='/login' />}
              </Route>
              <Route path='/my-tasks' component={MyTasks}>
                {isLoggedIn === 'true' || isLoggedIn === true ? <MyTasks /> : <Redirect to='/login' />}
              </Route>

              <Route path='/task/create'>{isLoggedIn === 'true' || isLoggedIn === true ? <TaskForm /> : <Redirect to='/login' />}</Route>

              <AdminRoute path='/tasks' component={Task}>
                {isAdmin === 0 || isAdmin === '0' ? <EmployeeTask /> : <Task />}
              </AdminRoute>
              <Route path='/settings' component={Setting}>
                {isLoggedIn === 'true' || isLoggedIn === true ? <Setting /> : <Redirect to='/login' />}
              </Route>
              <Route component={NotFound} />
            </Switch>
          </section>
        </main>
      ) : (
        <Switch>
          <Route exact path='/' component={Home}>
            {isLoggedIn === 'true' || isLoggedIn === true ? <Redirect to='/tasks' /> : <Redirect to='/login' />}
          </Route>
          <Route path='/forget-password' component={ForgetPassword} />
          <ForgetPasswordRoute path='/reset-password' component={ResetPassword} />
          <Route path='/login' component={Login}>
            {isLoggedIn === 'true' || isLoggedIn === true ? <Redirect to='/tasks' /> : <Login />}
          </Route>
          <InvitedRoute exact path='/register' component={Register} />
        </Switch>
      )}
    </Router>
  );
};
const mapStateToProps = (state) => ({
  isLoggedIn: state.login.isLoggedIn,
  username: state.login.username,
  avatar: state.login.avatar,
  isRegistered: state.register.isRegistered,
  isAdmin: state.login.isAdmin,
});
export default connect(mapStateToProps)(Routers);
