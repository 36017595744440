const helperReducer = (
  state = {
    unSavedChanges: false,
    newTask: null,
  },
  action
) => {
  switch (action.type) {
    case 'UNSAVED_CHANGES': {
      return {
        ...state,
        unSavedChanges: true,
      };
    }
    case 'SAVED_CHANGES': {
      return {
        ...state,
        unSavedChanges: false,
      };
    }
    case 'NEW_TASK_SUCCESS': {
      return {
        ...state,
        newTask: action.payload,
      };
    }

    case 'NEW_TASK_CLEAR': {
      return {
        ...state,
        newTask: null,
      };
    }

    default:
      return state;
  }
};

export default helperReducer;
