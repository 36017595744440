import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmployee } from '../../../actions';
import FormSubmitButton from '../../shared/FormSubmitButton';
import Input from '../../shared/Input';
import Select from '../../shared/Select';
import './details.scss';

const Details = ({employee, changeName, edit, changeArea}) => {
  const userDetails = employee
    const serverErrors = useSelector(state => state.response.errors)
    const dispatch = useDispatch();
    const [errors, setErrors] = useState([]);
    const [name, setName] = useState(userDetails.name !== null ?  userDetails.name : "")
    const [email, setEmail] = useState(userDetails.email !== null ?  userDetails.email : "")
    const [address, setAddress] = useState(userDetails.address !== null ?  userDetails.address : "")
    const [phone, setPhone] = useState(userDetails.phone !== null ?  userDetails.phone : "")
    const [company, setCompany] = useState(userDetails.company !== null ?  userDetails.company : "")
    const [area, setArea] = useState(userDetails.area !== null ?  userDetails.area : "")
    const [zip, setZip] = useState(userDetails.zip !== null ?  userDetails.zip : "")
    const [vat, setVAT] = useState(userDetails.vat !== null ?  userDetails.vat : "")
    const [city, setCity] = useState(userDetails.city !== null ?  userDetails.city : "")
    const [admin, setAdmin] = useState(userDetails.admin !== null ?  userDetails.admin : "")
    const {t} = useTranslation('common')

    useEffect(() => {  
        if(serverErrors.length > 0){
            let errorsArray = []
            Object.keys(serverErrors)
            .map((key) => { 
                return errorsArray[key] = serverErrors[key]
            });
            setErrors(errorsArray)
        }
    },[serverErrors])

    const handleChange = e => {
        const value = e.target.value;
        const name = e.target.name;
        if(name === 'name'){
            setName(value)
            changeName(value)
        }
        if(name === 'address'){
            setAddress(value)
        }
        if(name === 'phone'){
            setPhone(value)
        }
        if(name === 'company'){
          setCompany(value)
        }
        if(name === 'zip'){
          setZip(value)
        }
        if(name === 'city'){
          setCity(value)
        }
        if(name === 'vat'){
          setVAT(value)
        }
        if(name === 'area'){
          setArea(value)
          changeArea(value)
        }
        if(name === 'is_admin'){
          setAdmin(parseInt(value))
        }
        const updatedErrors = errors.map((error) => {
            return {
                ...error,
                [name]: ""
            }
        }) 
        setErrors(updatedErrors)
    }

    const formValidation = () => {
        let formIsValid = true;
        let errors = [];

        if (name.length === 0) {
            formIsValid = false;
            errors.push({'name': t('employee.edit.name.error')})
        }

        if (address.length === 0) {
            formIsValid = false;
            errors.push({'address': t('employee.edit.address.error')})
        }

        if (phone.length === 0) {
            formIsValid = false;
            errors.push({'phone': t('employee.edit.phone.error')})
        }

        if (isNaN(parseInt(admin)) || ( parseInt(admin) !== 0 && parseInt(admin) !== 1) ) {
          formIsValid = false;
          errors.push({'is_admin' : t('invite.form.admin.error.empty')})
        }

        if (company.length === 0) {
          formIsValid = false;
          errors.push({'company': t('employee.edit.company.error')})
        }

        if (zip.length === 0) {
          formIsValid = false;
          errors.push({'zip': t('employee.edit.zip.error')})
        }

        if (city.length === 0) {
          formIsValid = false;
          errors.push({'city': t('employee.edit.city.error')})
        }

        if (vat.length === 0) {
          formIsValid = false;
          errors.push({'vat': t('employee.edit.vat.error')})
        }

        if (area.length === 0) {
          formIsValid = false;
          errors.push({'area': t('employee.edit.area.error')})
        }

        if(!formIsValid){
            setErrors(errors)
        }

        return formIsValid;
    }

    const handleSubmit = event => {
        event.preventDefault();
        const validateForm = formValidation();
        if (!validateForm) {
            return false;
        }
        // Dispatch updateEmployee Action with payloads employee id, employee name, employee address
        dispatch(updateEmployee({id: userDetails.id, name, phone, address, company, zip, city, vat, area, admin}))
    }

    const options = [
      {
        name: t('invite.form.admin.options.select_options'),
        value: ''
      },
      {
        name: t('invite.form.admin.options.admin'),
        value: 1
      },
      {
        name: t('invite.form.admin.options.employee'),
        value: 0
      }
    ]

    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className='d-flex flex-wrap justify-content-between employee-dec-container'>
            {
                  edit === true ?
                  <div className="column">
                      <Input
                          className='input-field employee-input'
                          mainClass='custom-input'
                          type={'text'}
                          name={'name'}
                          id={'name'}
                          placeholder={t('employee.edit.name.placeholder')}
                          value={name}
                          label={t('employee.edit.name.label')}
                          errors={errors}
                          onChangeHandle={handleChange}
                      />
                  </div>
                  :
                  ""
                }
            <div className="column">
                { 
                      edit === false ? 
                      <div className="d-flex align-items-center"><strong className="aligned-label">{t('register.email')}:</strong> {employee.email}</div>
                      :
                      <Input
                        className='input-field disabled employee-input'
                        mainClass='custom-input'
                        type={'email'}
                        name={'email'}
                        id={'email'}
                        placeholder={'Email'}
                        disabled
                        label={t('employee.details.email')}
                        value={email}
                      />
                    }
                    {' '}
                </div>
                <div className="column">
                  {
                    edit === false ? <div className="d-flex align-items-center"><strong className="aligned-label">{t('register.phone')}:</strong> {employee.phone}</div> :
                    <Input
                          className='input-field employee-input'
                          mainClass='custom-input'
                          type={'text'}
                          name={'phone'}
                          id={'phone'}
                          placeholder={t('employee.edit.phone.placeholder')}
                          value={phone}
                          errors={errors}
                          label={t('employee.edit.phone.label')}
                          onChangeHandle={handleChange}
                        />
                  }{' '}
                </div>
                <div className="column">
                {
                  edit === false ? <div className="d-flex align-items-center"><strong className="aligned-label">{t('invite.form.admin.label')}:</strong> {
                    parseInt(employee.admin) === 1 ? t('invite.form.admin.options.admin') : t('invite.form.admin.options.employee')
                  }</div> :
                  <Select 
                      className='input-field employee-input'
                      mainClass='custom-input'
                      type={'text'}
                      name={'is_admin'}
                      id={'is_admin'}
                      placeholder={t('invite.form.admin.placeholder')}
                      options={options}
                      selected={parseInt(employee.admin)}
                      value={admin}
                      label={t('invite.form.admin.label')}
                      errors={errors}
                      onChangeHandle={handleChange}
                  />
                }{' '}
              </div>
              <div className="column">
                {
                  edit === false ? <div className="d-flex align-items-center"><strong className="aligned-label">{t('register.company')}:</strong> {employee.company}</div> :
                  <Input
                        className='input-field employee-input'
                        mainClass='custom-input'
                        type={'text'}
                        name={'company'}
                        id={'company'}
                        placeholder={t('employee.edit.company.placeholder')}
                        value={company}
                        errors={errors}
                        label={t('employee.edit.company.label')}
                        onChangeHandle={handleChange}
                      />
                }{' '}
              </div>
              <div className="column">
                {
                  edit === false ? <div className="d-flex align-items-center"><strong className="aligned-label">{t('register.vat')}:</strong> {employee.vat}</div> :
                  <Input
                        className='input-field employee-input'
                        mainClass='custom-input'
                        type={'text'}
                        name={'vat'}
                        id={'vat'}
                        placeholder={t('employee.edit.vat.placeholder')}
                        value={vat}
                        errors={errors}
                        label={t('employee.edit.vat.label')}
                        onChangeHandle={handleChange}
                      />
                }{' '}
              </div>
              <div className="column">
                {
                  edit === false ? <div className="d-flex align-items-center"><strong className="aligned-label">{t('register.city')}:</strong> {employee.city}</div> :
                  <Input
                        className='input-field employee-input'
                        mainClass='custom-input'
                        type={'text'}
                        name={'city'}
                        id={'city'}
                        placeholder={t('employee.edit.city.placeholder')}
                        value={city}
                        errors={errors}
                        label={t('employee.edit.city.label')}
                        onChangeHandle={handleChange}
                      />
                }{' '}
              </div>
              <div className="column">
                {
                  edit === false ? <div className="d-flex align-items-center"><strong className="aligned-label">{t('register.area')}:</strong> {employee.area}</div> :
                  <Input
                        className='input-field employee-input'
                        mainClass='custom-input'
                        type={'text'}
                        name={'area'}
                        id={'area'}
                        placeholder={t('employee.edit.area.placeholder')}
                        value={area}
                        errors={errors}
                        label={t('employee.edit.area.label')}
                        onChangeHandle={handleChange}
                      />
                }{' '}
              </div>
              <div className="column">
                {
                  edit === false ? <div className="d-flex align-items-center"><strong className="aligned-label">{t('register.zip')}:</strong> {employee.zip}</div> :
                  <Input
                        className='input-field employee-input'
                        mainClass='custom-input'
                        type={'text'}
                        name={'zip'}
                        id={'zip'}
                        placeholder={t('employee.edit.zip.placeholder')}
                        value={zip}
                        errors={errors}
                        label={t('employee.edit.zip.label')}
                        onChangeHandle={handleChange}
                      />
                }{' '}
              </div>
            <div className="column">
                { edit===false ? <div className="d-flex align-items-center"><strong className="aligned-label">{t('register.address')}:</strong>{employee.address}</div> : 
                  <Input
                        className='input-field employee-input'
                        mainClass='custom-input'
                        type={'text'}
                        name={'address'}
                        id={'address'}
                        placeholder={t('employee.edit.address.placeholder')}
                        value={address}
                        errors={errors}
                        label={t('employee.edit.address.label')}
                        onChangeHandle={handleChange}
                      />
                }{' '}
            </div>
          </div>
          <div className="total-container">
                <p>{t('employee.details.total')}</p>
                <p className="total-value">{employee.completed}</p>
            </div>
            {edit === false ? "" : 
              <div className="d-flex align-items-center justify-content-between">
                <FormSubmitButton label={t('button.save')} />
              </div>
            }
        </form>
      </>
    );
}

export default Details