import axios from 'axios';
import { sessionExpired } from './actions';
import {store} from './index'
import { history } from './router/history';

axios.interceptors.request.use((request) => {
    request.baseURL = `${ process.env.REACT_APP_API_URL }`
    request.withCredentials = true
    return request
})

axios.interceptors.response.use((response) => {
    return response
},(error) => {
    if(window.location.pathname !== '/register' || window.location.pathname !== '/reset-password') {
        if(error.response.status === 401) {  
            history.push('/login')
            store.dispatch(sessionExpired())
        }
    }
    return Promise.reject(error);
})

export default axios