const employeesReducer = (state = {employeesList: []}, action) => {
    switch(action.type) {
        case 'SET_EMPLOYEES' :
           return {
               ...state,
               employeesList: action.payloads
           };
        case 'REMOVE_EMPLOYEE_LIST' :
            const employeesList = state.employeesList.filter(employee => {
                return employee.id !== action.employeeId
            });
            return {
                ...state,
                employeesList
            }     
        case 'UPDATE_EMPLOYEE_DETAIL' : {
            return {
                ...state,
                employeesList: state.employeesList.map((employee, index) => {
                    if(employee.id === action.payloads.id){
                        return {
                            ...employee,
                            ...action.payloads
                        }
                    }else{
                        return {
                            ...employee
                        }
                    }
                })
            }
        }    
        default :
            return state;
    }
}

export default employeesReducer
