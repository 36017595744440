import React from 'react'
import './PreLoader.scss'

const PreLoader = () => {
    return (
        <div className="sk-spinner-wave sk-spinner">
            <div className="sk-rect1"></div>
            <div className="sk-rect2"></div>
            <div className="sk-rect3"></div>
            <div className="sk-rect4"></div>
            <div className="sk-rect5"></div>
        </div>
    )
}

export default PreLoader