const productsReducer = (state = {productsList: [], productSynced: true}, action) => {
    switch(action.type) {
        case 'SET_PRODUCTS' :
           return {
               ...state,
               productsList: action.payloads
           };  
        case 'SYNCING_PRODUCTS':
            return {
                ...state,
                productSynced: false    
            }
        case 'PRODUCT_SYNCED':
            return {
                ...state,
                productSynced: true    
            }       
        case 'REMOVE_PRODUCT' :
            const productsList = state.productsList.filter(product => {
                return product.id !== action.productId
            });
            return {
                ...state,
                productsList
            }      
        case 'ADDED_PRODUCT' : 
            return {
                ...state,
                productsList: state.productsList.concat(action.response.data)
            }
        case 'UPDATED_PRODUCT' : 
            return {
                ...state,
                productsList: state.productsList.map((product) => 
                    product.id === action.payloads.productId ? {
                        ...product,
                        ...action.payloads,
                    }
                    : product
                )
            }       
        default :
            return state;
    }
}

export default productsReducer
