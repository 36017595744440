import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { forgetPassword } from '../../actions/password'
import Input from '../shared/Input'
import PreLoader from '../shared/PreLoader'

const ForgetPassword = () => {
    const {t} = useTranslation('common')
    const [email, setEmail] = useState("")
    const [errors, setErrors] = useState([])
    const dispatch = useDispatch()
    const serverErrors = useSelector(state => state.response.errors)
    const loader = useSelector(state => state.response.isLoading)

    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        if(name === 'email'){
            setEmail(value)
        }
        const updatedErrors = errors.map((error) => {
            return {
                ...error,
                [name]: ""
            }
        }) 
        setErrors(updatedErrors)
    }

    useEffect(() => {  
        if(serverErrors.length > 0){
            let errorsArray = []
            Object.keys(serverErrors)
            .map((key) => { 
                return errorsArray[key] = serverErrors[key]
            });
            setErrors(errorsArray)
        }
    },[serverErrors])

    const formValidation = () => {
        let formIsValid = true;
        let errors = [];
        const validEmailRegex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (email.length === 0) {
          formIsValid = false;
          errors.push({'email': t("forget_password.form.email.error.empty")})
        } else if (!validEmailRegex.test(email)) {
          formIsValid = false;
          errors.push({'email': t("forget_password.form.email.error.invalid")})
        }
        if(!formIsValid){
            setErrors(errors)
        }

        return formIsValid;
    }
    const formHandler = (event) => {
        event.preventDefault();
        const validateForm = formValidation();
        if (!validateForm) {
          return false;
        }
        dispatch(forgetPassword({email}));
    }
    
    return (
        <div className='login-main-container d-flex align-items-center justify-content-center'>
            {
                loader ? 
                <PreLoader />
                :
                <div className='login-container text-center'>
                    <h1>{t('forget_password.header')}</h1>
                    <form onSubmit={(e) => formHandler(e)}>
                        <div className=''>
                            <Input
                                className='input-field employee-input'
                                errors={errors}
                                label={t('forget_password.form.email.label')}
                                placeholder={t('forget_password.form.email.placeholder')}
                                name={"email"}
                                value={email}
                                onChangeHandle={handleChange}
                                type={"text"}
                            />
                        </div>
                        <div className=''>
                        <input type='submit' className='project-btn' value={t('button.forget')} />
                        </div>
                        <Link to="/login">
                            {t('button.back_to_login')}
                        </Link>
                    </form>
                </div>   
            }
        </div>
    )
}

export default ForgetPassword