import React from 'react'

const FormSubmitButton = ({label, onClick}) => {
    return <div className=''>
                <input
                className='project-btn'
                type='submit'
                value={label}
                />
            </div>
}

export default FormSubmitButton