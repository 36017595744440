import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PlusCircleFillSvg from "../../svgs/plusCircleFill";
import DeleteXCircleFillSvg from "../../svgs/xCircleFill";
import PreLoader from "../shared/PreLoader";
import { productItemFields } from "./constants";
import DropDown from "../DropDown/DropDown";

const Products = ({
  allProductsList,
  products,
  selectedProducts,
  setSelectedProducts,
  setExistingTaskProducts,
  setSaveBtnState,
  onProductRemove,
  taskId,
  productId,
  productPriceSuccess,
  enabledSaveBtn,
}) => {
  const { t } = useTranslation("common");
  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    let productsData = [];
    if (products && products.length > 0) {
      productsData = products.map((product, index) => {
        return {
          name: product.name,
          errors: { product: null, quantity: null },
          quantity: product?.pivot?.quantity ?? null,
          product: product.id,
          model: product.model,
          preloaded: true,
          task_product_id: product?.pivot?.id ?? null,
          search: "",
          comment: product?.pivot?.comment ?? "",
        };
      });
    }

    const unsavedProducts = selectedProducts.filter(({ preloaded }) => !preloaded)
    setSelectedProducts([...unsavedProducts, ...productsData]);
  }, [products]);

  useEffect(() => {
    if (allProductsList && allProductsList.length > 0) {
      let populatedList = allProductsList.filter((item) => {
        return !selectedProducts.find((ele) => {
          return ele.product === item.id;
        });
      });
      setProductsList(populatedList);
    }
  }, [allProductsList, selectedProducts]);

  const onQuantityChange = (e, index) => {
    let quantity = e.target.value;
    let values = [...selectedProducts];
    values[index][e.target.name] = parseInt(quantity);
    values[index].errors = { ...values[index].errors, quantity: null };
    values[index].updated = true;
    enabledSaveBtn(values);
    setSelectedProducts(values);
  };

  const onCommentChange = (e, index) => {
    let comment = e.target.value;
    let values = [...selectedProducts];
    values[index][e.target.name] = comment;
    values[index].updated = true;
    enabledSaveBtn(values);
    setSelectedProducts(values);
  };

  const onProductSelect = (id, index) => {
    const product = allProductsList.find((product) => product.id === id);
    let populatedTaskProducts = [...selectedProducts];
    populatedTaskProducts[index] = {
      ...populatedTaskProducts[index],
      product: product.id,
      model: product.model,
      search: product.name,
      name: product.name,
      errors: { ...populatedTaskProducts[index].errors, product: null },
      updated: true,
    };
    enabledSaveBtn(populatedTaskProducts);
    setSelectedProducts(populatedTaskProducts);
  };

  const addNewProductItem = () => {
    let modifiedProductsArray = [{ ...productItemFields }, ...selectedProducts];
    enabledSaveBtn(modifiedProductsArray);
    setSelectedProducts(modifiedProductsArray);
  };

  const getOptions = (item) => {
    if (item && item.product) {
      return [allProductsList.find((p) => p.id === item.product), ...productsList];
    } else {
      return productsList;
    }
  };

  return (
    <>
      <div className="product-container">
        <button onClick={() => addNewProductItem()}>
          {t("task.employee_task_details.products.heading")}
          <PlusCircleFillSvg className="product-visit-add-btn plusIcon" />
        </button>
        <div className="product-content">
          <div className="column-container custom-product-container ">
            {selectedProducts.map((item, index) => {
              return (
                <div
                  key={index}
                  className='d-flex align-items-center justify-content-between custom-product-content'
                >
                  <div className='column d-flex justify-content-between content-one'>
                    <div className='width-50'>
                      <DropDown
                        options={getOptions(item)}
                        selectedOptions={item.product ? item : ''}
                        onChange={(value) => onProductSelect(value.id, index)}
                        label='name'
                        value='name'
                        isSearchable={true}
                        noOptionsMessage={t(
                          'product.messages.product_not_found'
                        )}
                        placeHolder={t('product.messages.search_product')}
                        className='products_drop_down'
                      />
                      {item.errors.product ? item.errors.product : ''}
                    </div>
                    <div className='content-one-inner-flex width-50 product-model-container'>
                      <p className='heading'>
                        {t('task.employee_task_details.products.model')}:{' '}
                      </p>
                      <p className='product-model-item'>{item.model}</p>
                    </div>
                    <div className='column-input d-flex flex-wrap align-items-center width-50'>
                      <div className='d-flex align-items-center content-one-inner-flex quality-column'>
                        <p className='heading'>
                          {t('task.employee_task_details.products.quantity')}:{' '}
                        </p>
                        <input
                          min={1}
                          type='number'
                          name='quantity'
                          step='any'
                          onChange={(e) => onQuantityChange(e, index)}
                          value={item.quantity}
                          className='input-field'
                        />
                      </div>
                      <div className='w-100'>
                        {item.errors.quantity ? item.errors.quantity : ''}
                      </div>
                        {productId === item.product && !productPriceSuccess ? (
                          <div className='loader-container'>
                            <PreLoader />
                          </div>
                        ) : (
                          ''
                        )}
                    </div>
                    <div className='d-flex align-items-center textArea-container content-one-inner-flex width-50'>
                      <div className='comment-title'>
                        <p className='heading'>
                          {t('task.employee_task_details.comment')}
                        </p>
                      </div>
                      <div className='textarea-container'>
                        <textarea
                          name='comment'
                          onChange={(e) => onCommentChange(e, index)}
                          value={item.comment}
                          className='input-field'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center button-container justify-content-around content-two'>
                    <button
                      onClick={(e) => onProductRemove(e, item, index)}
                      className='removeInput project-btn'
                    >
                      <DeleteXCircleFillSvg className='remove-cross-btn' />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
