export const productItemFields = {
  name: '',
  errors: {
    product: null,
    quantity: null,
  },
  quantity: '',
  product: null,
  model: '',
  preloaded: false,
  updated: true,
  search: '',
  comment: '',
};
