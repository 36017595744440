import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { addProduct, editProduct } from '../../../actions/products'
import FormSubmitButton from '../../shared/FormSubmitButton'
import Input from '../../shared/Input'
const Form = ({serverErrors, modalClose, productDetails, action, isAdmin}) => {
    const [name, setName] = useState(() => action === 'create' ? "" : productDetails.name)
    const [model, setModel] = useState(() => action === 'create' ? "" : productDetails.model)
    const [price, setPrice] = useState(() => action === 'create' ? isAdmin === 0 ? 0 : "" : productDetails.price)
    const [errors, setErrors] = useState([]);
    const dispatch = useDispatch();
    const {t} = useTranslation('common')
    const handleChange = event => {
        const name = event.target.name
        const val = event.target.value
        if(name === 'name'){
            setName(val)
        }
        if(name === 'model'){
            setModel(val)
        }
        if(name === 'price'){
            setPrice(val)
        }
        const updatedErrors = errors.map((error) => {
            return {
                ...error,
                [name]: ""
            }
        }) 
        setErrors(updatedErrors)
    }
    
    useEffect(() => {  
        if(serverErrors.length > 0){
            let errorsArray = []
            Object.keys(serverErrors)
            .map((key) => { 
                return errorsArray[key] = serverErrors[key]
            });
            setErrors(errorsArray)
        }
    },[serverErrors])

    const validate = () => {
        let errorsArray = []
        let formValid = true;
        if(!name || name.length === 0){
            errorsArray.push({'name': t('product.form.name.error')})
            formValid = false
        }
        if(!model || model.length === 0){
            errorsArray.push({'model': t('product.form.model.error')})
            formValid = false;
        }
        const priceRegex = /^\d+(?:[.,]\d+)*$/gm

        if(price === null || price < 0 || !priceRegex.test(price)){
            errorsArray.push({'price': t('product.form.price.error')})
            formValid = false;
        }
        if(!formValid){
            setErrors(errorsArray)
        }
        return formValid
    }

    const handleSubmit = event =>{
        event.preventDefault();
        const formValid = validate();
        if(!formValid){
            return false
        }
        if(action === 'create'){
            dispatch(addProduct({name, model, price}))
        }else{
            dispatch(editProduct({name, model, price, productId: productDetails.id}))
        }
        
    }
    return (
        <div>
            <form onSubmit={(e) => handleSubmit(e)} className="add-products">
                <Input
                type={"text"}
                name={"name"}
                id={"name"}
                placeholder={t('product.form.name.placeholder')} 
                errors={errors}
                onChangeHandle={handleChange}
                value={name}
                />
                <Input
                type={"text"}
                name={"model"}
                id={"model"}
                placeholder={t('product.form.model.placeholder')} 
                errors={errors}
                onChangeHandle={handleChange}
                value={model}
                />
                {
                    isAdmin
                    ?
                    <Input
                    type={"text"}
                    name={"price"}
                    id={"price"}
                    placeholder={t('product.form.price.placeholder')}
                    errors={errors}
                    onChangeHandle={handleChange}
                    value={price}
                    />
                    :
                    ""
                }
                <FormSubmitButton label={t('button.save')}/>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    serverErrors: state.response.errors,
    modalClose: state.response.modalClose
})

export default connect(mapStateToProps)(Form)