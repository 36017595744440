import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProduct, fetchUsers } from '../../actions';
import PreLoader from '../shared/PreLoader';
import List from './List'
import Overview from './Overview'
import './overview-main.scss';

const Summary = () => {
  const {t} = useTranslation('common')
  const dispatch = useDispatch();
  const task = useSelector(state => state.taskList.tasks)
  const employees = useSelector(state => state.employees)
  const products = useSelector(state => state.products.productsList)
  const [isLoading, setIsLoading] = useState(false)
  const [filterType, setFilterType] = useState("")

  useLayoutEffect(() => {
      dispatch(fetchUsers())
      dispatch(fetchProduct())
  },[])

  useEffect(() => {
    if(task.length > 0 && employees.employeesList && employees.employeesList.length > 0 && products && products.length > 0){
      setIsLoading(false)      
    }else{
      setIsLoading(true)      
    } 
  },[task, employees, products])

  const filterTasks = (filterText) => {
    setFilterType(filterText)
  }

  const filteredTaskTitle = () => {
    let taskTitle = ""
    if(filterType === 'new_task'){
      taskTitle = t('summary.tile.new_tasks')
    }else if(filterType === 'pending_task'){
      taskTitle = t('summary.tile.pending_tasks')
    }else if(filterType === 'need_attention'){
      taskTitle = t('summary.tile.need_attentions')
    }else if(filterType === 'invoiced_task'){
      taskTitle = t('summary.tile.invoiced')
    }else if(filterType === 'uninvoiced_task'){
      taskTitle = t('summary.tile.uninvoiced')
    }
    return taskTitle
  }
  
    return (
      <div className="summary-container">
        <h1>{t('summary.header')}</h1>
          { 
            isLoading ? <PreLoader /> : 
            <>
            <div> 
              <Overview changeTasks={(filterText) => filterTasks(filterText)}/>
            </div>
            <div className="new-task-container">
              <h1>{filteredTaskTitle()}</h1>
              <List filterType={filterType} products={products}/>
            </div>    
            </>
          }
      </div>
    );
}

export default Summary