import React from 'react'
import './SmallTile.scss'
const SmallTile = ({icon, title, amount}) => {
    return (
        <div className="d-flex align-items-center justify-content-between small-tile-container">  
            <div className="d-flex align-items-center">
                {
                    icon ? 
                    <div className={icon.classNames}>
                        <img src={icon.src} className={icon.iconSrcClassNames} alt="" />
                    </div>
                    :
                    ""
                }
                <p className={title.classNames}>{title.text}</p>
            </div>
            <p className={amount.classNames}>{amount.text}</p>
        </div>
    )
}

export default SmallTile