import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncDebounce } from 'react-table'
import './Filter.scss'

export const Filter = ({ filter, setFilterData }) => {
    const [value, setValue] = useState(filter)
    const onChange = useAsyncDebounce(value => {
        setFilterData(value || undefined)
    }, 1000)
    const {t} = useTranslation('common')

    return (
        <div className='input-container'>
            <input
            type='text'
            className='input-field search-input'
            value={value || ''} 
            onChange={e => {
                setValue(e.target.value)
                onChange(e.target.value)
            }} 
            placeholder={`${t('datatable.search')}...`}
            />
      </div>
    )
}