import Api from '../Api'

export const fetchProduct = () => {
    return (dispatch) => {
        Api.get(`sanctum/csrf-cookie`)
        .then(response => {
            Api.get('api/products')
            .then(response => {
              dispatch({type: 'SET_PRODUCTS', payloads: response.data})  
            }).catch(errors => {
            })
        })
    }
}

export const deleteProduct = (productId) => {
    return (dispatch) => {
        Api.get(`sanctum/csrf-cookie`)
        .then(response => {
            Api.delete(`api/products/${productId}`)
            .then(response => {
                dispatch({type: 'SUCCESS', 'message': 'Successfully Deleted Product !!'});
                dispatch({type: 'REMOVE_PRODUCT', productId})
                dispatch({type: 'RESET_VALIDATION_ERROR'})
            }).catch((errors) => {
                if(errors.response.status === 422){
                    dispatch({type: 'ERROR', 'status': errors.response.status , 'message': errors.response.data});
                }else{
                    dispatch({type: 'ERROR', 'status': errors.response.status , 'message': "Something Went Wrong"});
                }
            })
        })
    }
}

export const addProduct = (payloads) => {
    return (dispatch) => {
        Api.get(`sanctum/csrf-cookie`).then(response => {
            Api.post(`api/products`,{
                name: payloads.name,
                model: payloads.model,
                price: payloads.price
            }).then(response => {
                dispatch({type: 'PRODUCT_SUCCESS', 'message': 'Successfully Added!'});
                dispatch({type: 'ADDED_PRODUCT', response})    
                dispatch({type: 'RESET_VALIDATION_ERROR'})  
            }).catch(errors => {
                if(errors.response.status === 422){
                    dispatch({type: 'ERROR', 'status': errors.response.status, 'message': errors.response.data});
                }else{
                    dispatch({type: 'ERROR', 'status': errors.response.status, 'message': errors.response.data});
                }
            })
        })
    }
}

export const editProduct = (payloads) => {
    return (dispatch) => {
        Api.get(`sanctum/csrf-cookie`).then(response => {
            Api.put(`api/products/${payloads.productId}`,{
                name: payloads.name,
                model: payloads.model,
                price: payloads.price
            }).then(response => {
                dispatch({type: 'PRODUCT_SUCCESS', 'message': 'Successfully Updated!'});   
                dispatch({type: 'UPDATED_PRODUCT', payloads})   
                dispatch({type: 'RESET_VALIDATION_ERROR'})  
            }).catch(errors => {
                if(errors.response.status === 422){
                    dispatch({type: 'ERROR', 'status': errors.response.status, 'message': errors.response.data});
                }else{
                    dispatch({type: 'ERROR', 'status': errors.response.status, 'message': errors.response.data});
                }
            })
        })
    }
}

export const syncProducts = () => {
    return (dispatch) => {
        Api.get(`sanctum/csrf-cookie`).then(response => {
            dispatch({type: 'API_IS_FETCHING'})
            dispatch({type: 'SYNCING_PRODUCTS'})
            Api.get(`api/fetch-products`).then(response => {
                dispatch({type: 'SET_PRODUCTS', payloads: response.data})  
                dispatch({type: 'PRODUCT_SYNCED'})
                dispatch({type: 'API_FETCHED'})
              }).catch(errors => {
              })
        })
    }
}