import React from 'react'
import './LargeTile.scss'

const LargeTile = ({title, amount}) => {
    return (
        <div>
            <p className={title.classNames}>{title.text}</p>
            <p className={amount.classNames}>{amount.text}</p>
        </div>
    )
}

export default LargeTile