import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import Input from '../Input'

const DropDown = ({items,inputClass, toggleClass, placeholder, menuClass, searchType, selected, searchBy, onChangeHandle}) => {
    const [dropDownItems, setDropDownItems] = useState([])
    const [search, setSearch] = useState(searchType === 'array' ? [] :  "")
    
    useLayoutEffect(() => {
        setDropDownItems(items)
        if(selected){
            setSearch(selected)
        }else{
            setSearch("")
        }
    },[items,selected])


    useEffect(() => {
        if(search.length > 0){
            const searchByArray = searchBy.split(',');            
            let array = items.filter(item => {
                let i = 0;
                let filter = false;
                while(i < searchByArray.length){
                    if(searchByArray[i] === 'name'){
                        if(item.name && item.name.length > 0){
                            if(item.name.toLowerCase().includes(search.toLowerCase())){
                                filter = true
                            }
                        }
                    }else{
                        if(item[searchByArray[i]].toLowerCase().includes(search.toLowerCase())){
                            filter = true
                        }
                    }
                    
                    if(filter) break;
                    i++;
                }
                return filter;
            })
            setDropDownItems(array)
          }else{
            setDropDownItems(items)
          }
    },[search])
    
    const handleSearch = (value) => {
        setSearch(value)
    }
    
    return <Dropdown>
        <div className='dropdown-btn-container'>
        <Dropdown.Toggle
            bsPrefix={toggleClass ? toggleClass : 'dropdown-toggle'}
            id='dropdown-basic'
            // disabled={props.row.original.status === 'completed'}
        >
            {/* {selectedEmployee(props.row.original.employee_id)} */}
            <Input
                className={inputClass ? `input-field ${inputClass}` : 'input-field'}
                type={'text'}
                onChangeHandle={(e) => handleSearch(e.target.value)}
                placeholder={placeholder}
                value={search}
            />
        </Dropdown.Toggle>
        </div>
        <Dropdown.Menu
            bsPrefix={menuClass ? menuClass : 'dropdown-menu'}
        >
        {dropDownItems.map((item) => (
            <Dropdown.Item
            key={item.id}
            onClick={() =>
                onChangeHandle(item.id)
            }
            >
            {item.name}
            </Dropdown.Item>
        ))}
        </Dropdown.Menu>
    </Dropdown>
}

export default DropDown