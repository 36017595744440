import Select from "react-select";
import CaretDownFillSvg from "../../svgs/caretDownFill";
import "./DropDown.scss";
// custom dropdown indicator component

const CustomDropdownIndicator = ({ innerRef, innerProps }) => {
  return (
    <div ref={innerRef} {...innerProps}>
      <div className="drop_down_indicator">
        <CaretDownFillSvg />
      </div>
    </div>
  );
};

const DropDown = ({
  options,
  selectedOptions,
  isMultiSelect = false,
  isSearchable = false,
  isClearable = false,
  label = "label",
  value = "value",
  placeHolder = "Search...",
  noOptionsMessage = "No Options",
  onChange,
  onInputChange = () => {},
  className = ''
}) => {
const style = {
  control: (base, state) => ({
    ...base,
    border: '1px solid #C5C5C5',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #C5C5C5',
    },
  }),
  option: (base, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...base,
      backgroundColor: isFocused ? '#C1E6B7' : 'white',
      backgroundColor: isSelected ? '#C1E6B7' : 'white',
      '&:hover': {
        backgroundColor: '#C1E6B7',
      },
    };
  },
};
  return (
    <div className={`searchable_drop_down_container ${className}`}>
      <Select
        styles={style}
        options={options}
        value={selectedOptions}
        isMulti={isMultiSelect}
        isSearchable={isSearchable}
        isClearable={isClearable}
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionLabel={(option) => option[label] ?? option['label']}
        getOptionValue={(option) => option[value] ?? option['value']}
        placeholder={placeHolder}
        noOptionsMessage={() => noOptionsMessage}
        components={{
          DropdownIndicator: CustomDropdownIndicator,
          IndicatorSeparator: null,
        }}
        classNamePrefix={className}
        // please un-comment below line to style the drop down options
        // menuIsOpen={true}
      />
    </div>
  );
};

export default DropDown;
