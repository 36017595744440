import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../../actions';

const NavLink = ({ activeLink, dispatchMethod, onLangChange, classNames, lang, setActiveLink = () => {}, text, url, svg, children, type }) => {
  const dispatch = useDispatch();
  const helper = useSelector((state) => state.helper);

  useEffect(() => {}, [helper]);

  return (
    <div>
      {lang === true ? (
        <div>
          <Link to='#' className={classNames ? `${classNames}` : ''} onClick={() => onLangChange(url)}>
            {text}
          </Link>
          {children}
        </div>
      ) : (
        <div className='d-flex align-items-center'>
          {type === 'logout' ? (
            <>
              {helper.unSavedChanges ? (
                <Link className={classNames ? `${classNames} ${activeLink === url ? 'active' : ''}` : ''} to={url}>
                  <img src={svg} className='sidebar-icon' alt='' />
                  {text}
                </Link>
              ) : (
                <Link className={classNames ? `${classNames} ${activeLink === url ? 'active' : ''}` : ''} to={'#'} onClick={() => dispatch(logout())}>
                  <img src={svg} className='sidebar-icon' alt='' />
                  {text}
                </Link>
              )}
            </>
          ) : (
            <Link className={classNames ? `${classNames} ${activeLink === url ? 'active' : ''}` : ''} to={url} onClick={() => setActiveLink(url)}>
              <img src={svg} className='sidebar-icon' alt='' />
              {text}
            </Link>
          )}
          {children}
        </div>
      )}
    </div>
  );
};

export default NavLink;
