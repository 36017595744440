import React from 'react'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'

const AdminRoute = ({component: Component, ...rest}) => {
    const isAdmin = useSelector(state => state.login.isAdmin)
    return (
        <Route
            {...rest}
            component={(props) => (
                isAdmin === '1' || 1    
                    ? (
                        <Component {...props}/>
                    )
                        
                    : (
                        <Component {...props}/>  
                    ) 
            )}
        />
    )
}
export default AdminRoute