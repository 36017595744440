import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useConstants } from './useConstants';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { createTask, fetchTasks } from '../../actions/index';
import { useDispatch, useSelector } from 'react-redux';
import styles from './TaskForm.module.scss';
import { useHistory } from 'react-router-dom';
import PreLoader from '../shared/PreLoader';
import { getClients } from '../../services/task';
import { debounce } from 'lodash';

const TaskForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('common');
  const { taskDetails, subscribers, urgency, validateForm, formHasError, validateEmail, validateDate, validatePhone } = useConstants();
  const [formDetails, setFormDetails] = useState({ ...taskDetails });
  const [hasError, setHasError] = useState(formHasError);
  const [isEmailValid, setIsEmailValid] = useState('');
  const [isDateValid, setIsDateValid] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { isLoading } = useSelector((state) => state.response);
  const helper = useSelector((state) => state.helper);

  const onSubmit = (e) => {
    e.preventDefault();
    const { errors, isValid } = validateForm(formDetails);
    setHasError(errors);
    if (isValid) {
      const emailValidated = validateEmail(formDetails['4']);
      const dateValidated = validateDate(formDetails['11']);
      const phoneValidated = validatePhone(formDetails['3']);
      setIsEmailValid(emailValidated);
      setIsDateValid(dateValidated);
      setIsPhoneValid(phoneValidated);
      if (emailValidated && dateValidated && phoneValidated) {
        dispatch(createTask(formDetails));
        dispatch(fetchTasks());
      }
    }
  };

  const fetchSuggesstions = async (name) => {
    try {
      if (name) {
        setSearchLoading(true);
        const { data } = await getClients({ name });
        setSearchResults(data);
        setSearchLoading(false);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      setSearchResults([]);
    }
  };

  const debounceChanged = useCallback(
    debounce((newValues) => fetchSuggesstions(newValues), 500),
    []
  );

  const onNameChange = (value) => {
    setHasError({ ...hasError, 1.3: false });
    setFormDetails({ ...formDetails, 1.3: value });
    debounceChanged(value);
  };

  const preFillForm = (formData) => {
    const { name, email, phone, address, business_id, client_type, company_name, einvoicing_address, einvoicing_operator_id, postal, zipcode } = formData;
    setFormDetails({
      ...formDetails,
      1.3: name ?? '',
      3: phone ?? '',
      4: email ?? '',
      2.1: address ?? '',
      2.3: postal ?? '',
      2.5: zipcode ?? '',
      13: client_type === '0' ? subscribers[1].value : subscribers[0].value,
      14: company_name ?? '',
      15: business_id ?? '',
      16: einvoicing_address ?? '',
      17: einvoicing_operator_id ?? '',
    });
    setSearchResults([]);
  };

  useEffect(() => {
    if (helper.newTask) {
      setFormDetails(taskDetails);
      history.push('/tasks');
    }
  }, [isLoading, helper.newTask]);

  useEffect(() => {
    const cname = document.getElementById('customer_name');
    if (cname) {
      cname.addEventListener('focusout', () => setTimeout(() => setSearchResults([]), 200));
      cname.addEventListener('keydown', (event) => event.key === 'Escape' && setSearchResults([]));
    }
    return () => {
      cname.removeEventListener('focusout', () => {});
      cname.removeEventListener('keydown', () => {});
    };
  }, []);

  return (
    <div className={`${styles.formMainContainer}`}>
      <div className={`${styles.formTitleContainer}`}>
        <h1 className={`${styles.formTitle}`}>{t('task_form.heading')}</h1>
      </div>
      <form className={`${styles.formContainer}`} onSubmit={onSubmit}>
        <div className={`${styles.inputContainer}`}>
          <input id='customer_name' autoComplete='off' className={`${styles.inputField} ${hasError['1.3'] ? 'is-invalid' : ''}`} value={formDetails['1.3']} type='text' placeholder={t('task_form.customer_name')} onChange={(e) => onNameChange(e.target.value)} />
          <div className={`${styles.inputLoader}`}>{searchLoading ? <PreLoader /> : null}</div>
          <p className='invalid-feedback'>{t('task_form.required')}</p>
          {searchResults.length > 0 ? (
            <div className='automcompleteitems'>
              {searchResults.map((result) => (
                <div className='automcompleteitem' key={result.id} onClick={() => preFillForm(result)}>
                  {result.name}
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div className={`${styles.flexContainer}`}>
          <div className={`${styles.inputContainer}`}>
            <input
              type='text'
              className={`${styles.inputField} ${hasError['3'] || isPhoneValid === false ? 'is-invalid' : ''}`}
              placeholder={t('task_form.phone')}
              value={formDetails['3']}
              onChange={(e) => {
                setIsPhoneValid(true);
                setHasError({ ...hasError, 3: false });
                setFormDetails({ ...formDetails, 3: e.target.value });
              }}
            />
            <p className='invalid-feedback'>{!formDetails['3'] ? t('task_form.required') : t('task_form.invalid_phone_msg')}</p>
          </div>

          <div className={`${styles.inputContainer}`}>
            <input
              className={`${styles.inputField} ${hasError['4'] || isEmailValid === false ? 'is-invalid' : ''}`}
              type='text'
              placeholder={t('task_form.email')}
              value={formDetails['4']}
              onChange={(e) => {
                setHasError({ ...hasError, 4: false });
                setFormDetails({ ...formDetails, 4: e.target.value });
              }}
            />
            <p className='invalid-feedback'>{formDetails['4'] === '' ? t('task_form.required') : t('task_form.invalid_email_msg')}</p>
          </div>
        </div>

        <div>
          <div className={`${styles.inputContainer}`}>
            <input
              className={`${styles.inputField} ${hasError['2.1'] ? 'is-invalid' : ''}`}
              type='text'
              placeholder={t('task_form.local_address')}
              value={formDetails['2.1']}
              onChange={(e) => {
                setHasError({ ...hasError, 2.1: false });
                setFormDetails({ ...formDetails, 2.1: e.target.value });
              }}
            />
          </div>
          <div className={`${styles.flexContainer}`}>
            <div className={`${styles.inputContainer}`}>
              <input
                className={`${styles.inputField} ${hasError['2.3'] ? 'is-invalid' : ''}`}
                type='text'
                placeholder={t('task_form.postal_district')}
                value={formDetails['2.3']}
                onChange={(e) => {
                  setHasError({ ...hasError, 2.3: false });
                  setFormDetails({ ...formDetails, 2.3: e.target.value });
                }}
              />
            </div>
            <div className={`${styles.inputContainer}`}>
              <input
                className={`${styles.inputField} ${hasError['2.5'] ? 'is-invalid' : ''}`}
                type='text'
                placeholder={t('task_form.zip_code')}
                value={formDetails['2.5']}
                onChange={(e) => {
                  setHasError({ ...hasError, 2.5: false });
                  setFormDetails({ ...formDetails, 2.5: e.target.value });
                }}
              />
              <p className='invalid-feedback'>{t('task_form.required')}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.inputContainer} ${styles.selectContainer}`}>
          <select className={`${styles.inputField}`} name='subscriber' value={formDetails['13']} onChange={(e) => setFormDetails({ ...formDetails, 13: e.target.value })}>
            {subscribers.map((op) => (
              <option key={op.id} value={op.value}>
                {op.label}
              </option>
            ))}
          </select>
        </div>

        {formDetails['13'] === t('task_form.company') && (
          <div className='company_details'>
            <div className={`${styles.inputContainer}`}>
              <label className={`${styles.inputLabel}`}> {t('task_form.name_of_company_msg')}</label>
              <input
                className={`${styles.inputField}`}
                type='text'
                value={formDetails['14']}
                onChange={(e) =>
                  setFormDetails({
                    ...formDetails,
                    14: e.target.value,
                  })
                }
              />
            </div>
            <div className={`${styles.flexContainer}`}>
              <div className={`${styles.inputContainer}`}>
                <label className={`${styles.inputLabel}`}> {t('task_form.y_id')}</label>
                <input
                  className={`${styles.inputField}`}
                  type='text'
                  value={formDetails['15']}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      15: e.target.value,
                    })
                  }
                />
              </div>
              <div className={`${styles.inputContainer}`}>
                <label className={`${styles.inputLabel}`}> {t('task_form.online_billing')}</label>
                <input
                  className={`${styles.inputField}`}
                  type='text'
                  value={formDetails['16']}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      16: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className={`${styles.inputContainer}`}>
              <label className={`${styles.inputLabel}`}> {t('task_form.broker_id')}</label>
              <input
                className={`${styles.inputField}`}
                type='text'
                value={formDetails['17']}
                onChange={(e) =>
                  setFormDetails({
                    ...formDetails,
                    17: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}

        <div className={`${styles.flexContainer}`}>
          <div className={`${styles.inputContainer}`}>
            <input
              className={`${styles.inputField} ${hasError['5'] ? 'is-invalid' : ''}`}
              type='text'
              placeholder={t('task_form.make_model_of_heat_pump')}
              value={formDetails['5']}
              onChange={(e) => {
                setHasError({ ...hasError, 5: false });
                setFormDetails({ ...formDetails, 5: e.target.value });
              }}
            />

            <p className='invalid-feedback'>{t('task_form.required')}</p>
          </div>

          <div className={`${styles.inputContainer}`}>
            <input
              className={`${styles.inputField} ${hasError['6'] ? 'is-invalid' : ''}`}
              type='text'
              placeholder={t('task_form.serial_number')}
              value={formDetails['6']}
              onChange={(e) => {
                setHasError({ ...hasError, 6: false });
                setFormDetails({ ...formDetails, 6: e.target.value });
              }}
            />
            <p className='invalid-feedback'>{t('task_form.required')}</p>
          </div>
        </div>

        <div className={`${styles.inputContainer}`}>
          <input
            className={`${styles.inputField} ${hasError['11'] || isDateValid === false ? 'is-invalid' : ''}`}
            type='text'
            placeholder={t('task_form.purchase_date')}
            value={formDetails['11']}
            onChange={(e) => {
              setHasError({ ...hasError, 11: false });
              setFormDetails({ ...formDetails, 11: e.target.value });
            }}
          />
          <p className='invalid-feedback'>
            {formDetails['11'] === ''
              ? t('task_form.required')
              : t('task_form.invalid_date_msg', {
                  date: moment(new Date()).format('DD.MM.YYYY'),
                })}
          </p>
        </div>

        <div className={`${styles.inputContainer}`}>
          <textarea
            className={`${styles.inputField} ${styles.textField}`}
            placeholder={t('task_form.description_msg')}
            value={formDetails['7']}
            onChange={(e) =>
              setFormDetails({
                ...formDetails,
                7: e.target.value,
              })
            }
          />
        </div>

        <div className={`${styles.inputContainer}`}>
          <label className={`${styles.inputLabel} `}>{t('task_form.service_urgency')}</label>

          <div className={`${styles.sliderContainer} `}>
            {urgency.map((op, index) => (
              <div key={index}>
                <Form.Check type='switch' key={op.id} id={op.id} name='urgency' checked={op.value === formDetails['9']} onChange={() => setFormDetails({ ...formDetails, 9: op.value })} label={op.label} />
              </div>
            ))}
          </div>
        </div>

        <div className={`d-flex ${styles.btnContainer} `}>{isLoading ? <PreLoader /> : <button type='submit'>{t('task_form.order_maintenance')}</button>}</div>
      </form>
    </div>
  );
};

export { TaskForm };
