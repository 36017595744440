import moment from 'moment';
const tasks = (state = { tasks: [], updatedTaskId: null }, action) => {
  switch (action.type) {
    case 'TASKS': {
      return {
        ...state,
        tasks: action.payload.map((task, index) => {
          const singleTaskProduct = task.products.map((product) => {
            return {
              ...product,
              updated: false,
            };
          });
          return {
            ...task,
            products: singleTaskProduct,
          };
        }),
      };
    }
    case 'INVOICE_SENT': {
      return {
        ...state,
        tasks: state.tasks.map((task, i) =>
          task.id === action.payload.id
            ? {
                ...task,
                status: action.payload.status,
              }
            : task
        ),
      };
    }

    case 'TASK_UPDATE': {
      return {
        ...state,
        tasks: state.tasks.map((task, i) =>
          task.id === action.payload.data.id
            ? {
                ...task,
                ...action.payload.data,
              }
            : task
        ),
      };
    }

    case 'TASKID_BY_TIME': {
      return {
        ...state,
        updatedTaskId: action.payload.taskId,
      };
    }

    case 'DELETE_TASK': {
      return {
        ...state,
        tasks: state.tasks.filter(
          (task, i) => task.id !== action.payload.taskId
        ),
      };
    }

    case 'DELETE_KMSERVICEDRIVEN_TASK': {
      return {
        ...state,
        tasks: state.tasks.map((task, i) => {
          if (task.id === action.payload.taskId) {
            const updatedKmdriven = task.kilometer_service_driven.filter(
              (kmServiceDriven, i) => kmServiceDriven.id !== action.payload.id
            );
            return {
              ...task,
              kilometer_service_driven: updatedKmdriven,
            };
          } else {
            return {
              ...task,
            };
          }
        }),
      };
    }

    case 'TIME_UPDATED': {
      return {
        ...state,
        tasks: state.tasks.map((task, i) => {
          let timings = [];
          if (task.id === action.payloads.taskId) {
            if (task.timings.length > 0) {
              timings = task.timings.map((timing) => {
                if (timing.employee_id === action.payloads.selectedEmployee) {
                  return {
                    ...timing,
                    started_at: moment(action.payloads.started_at)
                      .utc()
                      .format('YYYY-MM-DD HH:mm:ss'),
                    stopped_at: moment(action.payloads.completed_at)
                      .utc()
                      .format('YYYY-MM-DD HH:mm:ss'),
                  };
                } else {
                  return timing;
                }
              });
            } else {
              timings.push(action.payloads.data);
            }
            return {
              ...task,
              timings,
            };
          } else {
            return task;
          }
        }),
      };
    }
    case 'KILOMETER_HOUR_UPDATED': {
      return {
        ...state,
        tasks: state.tasks.map((task, i) =>
          task.id === action.payloads.taskId
            ? {
                ...task,
                kilometer_service_driven: action.payloads.data,
              }
            : task
        ),
      };
    }

    case 'REQUEST_ATTENTION': {
      return {
        ...state,
        tasks: state.tasks.map((task, i) =>
          task.id === action.payloads.taskId
            ? {
                ...task,
                require_attention: action.payloads.request_attention,
              }
            : task
        ),
      };
    }

    // case 'TASK_KILOMETER_HOURS_PRICE_UPDATED' : {
    //     return {
    //         ...state,
    //         tasks: state.tasks.map(
    //             (task, i) => {
    //                 const res = action.payload;
    //                 console.log(res, 'reducer reducer reducer');

    //                 return (
    //                     task.id === action.payload.taskId  ? {
    //                         ...task,
    //                         price_kilometers_driven: action.payload.kilometerPricePerUnit,
    //                         price_timing: action.payload.hoursPricePerUnit,
    //                         driving_hours_price: action.payload.drivingHoursPricePerUnit,
    //                     }
    //                     : task
    //                     )
    //             }
    //         )
    //     }
    // }

    case 'TASK_KILOMETER_HOURS_PRICE_UPDATED': {
      return {
        ...state,
        tasks: state.tasks.map((task, i) => {
          let res = action.payload.res;
          let rowId = action.payload.rowId;
          if (task.id === res.task_id) {
            let updatedArr = task.kilometer_service_driven.map((row, index) => {
              if (
                res.kilometer_service_driven_price
                  .task_kilometer_service_driving_hours_id === rowId &&
                row.id ===
                  res.kilometer_service_driven_price
                    .task_kilometer_service_driving_hours_id
              ) {
                return {
                  ...row,
                  kilometer_service_driven_price: {
                    ...res.kilometer_service_driven_price,
                  },
                };
              } else {
                return {
                  ...row,
                };
              }
            });

            return {
              ...task,
              kilometer_service_driven: updatedArr,
            };
          } else {
            return {
              ...task,
            };
          }
          // return (
          //     task.id === action.payload.task_id  ? {
          //         ...task,
          //         price_kilometers_driven: action.payload.kilometerPricePerUnit,
          //         price_timing: action.payload.hoursPricePerUnit,
          //         driving_hours_price: action.payload.drivingHoursPricePerUnit,
          //     }
          //     : task
          //     )
        }),
      };
    }

    case 'TASK_COMPLETED': {
      return {
        ...state,
        tasks: state.tasks.map((task, i) =>
          task.id === action.payloads.taskId
            ? {
                ...task,
                status: 'completed',
              }
            : task
        ),
      };
    }
    case 'TASK_PRODUCT_ADDED': {
      const product = action.payload.response.data;
      product.updated = false;
      product.pivot = {
        id: product.task_product_id,
        comment: action.payload.comment,
        quantity: parseInt(action.payload.quantity),
      };
      return {
        ...state,
        tasks: state.tasks.map((task, i) => {
          if (task.id === action.payload.taskId) {
            const updatedProducts = task.products.map((productData, index) => {
              //commented the code for updating product
              if (
                productData.id === product.id &&
                productData.pivot.id === product.task_product_id
              ) {
                //which is false when adding new product
                return {
                  ...productData,
                  pivot: {
                    ...product.pivot,
                  },
                };
              } else {
                return {
                  ...productData,
                };
              }
            });
            //removed filter because it was used to update same item by adding it to the products arry
            const filterNewProductIfExist = updatedProducts.filter(
              (productData) => productData.pivot.id === product.task_product_id
            );
            if (filterNewProductIfExist.length === 0) {
              updatedProducts.push(product);
            }
            return {
              ...task,
              products: updatedProducts,
            };
          } else {
            return {
              ...task,
            };
          }
        }),
      };
    }
    case 'TASK_PRODUCT_REMOVED': {
      return {
        ...state,
        tasks: state.tasks.map((task, i) => {
          if (task.id === action.payload.taskId) {
            const filteredProducts = task.products.filter((product) => {
              return (
                product.pivot.id !== parseInt(action.payload.taskProductId)
              );
            });
            return {
              ...task,
              products: filteredProducts,
            };
          } else {
            return task;
          }
        }),
      };
    }
    case 'TASK_PRODUCT_PRICE_UPDATE': {
      return {
        ...state,
        tasks: state.tasks.map((task, i) => {
          if (task.id === action.payload.taskId) {
            const products = task.products.map((product) => {
              if (product.pivot.id === action.payload.task_product_id) {
                return {
                  ...product,
                  pivot: {
                    ...product.pivot,
                    price: action.payload.price,
                  },
                };
              } else {
                return product;
              }
            });
            return {
              ...task,
              products,
            };
          } else {
            return task;
          }
        }),
      };
    }

    case 'TASK_CREATE_SUCCESS': {
      return {
        ...state,
        newTask: action.payload,
      };
    }

    default:
      return state;
  }
};

export default tasks;
