const registerReducers = (state = {isRegistered: false, errors: null}, {type, payloads}) => {
    switch(type){
        case 'REGISTER' : {
            if((localStorage.getItem("registered") === null || localStorage.getItem('registered') === 'false') ){
                localStorage.setItem('registered', true);
           }
            return {
                ...state,
                isRegistered: true
            }
        }
        case 'IS_REGISTERED' : {
            if((localStorage.getItem("registered") === null || localStorage.getItem('registered') === 'false') ){
                localStorage.setItem('registered', true);
           }
           return {
                ...state,
                isRegistered: localStorage.getItem('registered') === 'true' ? localStorage.getItem('registered') : "false",
            }    
        }
        default : 
            return state
    }
}

export default registerReducers