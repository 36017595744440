import './App.scss';
import Router from '../src/router'
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import Toastr from './components/Toastr';

const App = () => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch({type: 'IS_LOGGED_IN'})
    dispatch({type: 'IS_REGISTERED'})
  })
  return (
    <>
      <Toastr />
      <Router/>
    </>
  );
}

export default App;
