import { toast } from 'react-toastify';

const responseReducer = (
  state = {
    successMessage: '',
    type: '',
    productId: '',
    hoursPriceSuccess: false,
    productPriceSuccess: false,
    profileUpdate: false,
    successResponse: false,
    isLoading: false,
    resetPassword: false,
    isApiFetched: false,
    modalClose: false,
    notificationId: '',
    notificationReadSuccess: false,
    requestAttentionSuccess: false,
    taskId: '',
    taskProductId: '',
    assignTaskSuccess: false,
    isTaskVisitAndProductsActionInProgress: false,
    errors: [],
  },
  action
) => {
  switch (action.type) {
    case 'SUCCESS': {
      toast.success(action.message);
      return {
        ...state,
        modalClose: false,
        successResponse: true,
      };
    }

    case 'PASSWORD_RESET_SUCCESS': {
      toast.success(action.message);
      return {
        ...state,
        resetPassword: true,
      };
    }

    case 'PRODUCT_SUCCESS': {
      toast.success(action.message);
      return {
        ...state,
        modalClose: true,
      };
    }
    case 'EMPLOYEE_SUCCESS': {
      toast.success(action.message);
      return {
        ...state,
        modalClose: true,
      };
    }
    case 'ERROR': {
      let validationErrors = [];
      if (action.status === 422) {
        if (action.message.hasOwnProperty('errors')) {
          Object.keys(action.message.errors).map((error, index) => {
            toast.error(action.message.errors[error][0]);
            return validationErrors.push({ [error]: action.message.errors[error][0] });
          });
        } else {
          toast.error(action.message);
        }
      } else if (action.status === 401) {
        toast.error('Istunto vanhentunut');
      } else {
        toast.error(action.message);
      }
      return {
        ...state,
        errors: validationErrors,
        modalClose: false,
      };
    }
    case 'RESET_VALIDATION_ERROR': {
      return {
        ...state,
        errors: [],
        modalClose: false,
        successResponse: false,
      };
    }
    case 'API_IS_FETCHING': {
      return {
        ...state,
        isApiFetched: true,
        isLoading: true,
      };
    }
    case 'API_FETCHED': {
      return {
        ...state,
        isApiFetched: true,
        isLoading: false,
      };
    }

    case 'HOURS_PRICE_API_FETCHING': {
      return {
        ...state,
        hoursPriceSuccess: false,
        type: action.payload.type,
      };
    }

    case 'HOURS_PRICE_API_FETCHED': {
      return {
        ...state,
        hoursPriceSuccess: true,
        type: action.payload.type,
      };
    }

    case 'PRODUCT_PRICE_API_FETCHING': {
      return {
        ...state,
        productPriceSuccess: false,
        productId: action.payload.id,
      };
    }

    case 'PRODUCT_PRICE_API_FETCHED': {
      return {
        ...state,
        productPriceSuccess: true,
        productId: action.payload.id,
        taskProductId: action.payload.taskProductId,
      };
    }

    case 'PROFILE_UPDATE_API_FETCHING': {
      return {
        ...state,
        type: action.payload.type,
        profileUpdate: false,
      };
    }

    case 'PROFILE_UPDATE_API_FETCHED': {
      return {
        ...state,
        type: action.payload.type,
        profileUpdate: true,
      };
    }

    case 'SINGLE_NOTIFICATION_READ_FETCHING': {
      return {
        ...state,
        notificationId: action.payloads.id,
        notificationReadSuccess: false,
      };
    }

    case 'SINGLE_NOTIFICATION_READ_FETCHED': {
      return {
        ...state,
        notificationId: action.payloads.id,
        notificationReadSuccess: true,
      };
    }

    case 'ASSIGN_TASK_FETCHING': {
      return {
        ...state,
        taskId: action.payloads.id,
        assignTaskSuccess: false,
      };
    }

    case 'ASSIGN_TASK_FETCHED': {
      return {
        ...state,
        taskId: action.payloads.id,
        assignTaskSuccess: true,
      };
    }

    case 'REQUEST_ATTENTION_API_FETCHING': {
      return {
        ...state,
        requestAttentionSuccess: true,
      };
    }

    case 'REQUEST_ATTENTION_API_FETCHED': {
      return {
        ...state,
        requestAttentionSuccess: false,
      };
    }

    case 'TASK_VISIT_AND_PRODUCTS_ACTION_INPROGRESS': {
      return {
        ...state,
        isTaskVisitAndProductsActionInProgress: true,
      };
    }

    case 'TASK_VISIT_AND_PRODUCTS_ACTION_COMPLETED': {
      return {
        ...state,
        isTaskVisitAndProductsActionInProgress: false,
      };
    }

    case 'RESET_SUCCESS_RESPONSE': {
      return {
        ...state,
        successResponse: false,
      };
    }

    default:
      return state;
  }
};

export default responseReducer;
