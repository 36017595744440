import React from 'react'

const Select  = ({ className, options, mainClass, errors, label, selected, type, onChangeHandle, disabled, name, placeholder, value, id }) => {
    return (
        <div>
            <div className={mainClass ? `${mainClass} d-flex align-items-center` : 'd-flex align-items-center'}>
                <React.Fragment>
                    {
                    label ? <label className='input-label' htmlFor={id}>
                        {label}
                    </label> : ""
                    }
                    <select name={name} defaultValue={selected} onChange={onChangeHandle} className={className ? className : 'input-field'} >
                        {
                            options.map((option) => (
                                <option value={option.value}>{option.name}</option>
                            ))
                        }
                    </select>                    
                </React.Fragment>
            </div>
            {errors ? <div className='error-message mb-4'>
            {' '}
            {Array.isArray(errors) && errors.length > 0
                ? errors.map((error) => error[name])
                : ''}
            </div> : ""}
      </div>
    )
}

export default Select