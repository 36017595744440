import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const NotFound = () => {
    const {t} = useTranslation('common')
    return (
        <>
        <h1>404 - {t('not_found')}!</h1>
        <Link to="/tasks">
            {t('go_to_task')}
        </Link>
        </>
    )
}

export default NotFound