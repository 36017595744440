import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { savedChanges } from '../../actions/helper';
import { useDispatch } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { logout } from '../../actions';

export function PromptOnRouteChangeInUnsavedInfo(props) {
  const { when, onConfirm, onCancel, title, confirmText, cancelText, message } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('common');
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const langRef = useRef();

  useEffect(() => {
    langRef.current = i18n.language;
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        if (langRef.current === i18n.language) setShowPrompt(true);
        return false;
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when, i18n.language]);

  const onConfirmClick = useCallback(async () => {
    if (onConfirm) {
      const canRoute = await Promise.resolve(onConfirm());
      if (canRoute) {
        history.block((prompt) => {
          if (prompt.pathname === '/login') dispatch(logout());
        });
        dispatch(savedChanges());
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onConfirm]);

  const onCancelClick = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <SweetAlert
      showCancel
      danger
      title={title}
      show={showPrompt}
      onConfirm={onConfirmClick}
      confirmBtnText={confirmText}
      onCancel={onCancelClick}
      cancelBtnText={cancelText}
      confirmBtnStyle={{ background: '#71BF5D', color: 'white' }}
      cancelBtnStyle={{ background: '#D43B3B', color: 'white', textDecoration: 'none' }}
    >
      {message}
    </SweetAlert>
  ) : null;
}
