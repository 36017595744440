import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { removeAccount, updateAccount } from '../../actions';
import FormSubmitButton from '../shared/FormSubmitButton';
import Input from '../shared/Input'
import PreLoader from '../shared/PreLoader';
import './setting.scss';

const Setting = () => {
    const loginDetails = useSelector(state => state.login)
    
    const serverErrors = useSelector(state => state.response.errors)
    const type = useSelector(state => state.response.type)
    const profileUpdate = useSelector(state => state.response.profileUpdate)

    
    const dispatch = useDispatch();
    const [errors, setErrors] = useState([]);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [city, setCity] = useState("")
    const [vat, setVAT] = useState("")
    const [company, setCompany] = useState("")
    const [zip, setZip] = useState("")
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [area, setArea] = useState("")
    const [imageURL, setImageUrl] = useState("")
    const [image, setImage] = useState("")
    const [alert, setAlert] = useState(false);
    const{t} = useTranslation('common')

    useEffect(() => {
      if(loginDetails.id !== null){
        setName(loginDetails.username)
        setEmail(loginDetails.email)
        setAddress(loginDetails.address)
        setPhone(loginDetails.phone)
        setCity(loginDetails.city)
        setZip(loginDetails.zip)
        setCompany(loginDetails.company)
        setVAT(loginDetails.vat)
        setImageUrl(loginDetails.avatar)
        setArea(loginDetails.area)
      }
    },[loginDetails])
    useEffect(() => {  
        if(serverErrors.length > 0){
            let errorsArray = []
            Object.keys(serverErrors)
            .map((key) => { 
                return errorsArray[key] = serverErrors[key]
            });
            setErrors(errorsArray)
        }
    },[serverErrors])

    const handleDelete = () => {
        const getAlert = () => (
          <SweetAlert 
            danger 
            closeOnClickOutside={true}
            onCancel={() => setAlert(null)}
            title={`${t('settings.delete.heading')}`} 
            onConfirm={() => {
                dispatch(removeAccount(
                    {
                        id: loginDetails.id, 
                        type: loginDetails.isAdmin === 0 || loginDetails.isAdmin === '0' ? 'employee' : 'admin'
                    }))
              setAlert(null)
            }}
            confirmBtnStyle={{background: "#71BF5D"}}
          >
            {t('settings.delete.text')}
          </SweetAlert>
        );
        setAlert(getAlert())
      }

    const handleChange = e => {
        const value = e.target.value;
        const name = e.target.name;
        if(name === 'name'){
            setName(value)
        }
        if(name === 'address'){
            setAddress(value)
        }
        if(name === 'phone'){
            setPhone(value)
        }
        if(name === 'city'){
          setCity(value)
        }
        if(name === 'zip'){
          setZip(value)
        }
        if(name === 'vat'){
          setVAT(value)
        }
        if(name === 'company'){
          setCompany(value)
        }

        if(name === 'area'){
          setArea(value)
        }
      
        const updatedErrors = errors.map((error) => {
            return {
                ...error,
                [name]: ""
            }
        }) 
        setErrors(updatedErrors)
    }

    const handleChangeImage = event => {
        setImageUrl(URL.createObjectURL(event.target.files[0]));
        setImage(event.target.files[0]);
        const updatedErrors = errors.map((error) => {
            return {
                ...error,
                image: ""
            }
        }) 
        setErrors(updatedErrors)
    };

    const formValidation = () => {
        let formIsValid = true;
        let errors = [];

        if (name.length === 0) {
            formIsValid = false;
            errors.push({'name': t('settings.error.name')})
        }

        if (address.length === 0) {
            formIsValid = false;
            errors.push({'address': t('settings.error.address')})
        }

        if (phone.length === 0) {
            formIsValid = false;
            errors.push({'phone': t('settings.error.phone')})
        }
        
        if (city.length === 0) {
            formIsValid = false;
            errors.push({'city': t('settings.error.city')})
        }
        if (zip.length === 0) {
            formIsValid = false;
            errors.push({'zip': t('settings.error.zip')})
        }
        if (company.length === 0) {
            formIsValid = false;
            errors.push({'company': t('settings.error.company')})
        }
        if (vat.length === 0) {
            formIsValid = false;
            errors.push({'vat': t('settings.error.vat')})
        }
        if (area.length === 0) {
          formIsValid = false;
          errors.push({'area': t('settings.error.area')})
        }

        if(!formIsValid){
            setErrors(errors)
        }

        return formIsValid;
    }

    const handleSubmit = event => {
        event.preventDefault();
        const validateForm = formValidation();
        if (!validateForm) {
            return false;
        }
        dispatch(updateAccount({
          id: loginDetails.id, 
          name, 
          phone, 
          city,
          zip,
          company,
          vat,
          address, 
          area, 
          image, 
          type: loginDetails.isAdmin === 0 || loginDetails.isAdmin === '0' ? 'employee' : 'admin'}))
    }
    return (
      <div className='setting-content'>
        <h1>{t('settings.heading')}</h1>

        <div className='form-container'>
          <div className="d-flex align-items-center justify-content-between">
            <p className='title'>{t('settings.profile')}</p>
            {loginDetails.isAdmin === 0 || loginDetails.isAdmin === '0' ? (
              <p className='business-area'>{t('settings.business_area')}: {loginDetails.area}</p>
            ) : (
              ''
            )}
            <div className="loader-container">
              {
                type === 'profile' && profileUpdate ? "" 
                : type === 'profile' && profileUpdate === false ? <PreLoader /> : ""
              }
            </div>
          </div>
          <div className="profile-form">
            <form onSubmit={handleSubmit}>
              <div className='my-3 d-flex align-items-center justify-content-between'>
                <div className='img-column'>
                  <div className='uploaded-img-container'>
                    <img
                      src={imageURL}
                      alt='profile_pic'
                      className='uploaded-img'
                    />
                  </div>
                </div>
                <div className='input-column'>
                  <Input
                    className='input-field'
                    type={'file'}
                    name={'image'}
                    id={'image'}
                    placeholder={''}
                    errors={errors}
                    onChangeHandle={handleChangeImage}
                  />
                  <Input
                    className='input-field'
                    type={'text'}
                    name={'name'}
                    id={'name'}
                    placeholder={t('settings.full_name')}
                    value={name}
                    errors={errors}
                    onChangeHandle={handleChange}
                  />
                </div>
              </div>
              <Input
                className='input-field disabled'
                type={'email'}
                name={'email'}
                id={'email'}
                placeholder={t('settings.email')}
                disabled
                value={email}
              />
              <Input
                className='input-field'
                type={'text'}
                name={'phone'}
                id={'phone'}
                placeholder={t('settings.phone')}
                value={phone}
                errors={errors}
                onChangeHandle={handleChange}
              />
              <Input
                className='input-field'
                type={'text'}
                name={'company'}
                id={'company'}
                placeholder={t('settings.company')}
                value={company}
                errors={errors}
                onChangeHandle={handleChange}
              />
              <Input
                className='input-field'
                type={'text'}
                name={'vat'}
                id={'vat'}
                placeholder={t('settings.vat')}
                value={vat}
                errors={errors}
                onChangeHandle={handleChange}
              />
              <Input
                className='input-field'
                type={'text'}
                name={'address'}
                id={'address'}
                placeholder={t('settings.address')}
                value={address}
                errors={errors}
                onChangeHandle={handleChange}
              />
              <Input
                className='input-field'
                type={'text'}
                name={'zip'}
                id={'zip'}
                placeholder={t('settings.zip')}
                value={zip}
                errors={errors}
                onChangeHandle={handleChange}
              />
              <Input
                className='input-field'
                type={'text'}
                name={'city'}
                id={'city'}
                placeholder={t('settings.city')}
                value={city}
                errors={errors}
                onChangeHandle={handleChange}
              />
              {
                loginDetails.isAdmin === 0 || loginDetails.isAdmin === '0' ? "" : 
                <Input
                  className='input-field'
                  type={'text'}
                  name={'area'}
                  id={'area'}
                  placeholder={t('settings.area')}
                  value={area}
                  errors={errors}
                  onChangeHandle={handleChange}
                />
              }
              <div className="d-flex align-items-center justify-content-between">
                <a className="remove-account" onClick={handleDelete}>{t('settings.remove')}</a>
              <FormSubmitButton label={t('settings.save')} />
              </div>
            </form> 
          </div>
        </div>
        {alert}
      </div>
    );
}

export default Setting