import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const InvitedRoute = ({component: Component, ...rest}) => {
    let queryParams = null
    const location = rest.location
    let queryFound = true
    if(location.search.length === 0){
        queryFound = false
    }else if(location.search.length > 0){
        queryParams = new URLSearchParams(location.search);
        if(!queryParams.has('email')){
            queryFound = false
        }
        if(!queryParams.has('invitation_code')){
            queryFound = false
        }
    }
    return (
        <Route 
            {...rest}
            component={(props) => (
                queryFound ? (
                    <div>
                        <Component {...props}/>
                    </div>
                ):(
                    <Redirect to='/login'/>    
                )
            )}
        />
    )
}

export default InvitedRoute