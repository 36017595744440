import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchProduct, fetchUsers, fetchTasks } from '../../actions';
import CachedIcon from '@material-ui/icons/Cached';
import './MyTasks.scss';

import List from '../shared/Tasks/List';
import PreLoader from '../shared/PreLoader';
import { savedChanges } from '../../actions/helper';
import SweetAlert from 'react-bootstrap-sweetalert';

const MyTasks = (props) => {
  const employees = useSelector((state) => state.employees);
  const products = useSelector((state) => state.products);
  const userDetails = useSelector((state) => state.login);
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const helper = useSelector((state) => state.helper);

  const taskListRefresh = () => {
    if (helper.unSavedChanges) {
      const getAlert = () => (
        <SweetAlert
          danger
          showCancel
          closeOnClickOutside={true}
          onCancel={() => {
            setAlert(null);
          }}
          title={`${t('task.details.messages.delete_modal_title')}`}
          onConfirm={() => {
            dispatch(fetchTasks());
            dispatch(savedChanges());
            setAlert(null);
          }}
          confirmBtnText={t('button.confirm')}
          cancelBtnText={t('button.cancel')}
          confirmBtnStyle={{ background: '#71BF5D', color: 'white' }}
          cancelBtnStyle={{ background: '#D43B3B', color: 'white', textDecoration: 'none' }}
        >
          {t('task.details.messages.unsaved')}
        </SweetAlert>
      );
      setAlert(getAlert());
    } else {
      dispatch(fetchTasks());
    }
  };

  useEffect(() => {}, [helper.unSavedChanges]);

  useEffect(() => {
    if (employees.employeesList.length > 0 && products.productsList.length > 0) {
      setIsLoading(false);
    } else {
      dispatch(fetchUsers());
      dispatch(fetchProduct());
      setIsLoading(true);
    }
  }, [employees.employeesList, products.productsList]);

  return (
    <div className='task-main-container'>
      <div className='d-flex align-items-center headerContainer'>
        <h1 className='taskHeading'>{t('my_tasks.heading')}</h1>
        {alert}
        <div className='refreshBtnContainer'>
          <button className={'refreshBtn'} onClick={taskListRefresh}>
            <CachedIcon style={{ color: '#707070' }} />
          </button>
        </div>
      </div>
      <div className='task-list-container'>{isLoading || props.loader ? <PreLoader /> : <List type={'admin'} adminId={userDetails.id} employees={employees} products={products.productsList} />}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loader: state.response.isLoading,
});
export default connect(mapStateToProps)(MyTasks);
// export default MyTasks
