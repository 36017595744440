const loginReducer = (state = {isLoggedIn: localStorage.getItem('isloggedIn') === 'true' ? localStorage.getItem('isloggedIn') : "false", id: null, area: null,  username: null, email: null, address: null, isAdmin: 0, avatar: null}, action) => {
    switch(action.type) {
        case 'SET_USER_DETAIL_AFTER_LOGIN' :
           if((localStorage.getItem("username") === null || localStorage.getItem('isloggedIn') === 'false') ){
                localStorage.setItem('isloggedIn', true);
                localStorage.setItem('avatar', action.payloads.avatar);
           }
           if((localStorage.getItem("username") === null || localStorage.getItem('isAdmin') !== '1') ){
                localStorage.setItem('isAdmin', action.payloads.admin);
            }
            return {
                ...state,
                username: action.payloads.name,
                address: action.payloads.address,
                city: action.payloads.city,
                zip: action.payloads.zip,
                company: action.payloads.company,
                vat: action.payloads.vat,
                phone:  action.payloads.phone,
                email:  action.payloads.email,
                isLoggedIn: "true",
                isAdmin: action.payloads.admin,
                avatar: action.payloads.avatar,
                id: action.payloads.id,
                area: action.payloads.area,
           }; 
        case 'IS_LOGGED_IN': 
            return {
                ...state,
                isLoggedIn: localStorage.getItem('isloggedIn') === 'true' ? localStorage.getItem('isloggedIn') : "false",
                isAdmin: localStorage.getItem('isAdmin') === '1' ? localStorage.getItem('isAdmin') : "0"
            }     
        default :
            return state;
    }
}

export default loginReducer
