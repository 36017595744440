/** @format */

import React, { useEffect, useLayoutEffect, useState } from 'react';
import '../App.scss';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../actions';
import './nav.scss';
import NavLink from '../containers/shared/NavLink';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

const Nav = (props) => {
  const [activeLink, setActiveLink] = useState('');
  const [nav, setNav] = useState([]);
  const { t, i18n } = useTranslation('common');
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language === 'en' ? 'English' : 'Finnish');

  const admin = {
    nav: [
      {
        url: '/summary',
        src: '/images/summary.svg',
        dispatch: null,
        order: 2,
        text: 'nav.summary',
      },
      {
        url: '/employees',
        dispatch: null,
        src: '/images/employee.svg',
        order: 4,
        text: 'nav.employees',
      },
      {
        url: '/announcement',
        dispatch: null,
        src: '/images/notification.svg',
        order: 5,
        text: 'nav.announcement',
      },
      {
        url: '/my-tasks',
        dispatch: null,
        src: '/images/task.svg',
        order: 6,
        text: 'nav.my-task',
      },
    ],
  };

  const logoutJson = {
    nav: {
      url: 'login',
      dispatch: logout(),
      src: '/images/logout.svg',
      order: 0,
      text: '',
    },
  };

  const employee = {
    nav: [
      {
        url: '/notifications',
        dispatch: null,
        src: '/images/notification.svg',
        order: 5,
        text: 'nav.notifications',
      },
    ],
  };

  const common = {
    nav: [
      {
        url: '/tasks',
        src: '/images/task.svg',
        dispatch: null,
        order: 1,
        text: 'nav.task',
      },
      // {
      //     "url": "/inprogress",
      //     "dispatch": null,
      //     "src": "images/timesheet.svg",
      //     "order": 3,
      //     "text": "Timesheets"
      // },
      {
        url: '/settings',
        dispatch: null,
        src: '/images/setting.svg',
        order: 6,
        text: 'nav.settings',
      },
      {
        url: '/product',
        dispatch: null,
        src: '/images/product.svg',
        order: 5,
        text: 'nav.products',
      },
    ],
  };

  // sorting method for nav by order
  const sortByColumn = (a, colName) => {
    a.sort(sortFunction);
    function sortFunction(a, b) {
      if (a[colName] === b[colName]) {
        return 0;
      } else {
        return a[colName] < b[colName] ? -1 : 1;
      }
    }
    return a;
  };

  useLayoutEffect(() => {
    let navArray = [];
    if (props.isAdmin === 1 || props.isAdmin === '1') {
      navArray = admin.nav;
    } else {
      navArray = employee.nav;
    }
    const finalNavArray = navArray.concat(common.nav);
    const sortedArray = sortByColumn(finalNavArray, 'order');
    setNav(sortedArray);
    setActiveLink(window.location.pathname);
  }, [props.isAdmin]);

  useEffect(() => {
    localStorage.setItem('lang', i18n.language);
  }, [i18n.language]);

  return (
    <div className='sidebar'>
      {!props.isLoggedIn ? (
        <Link className='sidebar-link' to='/login'>
          {t('button.login')}
        </Link>
      ) : (
        <div>
          <div className='profile-container d-flex align-items-center'>
            <img src={props.avatar} alt='avatar' className='profile-img-container' />
            <p className='user-name'>{props.username}</p>
            <div className='ml-auto'>
              <NavLink type='logout' url='/login' classNames={'logout-link ml-auto'} text={''} svg={logoutJson.nav.src} />
            </div>
          </div>
          <div className='sidebar-link-container'>
            {nav.map(({ url, src, text }, index) => (
              <NavLink
                key={index}
                activeLink={activeLink}
                classNames={'sidebar-link d-flex align-items-center'}
                setActiveLink={(link) => {
                  props.onChangeNav();
                  setActiveLink(link);
                }}
                lang={'false'}
                text={t(text)}
                url={url}
                svg={src}
              ></NavLink>
            ))}
            <Dropdown bsPrefix={'dropdown lang-dropdown'}>
              <div className='dropdown-btn-container'>
                <Dropdown.Toggle id='dropdown-basic'>{currentLanguage}</Dropdown.Toggle>
              </div>
              <Dropdown.Menu bsPrefix={'dropdown-menu langauge-dropdown'}>
                <Dropdown.Item>
                  <NavLink
                    classNames={`sidebar-link d-flex align-items-center ${i18n.language === 'tr' ? 'active' : ''}`}
                    onLangChange={(lang) => {
                      i18n.changeLanguage(lang);
                      setCurrentLanguage('Finnish');
                    }}
                    lang={true}
                    text={'Finnish'}
                    url={'tr'}
                  />
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink
                    classNames={`sidebar-link d-flex align-items-center ${i18n.language === 'en' ? 'active' : ''}`}
                    onLangChange={(lang) => {
                      i18n.changeLanguage(lang);
                      setCurrentLanguage('English');
                    }}
                    lang={true}
                    text={'English'}
                    url={'en'}
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}

      <div className='bg-img left-img'>
        <svg xmlns='http://www.w3.org/2000/svg' width='119.621' height='363.037' viewBox='0 0 119.621 363.037'>
          <path id='Path_59' data-name='Path 59' d='M548.616,68.118h0C548.616,30.5,582.456,0,624.2,0H911.653c0,66.066-59.425,119.621-132.731,119.621H605.766C574.2,119.621,548.616,96.561,548.616,68.118Z' transform='translate(0 911.653) rotate(-90)' fill='#c5c5c5' opacity='0.15' />
        </svg>
      </div>
      <div className='bg-img right-img'>
        <svg xmlns='http://www.w3.org/2000/svg' width='119.621' height='363.037' viewBox='0 0 119.621 363.037'>
          <path id='Path_60' data-name='Path 60' d='M548.616,68.118h0C548.616,30.5,582.456,0,624.2,0H911.653c0,66.066-59.425,119.621-132.731,119.621H605.766C574.2,119.621,548.616,96.561,548.616,68.118Z' transform='translate(119.621 -548.616) rotate(90)' fill='#c5c5c5' opacity='0.15' />
        </svg>
      </div>
      <div className='bg-img name-img'>Lännen Maalämpö Oy</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.login.isLoggedIn,
  username: state.login.username,
  isAdmin: state.login.isAdmin,
  avatar: state.login.avatar,
});
export default withRouter(connect(mapStateToProps)(Nav));
