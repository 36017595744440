import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchProduct, fetchUsers } from '../../actions';
import List from './List';
import './task.scss';
import { useTranslation } from 'react-i18next';
import PreLoader from '../shared/PreLoader';
import { Link } from 'react-router-dom';
import CachedIcon from '@material-ui/icons/Cached';
import { fetchTasks } from '../../actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import { savedChanges } from '../../actions/helper';

const Task = (props) => {
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const helper = useSelector((state) => state.helper);
  const [alert, setAlert] = useState(false);
  const taskListRefresh = () => {
    if (helper.unSavedChanges) {
      const getAlert = () => (
        <SweetAlert
          danger
          showCancel
          closeOnClickOutside={true}
          onCancel={() => {
            setAlert(null);
          }}
          title={`${t('task.details.messages.delete_modal_title')}`}
          onConfirm={() => {
            dispatch(fetchTasks());
            dispatch(savedChanges());
            setAlert(null);
          }}
          confirmBtnText={t('button.confirm')}
          cancelBtnText={t('button.cancel')}
          confirmBtnStyle={{ background: '#71BF5D', color: 'white' }}
          cancelBtnStyle={{ background: '#D43B3B', color: 'white', textDecoration: 'none' }}
        >
          {t('task.details.messages.unsaved')}
        </SweetAlert>
      );
      setAlert(getAlert());
    } else {
      dispatch(fetchTasks());
    }
  };

  useEffect(() => {}, [helper.unSavedChanges]);

  useLayoutEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchProduct());
  }, []);

  useEffect(() => {
    if (props.employees.employeesList && props.employees.employeesList.length > 0) {
      setIsLoading(false);
    } else {
      dispatch(fetchUsers());
      setIsLoading(true);
    }
  }, [props.employees.employeesList]);

  return (
    <div className='task-main-container'>
      <div className='d-flex align-items-center headerContainer'>
        <h1 className='taskHeading'>{t('task.heading')}</h1>
        {alert}
        {(props.isAdmin === 1 || props.isAdmin === '1') && (
          <div className='d-flex align-items-center'>
            <div className='newTaskImgContainer'>
              <Link to='/task/create'>
                <img src='/images/new_task.svg' alt='new task' />
              </Link>
            </div>
            <div>
              <button className={'refreshBtn'} onClick={taskListRefresh}>
                <CachedIcon style={{ color: '#707070' }} />
              </button>
            </div>
          </div>
        )}
      </div>

      <div className='task-list-container'>{isLoading || props.loader ? <PreLoader /> : <List employees={props.employees} />}</div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  employees: state.employees,
  isApiFetched: state.response.isApiFetched,
  loader: state.response.isLoading,
  isAdmin: state.login.isAdmin,
});
export default connect(mapStateToProps)(Task);
