import React from 'react';
import { useTranslation } from 'react-i18next';
import { Capitalize } from '../../../../Utility/Capitalize';

import './employee-completed-task-details.scss';
const CompletedTaskDetails = ({ client, description, kilometers_driven, service_hours, driving_hours, product_model, product_purchase_date_str, product_serial, urgency, products, kilometer_service_driven }) => {
  const { t } = useTranslation('common');
  const mapRowObjects = () => {
    const rows = kilometer_service_driven.map((row, index) => {
      return (
        <div className='driven-container d-flex justify-content-between customMappedRow customMappedRow-kmDrivenList' key={index}>
          <React.Fragment>
            <div className='column'>
              <h2>
                {t('task.employee_task_details.driving_hours_used')}
                <span className='driven-value'>
                  {row.driving_hours}
                  {t('task.details.hrs')}
                </span>
              </h2>
            </div>
            <div className='column'>
              <h2>
                {t('task.details.kilometer_driven')}
                <span className='driven-value'>
                  {row.kilometers_driven}
                  {t('task.details.km')}
                </span>
              </h2>
            </div>
            <div className='column'>
              <h2>
                {t('task.employee_task_details.hours_used')}
                <span className='driven-value'>
                  {row.service_hours}
                  {t('task.details.hrs')}
                </span>
              </h2>
            </div>
            <div className='column'>
              <h2>
                {t('task.employee_task_details.comment')}
                <span className='driven-value'>{row.comment}</span>
              </h2>
            </div>
          </React.Fragment>
        </div>
      );
    });

    return rows;
  };
  return (
    <div className='details-container'>
      <p>{t('task.details.customer')} : </p>

      <div className='details-content d-flex justify-content-between'>
        <div className='column'>
          <h3>
            {t('task.details.email')}: {client.email}
          </h3>
          <h3>
            {t('task.details.phone')}: {client.phone}
          </h3>
          <h3>
            {t('task.details.location')}: {Capitalize(client.address)}
          </h3>
          <h3>
            {t('task.details.zip')}: {client.zipcode}
          </h3>
          <h3>
            {t('task.details.city')}: {Capitalize(client.postal)}
          </h3>
        </div>
        <div className='column'>
          <h3>
            {t('task.details.model_heat_pump')}: {Capitalize(product_model)}{' '}
          </h3>
          <h3>
            {t('task.details.serial_number')}: {Capitalize(product_serial)}{' '}
          </h3>
          <h3>
            {t('task.details.purchase')}: {product_purchase_date_str}{' '}
          </h3>
        </div>

        <div className='column'>
          <h3>{t('task.details.maintenence')}</h3>
          <p>{Capitalize(description)}</p>
        </div>
        <div className='column'>
          <h3>{t('task.employee_task_details.urgency')}</h3>
          <p>{Capitalize(urgency)}</p>
        </div>
      </div>

      {kilometers_driven !== 0 && service_hours !== 0 && driving_hours !== 0 && kilometer_service_driven.length === 0 ? (
        <div className='driven-container d-flex justify-content-between'>
          <React.Fragment>
            <div className='column'>
              <h2>
                {t('task.details.kilometer_driven')}
                <span className='driven-value'>
                  {kilometers_driven}
                  {t('task.details.km')}
                </span>
              </h2>
            </div>
            <div className='column'>
              <h2>
                {t('task.employee_task_details.hours_used')}
                <span className='driven-value'>
                  {service_hours}
                  {t('task.details.hrs')}
                </span>
              </h2>
            </div>
            <div className='column'>
              <h2>
                {t('task.employee_task_details.driving_hours_used')}
                <span className='driven-value'>
                  {driving_hours}
                  {t('task.details.hrs')}
                </span>
              </h2>
            </div>
          </React.Fragment>
        </div>
      ) : (
        mapRowObjects()
      )}

      <div className='product-container'>
        <h2>{t('task.employee_task_details.products.heading')}</h2>
        <div className='product-content d-flex justify-content-between align-items-end'>
          <div className='column-container'>
            {products.length > 0
              ? products.map((product, index) => {
                  return (
                    <div key={index} className='column d-flex align-items-center justify-content-between'>
                      <div>
                        <p>{product.name}</p>
                      </div>
                      <div>
                        <p>
                          {t('task.employee_task_details.products.model')}: {product.model}
                        </p>
                      </div>
                      <div>
                        <p>
                          {t('task.employee_task_details.products.quantity')}: {product.pivot.quantity}
                        </p>
                      </div>
                    </div>
                  );
                })
              : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedTaskDetails;
