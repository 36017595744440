import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux'
import { Link, withRouter } from 'react-router-dom';
import {login} from '../../actions'
import PreLoader from '../shared/PreLoader';
import './Login.scss';

const Login = () => {
    const loader = useSelector(state => state.response.isLoading)
    const [username, setUserName] = useState("") 
    const [password, setPassword] = useState("") 
    const dispatch = useDispatch()
    const {t} = useTranslation('common')
    const formHandler = (event) => {
        event.preventDefault();
        if(username && password){
            dispatch(
                login({
                    username,
                    password
                })
            )
        }
    }
    return (
      <div className='login-main-container d-flex align-items-center justify-content-center'>
        {
          loader ?  
          <PreLoader />
          :
          <>
            <div className='bg-img logo'>
              <img src='/images/logo.png' alt="logo" className="img-fluid" />
            </div>
            <div className='login-container text-center'>
              <h1>{t('login.header')}</h1>
              <form onSubmit={(e) => formHandler(e)}>
                <div className=''>
                  {/* <label htmlFor='username'>User Name</label> */}
                  <input
                    onChange={(e) => setUserName(e.target.value)}
                    value={username}
                    className='input-field'
                    id='username'
                    type='text'
                    name='username'
                  />
                </div>
                <div className=''>
                  {/* <label htmlFor='password'>Password</label> */}
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    className='input-field'
                    id='password'
                    type='password'
                    name='password'
                  />
                  <p>
                    <Link to="/forget-password">{t('login.forget')}?</Link>
                  </p>
                </div>
                <div className=''>
                  <input type='submit' className='project-btn' value={t('login.button')} />
                </div>
              </form>
            </div>
            <div className='bg-img right-box'>
              <img src='/images/login-box.svg' alt="login-box" />
            </div>
            {/* <div className='bg-img bottom-left'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='381.404'
                height='119.621'
                viewBox='0 0 381.404 119.621'
              >
                <path
                  id='Path_3'
                  data-name='Path 3'
                  d='M381.4,496.123h0c0,37.621-35.552,68.118-79.408,68.118H0C0,498.175,62.432,444.62,139.446,444.62H321.363C354.521,444.62,381.4,467.68,381.4,496.123Z'
                  transform='translate(0 -444.62)'
                  fill='#efefef'
                />
              </svg>
            </div>
            <div className='bg-img left-oval'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='67.565'
                height='30.736'
                viewBox='0 0 67.565 30.736'
              >
                <path
                  id='Path_4'
                  data-name='Path 4'
                  d='M140.081,383.461c13.193,6,13.193,15.731,0,21.734s-34.582,6-47.775,0-13.193-15.733,0-21.734S126.888,377.458,140.081,383.461Z'
                  transform='translate(-82.411 -378.959)'
                  fill='#efefef'
                />
              </svg>
            </div> */}
          </>
        }
      </div>
    );
}

export default withRouter(Login)