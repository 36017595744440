// All common utility functions go here
export const Capitalize = (str) => {
  //regex to find any alphabets from a-z or A-Z in alphanumeric string
  const regex = /[a-zA-Z]/g;

  // condition to check if str exists & if it contains any alphabets
  if (str && str.match(regex)) {
    //change the original paragraph to lowercase
    let newPara = str
    //get all letters in an array if they match regex
    const arr = newPara.match(regex);
    //capitalize the first index of the alphabets inside array
    const capitalizeAlphabet = arr[0].toUpperCase();
    //replace the paragraphs first alphabet with capitalized letter
    let newStr = newPara.replace(arr[0], capitalizeAlphabet);

    return newStr;
  } else {
    return str;
  }
}
