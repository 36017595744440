/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../actions';
import PreLoader from '../shared/PreLoader';
import Input from '../shared/Input'
import './Register.scss';

const Register = ({location}) => {
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [serviceArea, setServiceArea] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [vat, setVAT] = useState('');
  const [company, setCompany] = useState('');
  const [image, setImage] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [errors, setErrors] = useState([]);
  const [token, setToken] = useState("");
  const{t} = useTranslation('common')
  const loader = useSelector(state => state.response.isLoading)
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('invitation_code')
    setToken(token)
    if(queryParams.has('invitation_code')){
      const email = queryParams.get('email')
      setEmail(email)
      queryParams.delete('email')
    }
    queryParams.delete('invitation_code')
    // history.replace({
    //   search: queryParams.toString(),
    // })
  }, [])

  const formValidation = () => {
    let formIsValid = true;
    let errors = [];
    if (firstname.length === 0) {
      formIsValid = false;
      errors.push({'firstname': t('register.error.name')})
    }
    if (lastname.length === 0) {
      formIsValid = false;
      errors.push({'lastname': t('register.error.lastname')})
    }

    // if (email.length === 0) {
    //   formIsValid = false;
    //   errors['email'] = 'Please provide your email';
    // } else if (!validEmailRegex.test(email)) {
    //   formIsValid = false;
    //   errors['email'] = 'Email id is not valid';
    // }

    if (address.length === 0) {
      formIsValid = false;
      errors.push({'address': t('register.error.address')})
    }

    if (serviceArea.length === 0) {
      formIsValid = false;
      errors.push({'serviceArea': t('register.error.serviceArea')})
    }

    if (city.length === 0) {
      formIsValid = false;
      errors.push({'city': t('register.error.city')})
    }

    if (zip.length === 0) {
      formIsValid = false;
      errors.push({'zip': t('register.error.zip')})
    }

    if (vat.length === 0) {
      formIsValid = false;
      errors.push({'vat': t('register.error.vat')})
    }

    if (company.length === 0) {
      formIsValid = false;
      errors.push({'company': t('register.error.company')})
    }

    if (password.length === 0) {
      formIsValid = false;
      errors.push({'password': t('register.error.password')})
    }

    if (confirmPassword.length === 0) {
      formIsValid = false;
      errors.push({'confirmPassword': t('register.error.confirmPassword.empty')})
    } else if (password !== confirmPassword) {
      formIsValid = false;
      errors.push({'confirmPassword': t('register.error.confirmPassword.match')})
    }

    if (formIsValid) {
      return true;
    } else {
      setErrors(errors);
      return false;
    }
  };

  const handleChangeImage = (event) => {
    setImageURL(URL.createObjectURL(event.target.files[0]));
    setImage(event.target.files[0]);
  };

  const handleEmailChange = (e) => {
    setErrors([
        ...errors, 
        email =>  t('regiser.error.email')])
  }

  const resetErrors = (name) => {
    const updatedErrors = errors.map((error) => {
      return {
          ...error,
          [name]: ""
      }
   })
   setErrors(updatedErrors)
  }

  const formHandler = (event) => {
    event.preventDefault();
    const validateForm = formValidation();
    if (!validateForm) {
      return false;
    }
    dispatch(
      register({
        firstname,
        lastname,
        email,
        address,
        phone,
        password,
        serviceArea,
        city,
        zip,
        vat,
        company,
        confirmPassword,
        token,
        image
      })
    );
  };

  return (
    <div className='register-main-container d-flex align-items-center justify-content-center'>
      {
        loader ? <PreLoader /> :
        <>
            {/* <div className='bg-img top-left'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='515.505'
                height='201.118'
                viewBox='0 0 515.505 201.118'
              >
                <path
                  id='Path_2'
                  data-name='Path 2'
                  d='M515.5,0h0c0,111.074-84.95,201.118-189.74,201.118H0V0Z'
                  fill='#efefef'
                />
              </svg>
            </div>
            <div className='bg-img top-right'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='363.037'
                height='119.621'
                viewBox='0 0 363.037 119.621'
              >
                <path
                  id='Path_1'
                  data-name='Path 1'
                  d='M548.616,68.118h0C548.616,30.5,582.456,0,624.2,0H911.653c0,66.066-59.425,119.621-132.731,119.621H605.766C574.2,119.621,548.616,96.561,548.616,68.118Z'
                  transform='translate(-548.616)'
                  fill='#efefef'
                />
              </svg>
            </div>
            <div className='bg-img right-oval'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='67.565'
                height='30.736'
                viewBox='0 0 67.565 30.736'
              >
                <path
                  id='Path_4'
                  data-name='Path 4'
                  d='M140.081,383.461c13.193,6,13.193,15.731,0,21.734s-34.582,6-47.775,0-13.193-15.733,0-21.734S126.888,377.458,140.081,383.461Z'
                  transform='translate(-82.411 -378.959)'
                  fill='#efefef'
                />
              </svg>
            </div> */}
            <div className='bg-img right-box'>
              <img src='/images/login-box.svg' alt="login-box" />
            </div>
            <div className='bg-img logo'>
              <img src='/images/logo.png' alt="logo" className='img-fluid' />
            </div>

            <div className='register-container text-center'>
              <h1>{t('register.heading')}</h1>
              <form onSubmit={(e) => formHandler(e)} encType="multipart/form-data">
              
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='img-column'>
                    <div className='uploaded-img-container'>
                      <img src={imageURL} alt="avatar" className='uploaded-img' />
                    </div>
                    <div className='error-message'>{errors.image}</div>
                  </div>
                  <div className='input-column'>
                    <Input
                        className='input-field'
                        type={'file'}
                        name={'image'}
                        id={'image'}
                        onChangeHandle={handleChangeImage}
                    />
                    <Input
                        className='input-field'
                        type={'text'}
                        name={'firstname'}
                        id={'firstname'}
                        placeholder={t('register.first_name')}
                        value={firstname}
                        errors={errors}
                        onChangeHandle={(e) => {
                          setFirstName(e.target.value);
                          resetErrors(e.target.name)
                        }}
                    />
                    <Input
                        className='input-field'
                        type={'text'}
                        name={'lastname'}
                        id={'lastname'}
                        placeholder={t('register.last_name')}
                        value={lastname}
                        errors={errors}
                        onChangeHandle={(e) => {
                          setLastName(e.target.value);
                          resetErrors(e.target.name)
                        }}
                    />
                  </div>
                </div>
                <Input
                    className='input-field employee-input'
                    type={'text'}
                    name={'email'}
                    id={'email'}
                    placeholder={t('register.email')}
                    value={email}
                    disabled={'disabled'}
                    errors={errors}
                    onChangeHandle={(e) => {
                      handleEmailChange(e)
                    }}
                />
                <div className="row">  
                  <div className='col-12 col-md-6'>
                    <Input
                        className='input-field employee-input'
                        type={'text'}
                        name={'serviceArea'}
                        id={'serviceArea'}
                        placeholder={t('register.area')}
                        value={serviceArea}
                        errors={errors}
                        onChangeHandle={(e) => {
                          setServiceArea(e.target.value);
                          resetErrors(e.target.name)
                        }}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <Input
                      className='input-field employee-input'
                      type={'text'}
                      name={'phone'}
                      id={'phone'}
                      placeholder={t('register.phone')}
                      value={phone}
                      errors={errors}
                      onChangeHandle={(e) => {
                        setPhone(e.target.value);
                        resetErrors(e.target.name)
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className='col-12 col-md-6'>
                    <Input
                      className='input-field employee-input'
                      type={'password'}
                      name={'password'}
                      id={'password'}
                      placeholder={t('register.password')}
                      value={password}
                      errors={errors}
                      onChangeHandle={(e) => {
                        setPassword(e.target.value);
                        resetErrors(e.target.name)
                      }}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <Input
                      className='input-field employee-input'
                      type={'password'}
                      name={'confirmPassword'}
                      id={'confirmPassword'}
                      placeholder={t('register.confirm_password')}
                      value={confirmPassword}
                      errors={errors}
                      onChangeHandle={(e) => {
                        setConfirmPassword(e.target.value);
                        resetErrors(e.target.name)
                      }}
                    />
                  </div>
                </div>
                <Input
                  className='input-field employee-input'
                  type={'text'}
                  name={'city'}
                  id={'city'}
                  placeholder={t('register.city')}
                  value={city}
                  errors={errors}
                  onChangeHandle={(e) => {
                    setCity(e.target.value);
                    resetErrors(e.target.name)
                  }}
                />
                <Input
                  className='input-field employee-input'
                  type={'text'}
                  name={'zip'}
                  id={'zip'}
                  placeholder={t('register.zip')}
                  value={zip}
                  errors={errors}
                  onChangeHandle={(e) => {
                    setZip(e.target.value);
                    resetErrors(e.target.name)
                  }}
                />
                <Input
                  className='input-field employee-input'
                  type={'text'}
                  name={'vat'}
                  id={'vat'}
                  placeholder={t('register.vat')}
                  value={vat}
                  errors={errors}
                  onChangeHandle={(e) => {
                    setVAT(e.target.value);
                    resetErrors(e.target.name)
                  }}
                />
                <Input
                  className='input-field employee-input'
                  type={'text'}
                  name={'company'}
                  id={'company'}
                  placeholder={t('register.company')}
                  value={company}
                  errors={errors}
                  onChangeHandle={(e) => {
                    setCompany(e.target.value);
                    resetErrors(e.target.name)
                  }}
                />
                <Input
                  className='input-field employee-input'
                  type={'text'}
                  name={'address'}
                  id={'address'}
                  placeholder={t('register.address')}
                  value={address}
                  errors={errors}
                  onChangeHandle={(e) => {
                    setAddress(e.target.value);
                    resetErrors(e.target.name)
                  }}
                />
                <div className=''>
                  <input
                    className='project-btn'
                    type='submit'
                    value={t('register.create')}
                  />
                </div>
              </form>
            </div>
            {/* <div className='bg-img bottom-left'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='381.404'
                height='119.621'
                viewBox='0 0 381.404 119.621'
              >
                <path
                  id='Path_3'
                  data-name='Path 3'
                  d='M381.4,496.123h0c0,37.621-35.552,68.118-79.408,68.118H0C0,498.175,62.432,444.62,139.446,444.62H321.363C354.521,444.62,381.4,467.68,381.4,496.123Z'
                  transform='translate(0 -444.62)'
                  fill='#efefef'
                />
              </svg>
            </div>
            <div className='bg-img left-oval'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='67.565'
                height='30.736'
                viewBox='0 0 67.565 30.736'
              >
                <path
                  id='Path_4'
                  data-name='Path 4'
                  d='M140.081,383.461c13.193,6,13.193,15.731,0,21.734s-34.582,6-47.775,0-13.193-15.733,0-21.734S126.888,377.458,140.081,383.461Z'
                  transform='translate(-82.411 -378.959)'
                  fill='#efefef'
                />
              </svg>
            </div> */}
        </>  
      }
    </div>
  );
};

export default Register;
