import Api from "../Api"

export const forgetPassword = ({email}) => {
    return(dispatch) => {
        Api.get(`sanctum/csrf-cookie`)
        .then(response => {
            dispatch({type: 'API_IS_FETCHING'})
            Api.post(`api/forget-password`,{
                email
            }).then((response) => {
                dispatch({type: 'SUCCESS', 'message': `Password reset link sent to your email-id ${email}`});
                dispatch({type: 'API_FETCHED'})
            }).catch((errors) => {
                dispatch({type: 'API_FETCHED'})
                if(errors.response.status === 422){
                    dispatch({type: 'ERROR', 'status': errors.response.status , 'message': errors.response.data});
                }else{
                    dispatch({type: 'ERROR', 'status': errors.response.status , 'message': "Something went wrong while sending link"});
                }
            })
        })
    } 
}

export const resetPassword = ({password, confPassword, token}) => {
    return(dispatch) => {
        Api.get(`sanctum/csrf-cookie`)
        .then(response => {
            dispatch({type: 'API_IS_FETCHING'})
            Api.post(`api/reset-password`,{
                password,
                confPassword,
                token
            }).then((response) => {
                dispatch({type: 'PASSWORD_RESET_SUCCESS', 'message': `Password reset successfully`});
                dispatch({type: 'API_FETCHED'})
            }).catch((errors) => {
                dispatch({type: 'API_FETCHED'})
                if(errors.response.status === 422){
                    dispatch({type: 'ERROR', 'status': errors.response.status , 'message': errors.response.data});
                }else{
                    dispatch({type: 'ERROR', 'status': errors.response.status , 'message': "Something went wrong while sending link"});
                }
            })
        })
    } 
}