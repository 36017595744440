import React from 'react'
import './Input.scss'

const Input = ({className, mainClass, errors, label, type, onKeyUp, onChangeHandle, disabled, name, placeholder, value, id}) => {
    return (
      <div>
        <div className={mainClass ? `${mainClass} d-flex align-items-center` : 'd-flex align-items-center'}>
          <React.Fragment>
            {label ? <label className='input-label' htmlFor={id}>
              {label}
            </label> : ""}
            <input
              className={className ? className : 'input-field'}
              type={type}
              onChange={onChangeHandle}
              onKeyUp={onKeyUp}
              name={name}
              disabled={disabled ? 'disabled' : ''}
              placeholder={placeholder}
              value={value}
              id={id}
            />
          </React.Fragment>
        </div>
        {errors ? <div className='error-message mb-4'>
          {' '}
          {Array.isArray(errors) && errors.length > 0
            ? errors.map((error) => error[name])
            : ''}
        </div> : ""}
      </div>
    );
    
    
}

export default Input