import React from 'react'
import './Details.scss'

const Details = ({data}) => {
    return (
        <div className="notification-message-container">
            {
                data.data.hasOwnProperty('attention_desc') ? 
                <span>{data.data.attention_desc}</span>
                :
                ""
            }
        </div>
    )
}

export default Details