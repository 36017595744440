import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalPopup = ({ open, header, dialogClass, setModalClose, children }) => {
  return (
    <Modal contentClassName={'content'} dialogClassName={dialogClass ? dialogClass : 'dialog'} autoFocus={true} centered show={open} onHide={() => setModalClose(!open)}>
      <Modal.Header closeButton>
        <Modal.Title bsPrefix='w-100'>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

// const mapStateToProps = state => ({
//     isCloseModel : state.invite.closeModal
// })
export default ModalPopup;
