export const StatusLabel = (t) =>  {
        return {
                completed : t('task.status.completed'),
                assigned : t('task.status.assigned'),
                running : t('task.status.running'),
                invoiced : t('task.status.invoiced'),
                unassigned : t('task.status.unassigned')
        }
}

export const Constant = {
        completed: "COMPLETED", 
        invoiced: "INVOICED", 
}

export const defaultProductsCode = {
        kilometer: 'HKM',
        service: 'H1',
        driving: 'AJO1'
}