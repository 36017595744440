import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProduct, fetchUsers } from '../../../actions';
import { useTranslation } from 'react-i18next';
import LargeTile from '../../../containers/shared/LargeTile'
import SmallTile from '../../../containers/shared/SmallTile'
import './overview-card.scss';

const Overview = ({changeTasks}) => {
    const dispatch = useDispatch();
    const task = useSelector(state => state.taskList.tasks)
    const employees = useSelector(state => state.employees)
    const products = useSelector(state => state.products.productsList)
    const [newTasks, setNewTask] = useState(0)
    const [pendingTasks, setPendingTask] = useState(0)
    const [needAttentionTasks, setAttentionCount] = useState(0)
    const [invoicedTasks, setinvoicedCount] = useState(0)
    const [unInvoicedTasks, setUnInvoicedCount] = useState(0)
    const [selectedTile, setSelectedTile] = useState("")
    // const [employeesCount, setEmployeesCount] = useState(0)
    // const [productsCount, setProductsCount] = useState(0)
    const {t} = useTranslation('common')

    useLayoutEffect(() => {
        saveCounts()
        handleFilter('new_task')
    },[])
    useEffect(() => {
        if(task && task.length > 0 && employees.employeesList && employees.employeesList.length > 0 && products && products.length > 0){
          return saveCounts()
        } 
    },[task])

    const saveCounts = () => {
        const newTasks = task.filter(task => task.status.toUpperCase() === 'NEW REQUEST')
        const pendingTasks = task.filter(task => task.status.toUpperCase() === 'ASSIGNED')
        const unInvoicedTasks = task.filter(task => task.status.toUpperCase() === 'COMPLETED')
        const invoicedTasks = task.filter(task => task.status.toUpperCase() === 'INVOICED')
        const requireAttentions = task.filter(task => task.require_attention === 1)
        setNewTask(newTasks.length)
        setPendingTask(pendingTasks.length)
        setAttentionCount(requireAttentions.length)
        setUnInvoicedCount(unInvoicedTasks.length)
        setinvoicedCount(invoicedTasks.length)
        // setEmployeesCount(employees.employeesList.length)
        // setProductsCount(products.length)
    }

    const handleFilter = filterType => {
      let tasks = []
      if(filterType === 'new_task'){
        tasks = task.filter(task => task.status.toUpperCase() === 'NEW REQUEST')
      }else if(filterType === 'pending_task'){
        tasks = task.filter(task => task.status.toUpperCase() === 'ASSIGNED')
      }else if(filterType === 'need_attention'){
        tasks = task.filter(task => task.require_attention === 1)
      }else if(filterType === 'invoiced_task'){
        tasks = task.filter(task => task.status.toUpperCase() === 'INVOICED')
      }else if(filterType === 'uninvoiced_task'){
        tasks = task.filter(task => task.status.toUpperCase() === 'COMPLETED')
      }
      setSelectedTile(filterType)
      changeTasks(filterType)
    }
    
    return (
      <div className='overview-card-container d-flex justify-content-between'>
        <div className={selectedTile === 'new_task' ? `overview-card active-tab` : `overview-card`} onClick={() => handleFilter('new_task')}>
          <div className='bg-img top-img'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 173.753 57.251'
            >
              <path
                id='Path_70'
                data-name='Path 70'
                d='M548.616,32.6h0c0-18.006,16.2-32.6,36.175-32.6H722.369c0,31.62-28.442,57.251-63.526,57.251H575.968C560.863,57.251,548.616,46.215,548.616,32.6Z'
                transform='translate(-548.616)'
                fill='#c5c5c5'
                opacity='0.23'
              />
            </svg>
          </div>
          <LargeTile
            title={{
              text: t('summary.tile.new_tasks'),
              classNames: 'overview-card-title',
            }}
            amount={{
              text: newTasks,
              classNames: 'card-amount',
            }}
          />

          <div className='bg-img bottom-img'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 293.58 96.735'>
              <path
                id='Path_66'
                data-name='Path 66'
                d='M548.616,55.085h0C548.616,24.662,575.982,0,609.739,0H842.2c0,53.426-48.056,96.735-107.337,96.735H594.832C569.308,96.735,548.616,78.086,548.616,55.085Z'
                transform='translate(842.196 96.735) rotate(-180)'
                fill='#c5c5c5'
                opacity='0.23'
              />
            </svg>
          </div>
        </div>
        <div className={selectedTile === 'pending_task' ? `overview-card active-tab` : `overview-card`} onClick={() => handleFilter('pending_task')}>
          <div className='bg-img top-img'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 173.753 57.251'
            >
              <path
                id='Path_70'
                data-name='Path 70'
                d='M548.616,32.6h0c0-18.006,16.2-32.6,36.175-32.6H722.369c0,31.62-28.442,57.251-63.526,57.251H575.968C560.863,57.251,548.616,46.215,548.616,32.6Z'
                transform='translate(-548.616)'
                fill='#c5c5c5'
                opacity='0.23'
              />
            </svg>
          </div>
          <LargeTile
            title={{
              text: `${t('summary.tile.pending_tasks')}`,
              classNames: 'overview-card-title',
            }}
            amount={{
              text: pendingTasks,
              classNames: 'card-amount',
            }}
          />
          <div className='bg-img bottom-img'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 293.58 96.735'>
              <path
                id='Path_66'
                data-name='Path 66'
                d='M548.616,55.085h0C548.616,24.662,575.982,0,609.739,0H842.2c0,53.426-48.056,96.735-107.337,96.735H594.832C569.308,96.735,548.616,78.086,548.616,55.085Z'
                transform='translate(842.196 96.735) rotate(-180)'
                fill='#c5c5c5'
                opacity='0.23'
              />
            </svg>
          </div>
        </div>
        <div className={selectedTile === 'need_attention' ? `overview-card active-tab` : `overview-card`} onClick={() => handleFilter('need_attention')}>
          <div className='bg-img top-img'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 173.753 57.251'
            >
              <path
                id='Path_70'
                data-name='Path 70'
                d='M548.616,32.6h0c0-18.006,16.2-32.6,36.175-32.6H722.369c0,31.62-28.442,57.251-63.526,57.251H575.968C560.863,57.251,548.616,46.215,548.616,32.6Z'
                transform='translate(-548.616)'
                fill='#c5c5c5'
                opacity='0.23'
              />
            </svg>
          </div>
          <LargeTile
            title={{
              text: `${t('summary.tile.need_attentions')}`,
              classNames: 'overview-card-title',
            }}
            amount={{
              text: needAttentionTasks,
              classNames: 'card-amount',
            }}
          />
          <div className='bg-img bottom-img'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 293.58 96.735'>
              <path
                id='Path_66'
                data-name='Path 66'
                d='M548.616,55.085h0C548.616,24.662,575.982,0,609.739,0H842.2c0,53.426-48.056,96.735-107.337,96.735H594.832C569.308,96.735,548.616,78.086,548.616,55.085Z'
                transform='translate(842.196 96.735) rotate(-180)'
                fill='#c5c5c5'
                opacity='0.23'
              />
            </svg>
          </div>
        </div>
        <div>
          <div className='small-card-container'>
            <div className={selectedTile === 'uninvoiced_task' ? `overview-card active-tab` : `overview-card`} onClick={() => handleFilter('uninvoiced_task')}>
              <SmallTile
                // icon={{
                //   classNames: 'card-icon',
                //   iconSrcClassNames: 'small-tile-icon-src',
                // }}
                title={{
                  text: `${t('summary.tile.uninvoiced')}`,
                  classNames: 'overview-card-title',
                }}
                amount={{
                  text: unInvoicedTasks,
                  classNames: 'card-amount',
                }}
              />
            </div>
            <div className={selectedTile === 'invoiced_task' ? `overview-card active-tab` : `overview-card`} onClick={() => handleFilter('invoiced_task')}>
              <SmallTile
                // icon={{
                //   classNames: 'card-icon',
                //   iconSrcClassNames: 'small-tile-icon-src',
                // }}
                title={{
                  text: `${t('summary.tile.invoiced')}`,
                  classNames: 'overview-card-title',
                }}
                amount={{
                  text: invoicedTasks,
                  classNames: 'card-amount',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
}

export default Overview