import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resetPassword } from '../../actions/password'
import Input from '../shared/Input'
import PreLoader from '../shared/PreLoader'
import './reset-password.scss';
const ResetPassword = ({location}) => {
    const serverErrors = useSelector(state => state.response.errors);
    const reponseSuccess = useSelector(state => state.response.resetPassword);
    const history = useHistory()
    const dispatch = useDispatch();
    const [password, setPassword] = useState("")
    const [confPassword, setConfPassword] = useState("")
    const [errors, setErrors] = useState([])
    const [token, setToken] = useState("")
    const {t} = useTranslation('common')
    const loader = useSelector(state => state.response.isLoading)

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token')
        setToken(token)
        queryParams.delete('token')
    }, [])

    useEffect(() => {  
        if(serverErrors.length > 0){
            let errorsArray = []
            Object.keys(serverErrors)
            .map((key) => { 
                return errorsArray[key] = serverErrors[key]
            });
            setErrors(errorsArray)
        }
    },[serverErrors])

    useEffect(() => {  
        if(reponseSuccess){
            history.push('/login');
        }
    },[reponseSuccess])

    
    const formValidation = () => {
        let formIsValid = true;
        let errors = [];
        
        if (password.length === 0) {
          formIsValid = false;
          errors.push({'password': t("reset_password.form.password.error")})
        } 
        if(confPassword.length === 0) {
            formIsValid = false;
            errors.push({'confPassword': t("reset_password.form.confPassword.error.empty")})
        }else if(password !== confPassword) {
            formIsValid = false;
            errors.push({'confPassword': t("reset_password.form.confPassword.error.invalid")})
        }

        if(!formIsValid){
            setErrors(errors)
        }
        return formIsValid;
    }

    const formHandler = (event) => {
        event.preventDefault();
        const valid = formValidation();
        if(!valid){
            return false;
        }
        dispatch(resetPassword({password, confPassword, token}))
    }

    const handleChange = e => {
        const value = e.target.value;
        const name = e.target.name;
        if(name === 'password'){
            setPassword(value)
        }
        if(name === 'confPassword'){
            setConfPassword(value)
        }
        const updatedErrors = errors.map((error) => {
            return {
                ...error,
                [name]: ""
            }
        }) 
        setErrors(updatedErrors)
    }

    return(
        <div className='login-main-container reset-password-page d-flex align-items-center justify-content-center'>
            {
                loader ? <PreLoader />
                :
                <div className='login-container text-center'>
                    <h1>{t('reset_password.header')}</h1>
                    <form onSubmit={(e) => formHandler(e)}>
                        <div className=''>
                        {/* <label htmlFor='username'>User Name</label> */}
                        <Input
                            errors={errors}
                            label={t('reset_password.form.password.label')}
                            placeholder={t('reset_password.form.password.placeholder')}
                            name={"password"}
                            value={password}
                            onChangeHandle={handleChange}
                            type={"password"}
                        />
                        <Input
                            errors={errors}
                            label={t('reset_password.form.confPassword.label')}
                            placeholder={t('reset_password.form.confPassword.placeholder')}
                            name={"confPassword"}
                            value={confPassword}
                            onChangeHandle={handleChange}
                            type={"password"}
                        />
                        </div>
                        <div className=''>
                        <input type='submit' className='project-btn' value={t('button.reset')} />
                        </div>
                    </form>
                </div>   
            }
        </div>
    )
}

export default ResetPassword