import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductPrice, updateKilometerHoursPrice, sendInvoice } from '../../../actions';
import PreLoader from '../../shared/PreLoader';
import { Constant, defaultProductsCode } from '../../shared/StatusLabel';
import './task-details.scss';
import { Capitalize } from '../../../Utility/Capitalize';
import PersonCheckSvg from '../../../svgs/personCheck';

const Details = ({
  client,
  description,
  kilometers_driven,
  product_model,
  product_purchase_date_str,
  product_serial,
  service_hours,
  driving_hours_price,
  driving_hours,
  urgency,
  id,
  price_kilometers_driven,
  price_timing,
  products,
  require_attention_desc,
  require_attention,
  status,
  kilometer_service_driven,
}) => {
  const isLoading = useSelector((state) => state.response.isLoading);
  const productId = useSelector((state) => state.response.productId);
  const [listOfProducts, SetListOfProducts] = useState([]);
  const allProducts = useSelector((state) => state.products);
  const customTasksState = useSelector((state) => state);
  const productPriceSuccess = useSelector((state) => state.response.productPriceSuccess);
  const hoursPriceSuccess = useSelector((state) => state.response.hoursPriceSuccess);
  const type = useSelector((state) => state.response.type);
  const [totalPrice, setTotalPrice] = useState(0);
  const [fieldValues, setFieldValues] = useState([]);
  useEffect(() => {
    if (products && products.length > 0) {
      SetListOfProducts(products);
    }
  }, [customTasksState, products, listOfProducts]);
  // const initkilometerPrice = price_kilometers_driven ? props.bar._id : '';
  const [kilometerPrice, setKilometerPrice] = useState(() => (price_kilometers_driven ? price_kilometers_driven : 0));

  const [hoursPrice, setHoursPrice] = useState(() => (price_timing ? price_timing : 0));
  const [drivingHoursPrice, setDrivingHoursPrice] = useState(() => (driving_hours_price ? driving_hours_price : 0));
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [newPricing, SetNewPricing] = useState([]);

  const getTotalPrice = React.useCallback(() => {
    let total = 0;
    let fieldValues = [];
    if (products.length > 0) {
      products.map((product) => {
        fieldValues.push({
          field: product.name,
          val: !product.pivot.hasOwnProperty('price') || product.pivot.price === null ? parseFloat(product.price) : product.pivot.price,
          quantity: parseFloat(product.pivot.quantity),
        });
        if (!product.pivot.hasOwnProperty('price') || product.pivot.price === null) {
          total += parseFloat(product.price) * parseFloat(product.pivot.quantity);
        } else {
          total += parseFloat(product.pivot.price) * parseFloat(product.pivot.quantity);
        }
        return total;
      });
    }
    let kilometerServiceDrivingPrice = 0;
    let kmserdrFieldValues = [];
    if (kilometer_service_driven.length > 0) {
      kilometer_service_driven.map((row) => {
        kmserdrFieldValues.push({
          totalKmDrivenPricePerQantity: row.kilometer_service_driven_price && row.kilometer_service_driven_price !== null ? parseFloat(row.kilometers_driven) * parseFloat(row.kilometer_service_driven_price.kilometers_driven_price) : parseFloat(row.kilometers_driven) * parseFloat(kilometerPrice),
          totalServiceHrsPricePerQantity: row.kilometer_service_driven_price && row.kilometer_service_driven_price !== null ? parseFloat(row.service_hours) * parseFloat(row.kilometer_service_driven_price.service_hours_price) : parseFloat(row.service_hours) * parseFloat(hoursPrice),
          totalDriveHrsPricePerQantity: row.kilometer_service_driven_price && row.kilometer_service_driven_price !== null ? parseFloat(row.driving_hours) * parseFloat(row.kilometer_service_driven_price.driving_hours_price) : parseFloat(row.driving_hours) * parseFloat(drivingHoursPrice),
        });
        return kmserdrFieldValues;
      });

      const totalKilometerPrice = kmserdrFieldValues.reduce((accumulator, currentValue) => {
        return (accumulator += currentValue.totalKmDrivenPricePerQantity);
      }, 0);

      const totalServicePrice = kmserdrFieldValues.reduce((accumulator, currentValue) => {
        return (accumulator += currentValue.totalServiceHrsPricePerQantity);
      }, 0);

      const totalDrivingPrice = kmserdrFieldValues.reduce((accumulator, currentValue) => {
        return (accumulator += currentValue.totalDriveHrsPricePerQantity);
      }, 0);

      kilometerServiceDrivingPrice = totalKilometerPrice + totalServicePrice + totalDrivingPrice;
    }

    setTotalPrice(total + kilometerServiceDrivingPrice);
  }, [drivingHoursPrice, hoursPrice, kilometerPrice, kilometer_service_driven, products]);

  const mapPriceKmHoursDriven = React.useCallback(() => {
    let kmDriven = [...kilometer_service_driven];
    kmDriven.map((element) => {
      if (element.kilometer_service_driven_price && element.kilometer_service_driven_price !== null) {
        element.price_kilometers_driven = element.kilometer_service_driven_price.kilometers_driven_price;
        element.price_service_hours = element.kilometer_service_driven_price.service_hours_price;
        element.driving_hours_price = element.kilometer_service_driven_price.driving_hours_price;
        return element;
      } else {
        element.price_kilometers_driven = parseFloat(kilometerPrice);
        element.price_service_hours = parseFloat(hoursPrice);
        element.driving_hours_price = parseFloat(drivingHoursPrice);
        return element;
      }
    });

    SetNewPricing(kmDriven);
    return kmDriven;
  }, [kilometer_service_driven, kilometerPrice, hoursPrice, drivingHoursPrice, products]);

  useEffect(() => {
    if (kilometer_service_driven && kilometer_service_driven.length > 0) {
      mapPriceKmHoursDriven();
      getTotalPrice();
    }
  }, [kilometer_service_driven, getTotalPrice, mapPriceKmHoursDriven]);

  useLayoutEffect(() => {
    getTotalPrice();
    const kilometerProduct = allProducts.productsList.find((product) => product.model === defaultProductsCode.kilometer);
    const serviceProduct = allProducts.productsList.find((product) => product.model === defaultProductsCode.service);
    const drivingProduct = allProducts.productsList.find((product) => product.model === defaultProductsCode.driving);
    if (!price_kilometers_driven && kilometerProduct) {
      setKilometerPrice(kilometerProduct.price);
    }
    if (!price_timing && serviceProduct) {
      setHoursPrice(serviceProduct.price);
    }
    if (!driving_hours_price && drivingProduct) {
      setDrivingHoursPrice(drivingProduct.price);
    }
    mapPriceKmHoursDriven();
  }, [allProducts.productsList, driving_hours_price, getTotalPrice, mapPriceKmHoursDriven, price_kilometers_driven, price_timing, totalPrice]);

  const handleSaveKilometerPrice = (e, type) => {
    const kilometerPricePerUnit = parseFloat(kilometerPrice);
    if (Number.isFinite(kilometerPricePerUnit) && kilometerPricePerUnit > 0) {
      const hoursPricePerUnit = hoursPrice !== null ? hoursPrice : 0;
      const drivingHoursPricePerUnit = drivingHoursPrice !== null ? drivingHoursPrice : 0;
      if (status.toUpperCase() !== 'INVOICED') {
        dispatch(
          updateKilometerHoursPrice({
            kilometerPricePerUnit,
            drivingHoursPricePerUnit,
            hoursPricePerUnit,
            taskId: id,
            type,
          })
        );
      }
    }
  };

  const handleSaveHoursPrice = (e, type) => {
    const hoursPricePerUnit = parseFloat(hoursPrice);
    if (Number.isFinite(hoursPricePerUnit) && hoursPricePerUnit > 0) {
      const kilometerPricePerUnit = kilometerPrice !== null ? kilometerPrice : 0;
      const drivingHoursPricePerUnit = drivingHoursPrice !== null ? drivingHoursPrice : 0;
      if (status.toUpperCase() !== 'INVOICED') {
        dispatch(
          updateKilometerHoursPrice({
            kilometerPricePerUnit,
            drivingHoursPricePerUnit,
            hoursPricePerUnit,
            taskId: id,
            type,
          })
        );
      }
    }
  };

  const handleSaveDrivingHoursPrice = (e, type) => {
    const drivingHoursPricePerUnit = parseFloat(drivingHoursPrice);
    if (Number.isFinite(drivingHoursPricePerUnit) && drivingHoursPricePerUnit > 0) {
      const kilometerPricePerUnit = kilometerPrice !== null ? kilometerPrice : 0;
      const hoursPricePerUnit = hoursPrice !== null ? hoursPrice : 0;
      if (status.toUpperCase() !== 'INVOICED') {
        dispatch(
          updateKilometerHoursPrice({
            kilometerPricePerUnit,
            drivingHoursPricePerUnit,
            hoursPricePerUnit,
            taskId: id,
            type,
          })
        );
      }
    }
  };

  const changeProductCost = (event, quantity) => {
    const array = fieldValues.map((data, index) => {
      if (event.target.name === data.field) {
        return {
          field: event.target.name,
          val: isFinite(parseFloat(event.target.value)) ? parseFloat(event.target.value) : 0,
          quantity: parseFloat(quantity),
        };
      } else {
        return { ...data };
      }
    });
    let total = 0;
    array.map((data) => {
      return (total += data.val * data.quantity);
    });
    // No need to add validations for kilometers_driven , service_hours or driving_hours as these values are fetched from database
    total += (isFinite(kilometerPrice) ? parseFloat(kilometerPrice) : 0) * (kilometers_driven ? parseFloat(kilometers_driven) : 0);
    total += (isFinite(hoursPrice) ? parseFloat(hoursPrice) : 0) * (service_hours ? parseFloat(service_hours) : 0);
    total += (isFinite(drivingHoursPrice) ? parseFloat(drivingHoursPrice) : 0) * (driving_hours ? parseFloat(driving_hours) : 0);
    setFieldValues(array);
    setTotalPrice(total);
  };

  const changeKilometerHoursPrice = (type, val) => {
    let total = 0;
    fieldValues.map((data) => {
      return (total += data.val * data.quantity);
    });

    if (isFinite(parseFloat(val))) {
      if (type === 'kilometer') {
        total += parseFloat(val) * (kilometers_driven ? parseFloat(kilometers_driven) : 0);
        total += parseFloat(hoursPrice !== 0 ? hoursPrice : 0) * (service_hours ? parseFloat(service_hours) : 0);
        total += parseFloat(drivingHoursPrice !== 0 ? drivingHoursPrice : 0) * (driving_hours ? parseFloat(driving_hours) : 0);
      } else if (type === 'driving_hours') {
        total += parseFloat(val) * (driving_hours ? parseFloat(driving_hours) : 0);
        total += parseFloat(kilometerPrice !== 0 ? kilometerPrice : 0) * (kilometers_driven ? parseFloat(kilometers_driven) : 0);
        total += parseFloat(hoursPrice !== 0 ? hoursPrice : 0) * (service_hours ? parseFloat(service_hours) : 0);
      } else {
        total += parseFloat(val) * (service_hours ? parseFloat(service_hours) : 0);
        total += parseFloat(kilometerPrice !== 0 ? kilometerPrice : 0) * (kilometers_driven ? parseFloat(kilometers_driven) : 0);
        total += parseFloat(drivingHoursPrice !== 0 ? drivingHoursPrice : 0) * (driving_hours ? parseFloat(driving_hours) : 0);
      }
    }
    setTotalPrice(total);
  };

  const handleKmPrice = (e, rowIndex) => {
    const kilmDriven = newPricing.map((item, i) => {
      if (i === rowIndex) {
        item.price_kilometers_driven = e.target.value;
        return item;
      }
      return item;
    });
    SetNewPricing(kilmDriven);
  };

  const handleServiceHoursPrice = (e, rowIndex) => {
    const SerHoursPrice = newPricing.map((item, i) => {
      if (i === rowIndex) {
        item.price_service_hours = e.target.value;
        return item;
      }
      return item;
    });
    SetNewPricing(SerHoursPrice);
  };

  const handleDrivingHoursPrice = (e, rowIndex) => {
    const DrivingHoursPrice = newPricing.map((item, i) => {
      if (i === rowIndex) {
        item.driving_hours_price = e.target.value;
        return item;
      }
      return item;
    });
    SetNewPricing(DrivingHoursPrice);
  };

  const handleSaveKmPrice = (rowIndex, id) => {
    let kilometerPricePerUnit = newPricing.filter((item) => item.id === id);

    let kmPrice = parseFloat(kilometerPricePerUnit[0].price_kilometers_driven);
    if (Number.isFinite(kmPrice) && kmPrice > 0) {
      const serviceHoursPrice = kilometerPricePerUnit[0].price_service_hours !== null ? kilometerPricePerUnit[0].price_service_hours : 0;
      const drivingHrsPrice = kilometerPricePerUnit[0].driving_hours_price !== null ? kilometerPricePerUnit[0].driving_hours_price : 0;
      const taskId = kilometerPricePerUnit[0].task_id;
      if (status.toUpperCase() !== 'INVOICED') {
        dispatch(
          updateKilometerHoursPrice({
            kmPrice,
            drivingHrsPrice,
            serviceHoursPrice,
            taskId: taskId,
            rowId: id,
            type,
          })
        );
      }
    }
  };

  const handleSaveServiceHrsPrice = (rowIndex, id) => {
    let hoursPricePerUnit = newPricing.filter((item) => item.id === id);

    let serviceHoursPrice = parseFloat(hoursPricePerUnit[0].price_service_hours);
    if (Number.isFinite(serviceHoursPrice) && serviceHoursPrice > 0) {
      const kmPrice = hoursPricePerUnit[0].price_kilometers_driven !== null ? hoursPricePerUnit[0].price_kilometers_driven : 0;
      const drivingHrsPrice = hoursPricePerUnit[0].driving_hours_price !== null ? hoursPricePerUnit[0].driving_hours_price : 0;
      const taskId = hoursPricePerUnit[0].task_id;
      if (status.toUpperCase() !== 'INVOICED') {
        dispatch(
          updateKilometerHoursPrice({
            kmPrice,
            drivingHrsPrice,
            serviceHoursPrice,
            taskId: taskId,
            rowId: id,
            type,
          })
        );
      }
    }
  };

  const handleSaveDrvngHrsPrice = (rowIndex, id) => {
    let drivingHoursPricePerUnit = newPricing.filter((item) => item.id === id);

    let drivingHrsPrice = parseFloat(drivingHoursPricePerUnit[0].driving_hours_price);
    if (Number.isFinite(drivingHrsPrice) && drivingHrsPrice > 0) {
      const kmPrice = drivingHoursPricePerUnit[0].price_kilometers_driven !== null ? drivingHoursPricePerUnit[0].price_kilometers_driven : 0;
      const serviceHoursPrice = drivingHoursPricePerUnit[0].price_service_hours !== null ? drivingHoursPricePerUnit[0].price_service_hours : 0;
      const taskId = drivingHoursPricePerUnit[0].task_id;
      if (status.toUpperCase() !== 'INVOICED') {
        dispatch(
          updateKilometerHoursPrice({
            kmPrice,
            drivingHrsPrice,
            serviceHoursPrice,
            taskId: taskId,
            rowId: id,
            type,
          })
        );
      }
    }
  };

  const renderSendInvoiceButton = (
    <button className='project-btn d-flex align-items-center' onClick={() => dispatch(sendInvoice({ taskId: id }))}>
      <PersonCheckSvg className='btnIcon' /> {t('button.send')}
    </button>
  );

  return (
    <div className={isLoading ? `admin-details-container loader-padding-container` : `admin-details-container`}>
      {isLoading ? (
        <div className='detail-loader'>
          <PreLoader />
        </div>
      ) : (
        <>
          <p>{t('task.details.customer')} : </p>

          <div className='details-content d-sm-flex justify-content-sm-between'>
            <div className='column'>
              <h3>
                {t('task.details.email')}: {client.email}
              </h3>
              <h3>
                {t('task.details.phone')}: {client.phone}
              </h3>
              <h3>
                {t('task.details.location')}: {Capitalize(client.address)}
              </h3>
              <h3>
                {t('task.details.zip')}: {client.zipcode}
              </h3>
              <h3>
                {t('task.details.city')}: {Capitalize(client.postal)}
              </h3>
              <h3>
                {t('task.details.invoice_address')}: {Capitalize(client.einvoicing_address)}
              </h3>
              <h3>
                {t('task.details.broker_id')}: {client.einvoicing_operator_id}
              </h3>
            </div>
            <div className='column'>
              <h3>
                {t('task.details.model_heat_pump')}: {Capitalize(product_model)}{' '}
              </h3>
              <h3>
                {t('task.details.serial_number')}: {Capitalize(product_serial)}{' '}
              </h3>
              <h3>
                {t('task.details.purchase')}: {product_purchase_date_str}{' '}
              </h3>
            </div>

            <div className='column'>
              <h3>{t('task.details.maintenence')}</h3>
              <p>{Capitalize(description)}</p>
            </div>
            <div className='column'>
              <h3>{t('task.details.urgency')}</h3>
              <p>{Capitalize(urgency)}</p>
            </div>
            {require_attention === 1 ? (
              <div className='column'>
                <h3>{t('task.details.attention_desc')}</h3>
                <h3 className='urgency require-attention'>{Capitalize(require_attention_desc)}</h3>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className='driven-container'>
            {newPricing.map((row, rowIndex) => {
              return (
                <div className='d-flex mb-md-5 justify-content-between custom-drivenkm-flex flex-wrap' key={rowIndex}>
                  <div className='column d-sm-flex justify-content-evenly row-column'>
                    <div className='field-heading'>
                      <h2>{t('task.details.hours_used')}</h2>
                    </div>
                    <div className='driven-value'>
                      {row.service_hours}
                      {t('task.details.hrs')}
                    </div>
                  </div>
                  <div className='column d-sm-flex justify-content-evenly row-column'>
                    <div className='field-heading'>
                      <h2>{t('task.details.hours_price')}</h2>
                    </div>
                    <div className=''>
                      {status.toUpperCase() === Constant.invoiced ? (
                        <span className='driven-value'>{hoursPrice}€</span>
                      ) : (
                        <React.Fragment>
                          <div className='row-column-input'>
                            <input
                              type='number'
                              onChange={(e) => {
                                // setHoursPrice(e.target.value);
                                // changeKilometerHoursPrice(
                                //   "hours",
                                //   e.target.value ? e.target.value : 0
                                // );
                                handleServiceHoursPrice(e, rowIndex);
                              }}
                              value={row.price_service_hours}
                              onBlur={(e) => {
                                // handleSaveHoursPrice(e, "hours")
                                getTotalPrice();
                                handleSaveServiceHrsPrice(rowIndex, row.id);
                              }}
                              className='input-field'
                            />
                            €
                          </div>
                          <div className='loader-container'>{type === 'hours' && hoursPriceSuccess ? '' : type === 'hours' && hoursPriceSuccess === false ? <PreLoader /> : ''}</div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className='column d-sm-flex row-column'>
                    <div className='field-heading'>
                      <h2>{t('task.details.kilometer_driven')}</h2>
                    </div>
                    <div className='driven-value'>
                      {row.kilometers_driven}
                      {t('task.details.km')}
                    </div>
                  </div>
                  <div className='column d-sm-flex justify-content-evenly row-column'>
                    <div className='field-heading'>
                      <h2>{t('task.details.kilometer_price')}</h2>
                    </div>
                    <div className=''>
                      {status.toUpperCase() === Constant.invoiced ? (
                        <span className='driven-value'>{kilometerPrice ? kilometerPrice : 0}€</span>
                      ) : (
                        <React.Fragment>
                          <div className='row-column-input'>
                            <input
                              type='number'
                              onChange={(e) => {
                                // setKilometerPrice(e.target.value);
                                // changeKilometerHoursPrice(
                                //   "kilometer",
                                //   e.target.value ? e.target.value : 0
                                // );
                                handleKmPrice(e, rowIndex);
                              }}
                              value={row.price_kilometers_driven}
                              onBlur={(e) => {
                                // handleSaveKilometerPrice(e, "kilometer")
                                getTotalPrice();
                                handleSaveKmPrice(rowIndex, row.id);
                              }}
                              className='input-field'
                            />
                            €
                          </div>
                          <div className='loader-container'>{type === 'kilometer' && hoursPriceSuccess ? '' : type === 'kilometer' && hoursPriceSuccess === false ? <PreLoader /> : ''}</div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>

                  <div className='column d-sm-flex justify-content-evenly row-column'>
                    <div className='field-heading'>
                      <h2>{t('task.details.driving_hours')}</h2>
                    </div>
                    <div className='driven-value'>
                      {row.driving_hours}
                      {t('task.details.hrs')}
                    </div>
                  </div>
                  <div className='column d-sm-flex justify-content-evenly row-column'>
                    <div className='field-heading'>
                      <h2>{t('task.details.driving_hour_price')}</h2>
                    </div>
                    <div className=''>
                      {status.toUpperCase() === Constant.invoiced ? (
                        <span className='driven-value'>{drivingHoursPrice}€</span>
                      ) : (
                        <React.Fragment>
                          <div className='row-column-input'>
                            <input
                              type='number'
                              onChange={(e) => {
                                // setDrivingHoursPrice(e.target.value);
                                // changeKilometerHoursPrice(
                                //   "driving_hours",
                                //   e.target.value ? e.target.value : 0
                                // );
                                handleDrivingHoursPrice(e, rowIndex);
                              }}
                              value={row.driving_hours_price}
                              onBlur={(e) => {
                                // handleSaveDrivingHoursPrice(e, "driving_hours")
                                getTotalPrice();
                                handleSaveDrvngHrsPrice(rowIndex, row.id);
                              }}
                              className='input-field'
                            />
                            €
                          </div>
                          <div className='loader-container'>{type === 'driving_hours' && hoursPriceSuccess ? '' : type === 'driving_hours' && hoursPriceSuccess === false ? <PreLoader /> : ''}</div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>

                  <div className='d-flex justify-content-evenly row-column-custom-width'>
                    <div className='column d-sm-flex  justify-content-evenly row-column-custom'>
                      <div className='field-heading'>
                        <h2>{t('task.details.comment_used')}</h2>
                      </div>
                      <div className='driven-value'>{row.comment}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className='product-container'>
            <h2>{t('task.details.products.heading')}</h2>
            <div className='product-content'>
              <div className='column-container'>
                {listOfProducts.length > 0
                  ? listOfProducts.map((product, index) => {
                      return (
                        <div key={index} className='column d-flex align-items-center justify-content-between flex-wrap'>
                          <div className='product-row-column'>
                            {t('task.details.products.name')}: <p>{product.name}</p>
                          </div>
                          <div className='product-row-column'>
                            {t('task.details.products.model')}: <p>{product.model}</p>
                          </div>
                          <div className='product-row-column'>
                            {t('task.details.products.quantity')}: <p>{product.pivot.quantity}</p>
                          </div>

                          <div className='product-row-column d-flex'>
                            <label className='input-label'>{t('task.details.products.set_price')}</label>
                            {status.toUpperCase() === Constant.invoiced ? (
                              <span className='driven-value'>{!product.pivot.hasOwnProperty('price') || product.pivot.price === null ? `${parseFloat(product.price)}` : `${product.pivot.price}`}€</span>
                            ) : (
                              <React.Fragment>
                                <div className='d-flex'>
                                  <input
                                    type='text'
                                    className='input-field'
                                    name={product.name}
                                    defaultValue={!product.pivot.hasOwnProperty('price') || product.pivot.price === null ? `${parseFloat(product.price)}` : `${product.pivot.price}`}
                                    onChange={(e) => changeProductCost(e, product.pivot ? product.pivot.quantity : 0)}
                                    onBlur={(e) => (isFinite(parseFloat(e.target.value)) ? (getTotalPrice(), dispatch(updateProductPrice(id, e.target.value, product.pivot.id))) : '')}
                                    onKeyUp={() => getTotalPrice()}
                                  />
                                  <span className='ml-2 d-flex align-items-center'>€</span>
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                          <div className='product-row-column'>
                            <p>{t('task.details.products.comment')}: </p>
                            <p className='align-items-center pl-1'>{product.pivot.comment}</p>
                          </div>
                          <div className='loader-container product-row-column'>{productId === product.id && productPriceSuccess ? '' : productId === product.id && productPriceSuccess === false ? <PreLoader /> : ''}</div>
                        </div>
                      );
                    })
                  : ''}
              </div>
            </div>
          </div>
          <div className='total-container d-flex justify-content-end'>
            <div>
              <div className='total-label'>
                <div className='total-label-inner'>
                  <p>{t('task.details.products.total')}</p>
                  <p className='no-tax-label'>{t('task.details.no_tax')}</p>
                </div>
                <p className='total-value'>{totalPrice.toFixed(2)}€</p>
              </div>
              {status.toUpperCase() === Constant.invoiced ? '' : renderSendInvoiceButton}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Details;
