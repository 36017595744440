import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useTable, useExpanded, useSortBy, useRowState, usePagination, useGlobalFilter } from 'react-table';
import BTable from 'react-bootstrap/Table';
import { Filter } from '../../../components/Product/Filter';
import { useTranslation } from 'react-i18next';
import './Datatable.scss';
import { useSelector, useDispatch } from 'react-redux';
import { newTaskClear } from '../../../actions/helper';

const Datatable = ({ tableClass, rowClass, globalFilterFunction, data, columns, isFiltered, isPaginated, isExpanded, renderRowSubComponent, toggle }) => {
  const [open, setOpen] = useState({});
  const [pageOpen, setPage] = useState(0);
  const [sort, setSort] = useState([]);
  const { t } = useTranslation('common');
  const loggedInUser = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const getInitialOpenedTask = () => {
    if (loggedInUser) {
      if (parseInt(loggedInUser.isAdmin) === 0) {
        const newTaskFromLocalStorage = localStorage.getItem('newTask') ? JSON.parse(localStorage.getItem('newTask')) : null;
        if (newTaskFromLocalStorage) {
          const newTaskIndx = data.findIndex((d) => d.id === newTaskFromLocalStorage.id);
          console.log({ newTaskIndx });
          if (newTaskIndx !== -1) {
            let obj = {};
            obj[newTaskIndx] = true;
            return obj;
          }
        }
      }
    }
    return open;
  };

  const stateReducer = useCallback((newState, action, prevState) => {
    if (action.type === 'toggleRowExpanded') {
      if (parseInt(loggedInUser.isAdmin) === 0) localStorage.removeItem('newTask');
      let newExpanded = newState.expanded;
      let modifiedExpnd = {};
      if (Object.keys(newExpanded).length > 0) {
        modifiedExpnd[action.id] = newExpanded[action.id];
      }
      return { ...newState, expanded: modifiedExpnd };
    }
    return newState;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    setGlobalFilter,
    visibleColumns,
    state: { expanded, pageIndex, globalFilter, sortBy },
  } = useTable(
    data
      ? {
          columns,
          data,
          initialState: {
            expanded: getInitialOpenedTask(),
            pageIndex: pageOpen,
            sortBy: sort,
          },
          stateReducer,
          globalFilter: globalFilterFunction,
        }
      : { columns },
    useRowState,
    useGlobalFilter,
    useSortBy,
    isExpanded ? useExpanded : '',
    usePagination
  );

  useEffect(() => {
    setOpen(expanded);
  }, [expanded]);

  useEffect(() => {
    if (pageIndex) {
      setPage(pageIndex);
    }
    if (globalFilter) {
      setPage(0);
    }
  }, [pageIndex, globalFilter]);

  useEffect(() => {
    setSort(sortBy);
  }, [sortBy]);

  useEffect(() => {
    dispatch(newTaskClear());

    return () => {
      if (parseInt(loggedInUser.isAdmin) === 0) localStorage.removeItem('newTask');
    };
  }, []);

  return (
    <div>
      {isFiltered ? <Filter filter={globalFilter} setFilterData={setGlobalFilter} /> : ''}
      <div>
        <BTable className={tableClass} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr className='list-header' {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      className={`header-column ${column.render('Header')}`}
                      {...column.getHeaderProps([
                        {
                          className: `header-column ${column.className}`,
                        },
                        column.getSortByToggleProps(),
                      ])}
                    >
                      <div className='dark-border'>
                        {column.render('Header')}
                        <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isPaginated
              ? page.map((row, i) => {
                  prepareRow(row);
                  return (
                    // Use a React.Fragment here so the table markup is still valid
                    <React.Fragment key={row.index}>
                      {row.isExpanded ? (
                        <tr className={expanded && expanded[row.index] === true ? `expanded-column content-td list-content` : `content-td`} {...row.getRowProps()}>
                          <td className='collapse-row' colSpan={visibleColumns.length}>
                            <div className='d-flex align-items-center custom-row'>
                              {row.cells.map((cell) => {
                                return (
                                  <div className='custom-cell-container' colSpan={visibleColumns.length} {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </div>
                                );
                              })}
                            </div>
                            {renderRowSubComponent({ row, toggle })}
                          </td>
                        </tr>
                      ) : (
                        <tr className='list-content' {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td className={expanded && expanded[row.index] === true ? `expanded-column content-td` : `content-td`} {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              : rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    // Use a React.Fragment here so the table markup is still valid
                    <React.Fragment key={row.index}>
                      <tr className={rowClass ? `${rowClass}` : `list-content`} {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td className={expanded && expanded[row.index] === true ? `expanded-column content-td` : `content-td`} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded ? (
                        <tr className={rowClass ? `${rowClass} collapse-content` : `collapse-content`}>
                          <td className='collapse-row' colSpan={visibleColumns.length}>
                            {renderRowSubComponent({
                              row,
                              ...row.getToggleRowExpandedProps(),
                              toggle,
                            })}
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
          </tbody>
        </BTable>
      </div>
      {isPaginated ? (
        <div>
          <div className='pagination-container'>
            <span>
              {t('datatable.page')}{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {/* {t('datatable.prev')} */}
                <div className='svg-container'>
                  <svg className={!canPreviousPage ? 'prev prev-disabled' : 'prev'} version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 541.07 541.071' style={{ background: 'new 0 0 541.07 541.071' }}>
                    <path
                      className='svg-arrow'
                      d='M1.792,277.575l142.611,162.657c2.39,4.779,8.66,8.653,14.002,8.653H290.32c5.343,0,7.738-3.874,5.349-8.653
                                                L153.058,277.575c-2.39-4.78-2.39-12.531,0-17.311l142.611-159.432c2.39-4.78-0.006-8.654-5.349-8.654H158.406
                                                c-10.019,0-16.181,10.891-21.934,17.531C128.859,118.498,23.148,236.387,1.79,260.264C-0.597,265.047-0.597,272.794,1.792,277.575
                                                z'
                    />
                    <path
                      className='svg-arrow'
                      d='M540.145,100.835c2.39-4.78-0.006-8.654-5.349-8.654c0,0-131.914,0-131.917,0c-12.213,0.003-28.259,24.593-36.228,33.504
                                                c-14.021,15.673-119.618,133.052-120.383,134.585c-2.39,4.78-2.39,12.531,0,17.311l142.611,162.657
                                                c2.391,4.78,8.66,8.654,14.003,8.654h131.914c5.343,0,7.738-3.874,5.349-8.654L397.533,277.581c-2.39-4.779-2.39-12.531,0-17.311
                                                L540.145,100.835z'
                    />
                  </svg>
                </div>
              </button>
              &nbsp; &nbsp;
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {/* {t('datatable.next')} */}
                <div className='svg-container'>
                  <svg className={!canNextPage ? 'next next-disabled' : 'next'} version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 480.026 480.026' style={{ background: 'new 0 0 480.026 480.026' }}>
                    <path
                      className='svg-arrow'
                      d='M475.922,229.325l-144-160c-3.072-3.392-7.36-5.312-11.904-5.312h-96c-6.304,0-12.032,3.712-14.624,9.472
                                                c-2.56,5.792-1.504,12.544,2.72,17.216l134.368,149.312l-134.368,149.28c-4.224,4.704-5.312,11.456-2.72,17.216
                                                c2.592,5.792,8.32,9.504,14.624,9.504h96c4.544,0,8.832-1.952,11.904-5.28l144-160
                                                C481.394,244.653,481.394,235.373,475.922,229.325z'
                    />
                    <path
                      className='svg-arrow'
                      d='M267.922,229.325l-144-160c-3.072-3.392-7.36-5.312-11.904-5.312h-96c-6.304,0-12.032,3.712-14.624,9.472
                                                c-2.56,5.792-1.504,12.544,2.72,17.216l134.368,149.312L4.114,389.293c-4.224,4.704-5.312,11.456-2.72,17.216
                                                c2.592,5.792,8.32,9.504,14.624,9.504h96c4.544,0,8.832-1.952,11.904-5.28l144-160
                                                C273.394,244.653,273.394,235.373,267.922,229.325z'
                    />
                  </svg>
                </div>
              </button>
            </span>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Datatable;
