import Api from '../Api';
import moment from 'moment';
import { history } from '../router/history';
import { store } from '../index';
export * from './products';

export const login = ({ username, password }) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      dispatch({ type: 'API_IS_FETCHING' });
      Api.post(`login`, {
        email: username,
        password: password,
      })
        .then((response) => {
          dispatch(fetchUser());
          dispatch({ type: 'SUCCESS', message: 'Successfully Logged In !' });
          history.push('/tasks');
        })
        .catch((errors) => {
          dispatch({ type: 'API_FETCHED' });
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Kirjautuminen epäonnistui',
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          }
        });
    });
  };
};

export const fetchTasks = () => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      dispatch({ type: 'API_IS_FETCHING' });
      Api.get(`api/tasks`)
        .then((response) => {
          dispatch({ type: 'TASKS', payload: response.data });
          // dispatch(fetchUsers())
          // dispatch(fetchProduct())
          dispatch({ type: 'API_FETCHED' });
        })
        .catch((errors) => {
          dispatch({ type: 'API_FETCHED' });
          dispatch({
            type: 'ERROR',
            status: errors.response.status,
            message: errors.response.data,
          });
        });
    });
  };
};

export const createTask = (data) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then(() => {
      dispatch({ type: 'API_IS_FETCHING' });
      Api.post(`api/tasks/create`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          const states = store.getState();
          const { login } = states;
          if (parseInt(login.isAdmin) === 0) localStorage.setItem('newTask', JSON.stringify(res.data));
          dispatch({ type: 'NEW_TASK_SUCCESS', payload: res.data });
          dispatch({ type: 'TASK_CREATE_SUCCESS', payload: res.data });
          dispatch({ type: 'SUCCESS', message: 'Task added successfully!' });
          dispatch({ type: 'API_FETCHED' });
          dispatch({ type: 'RESET_SUCCESS_RESPONSE' });
        })
        .catch((errors) => {
          dispatch({ type: 'API_FETCHED' });
          dispatch({
            type: 'ERROR',
            status: errors.response.status,
            message: errors.response?.data?.message ? errors.response.data.message : 'Something Went Wrong',
          });
        });
    });
  };
};

export const assignTask = (taskId, employee) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      dispatch({ type: 'ASSIGN_TASK_FETCHING', payloads: { id: taskId } });
      Api.post(`api/tasks/${taskId}/assign`, {
        employee,
      })
        .then((response) => {
          dispatch({ type: 'TASK_UPDATE', payload: response });
          dispatch({
            type: 'SUCCESS',
            message: 'Successfully Assigned Task !!',
          });
          dispatch({ type: 'ASSIGN_TASK_FETCHED', payloads: { id: taskId } });
        })
        .catch((errors) => {
          dispatch({ type: 'ASSIGN_TASK_FETCHED', payloads: { id: taskId } });
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          }
        });
    });
  };
};

export const fetchUser = () => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      Api.get('api/user')
        .then((response) => {
          dispatch(setUserDetailsAfterLogin(response.data));
          dispatch(fetchTasks());
        })
        .catch((error) => {
          dispatch(isLoggedOut());
        });
    });
  };
};

export const fetchEmployees = () => {
  return (dispatch) => {
    dispatch({ type: 'API_IS_FETCHING' });
    Api.get(`api/users`).then((response) => {
      dispatch(setEmployees(response.data));
      dispatch({ type: 'API_FETCHED' });
    });
  };
};

export const removeAccount = (payloads) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      if (payloads.type === 'employee') {
        Api.delete(`api/employees/${payloads.id}`)
          .then((response) => {
            dispatch({
              type: 'SUCCESS',
              message: 'Successfully Removed Account !!',
            });
            dispatch(logout());
          })
          .catch((errors) => {
            if (errors.response.status === 422) {
              dispatch({
                type: 'ERROR',
                status: errors.response.status,
                message: errors.response.data,
              });
            } else {
              dispatch({
                type: 'ERROR',
                status: errors.response.status,
                message: 'Something Went Wrong',
              });
            }
          });
      } else {
        Api.delete(`api/administrators/${payloads.id}`)
          .then((response) => {
            dispatch({
              type: 'SUCCESS',
              message: 'Successfully Removed Account !!',
            });
            dispatch(logout());
          })
          .catch((errors) => {
            if (errors.response.status === 422) {
              dispatch({
                type: 'ERROR',
                status: errors.response.status,
                message: errors.response.data,
              });
            } else {
              dispatch({
                type: 'ERROR',
                status: errors.response.status,
                message: 'Something Went Wrong',
              });
            }
          });
      }
    });
  };
};

export const updateEmployee = (payloads) => {
  const fd = new FormData();
  fd.append('name', `${payloads.name}`);
  fd.append('address', payloads.address);
  fd.append('phone', payloads.phone);
  fd.append('company', payloads.company);
  fd.append('zip', payloads.zip);
  fd.append('city', payloads.city);
  fd.append('vat', payloads.vat);
  fd.append('area', payloads.area);
  fd.append('is_admin', payloads.admin);
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      dispatch({ type: 'API_IS_FETCHING' });
      Api.post(`api/employees/${payloads.id}?_method=PUT`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          dispatch({
            type: 'EMPLOYEE_SUCCESS',
            message: 'Successfully Updated !!',
          });
          dispatch({ type: 'UPDATE_EMPLOYEE_DETAIL', payloads: response.data });
          dispatch({ type: 'RESET_VALIDATION_ERROR' });
          dispatch({ type: 'API_FETCHED' });
        })
        .catch((errors) => {
          dispatch({ type: 'API_FETCHED' });
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const updateAccount = (payloads) => {
  const fd = new FormData();
  fd.append('name', `${payloads.name}`);
  fd.append('address', payloads.address);
  fd.append('phone', payloads.phone);
  fd.append('city', payloads.city);
  fd.append('zip', payloads.zip);
  fd.append('company', payloads.company);
  fd.append('vat', payloads.vat);
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      dispatch({
        type: 'PROFILE_UPDATE_API_FETCHING',
        payload: { type: 'profile' },
      });
      if (payloads.type === 'employee') {
        Api.post(`api/employees/${payloads.id}?_method=PUT`, fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            dispatch({ type: 'SUCCESS', message: 'Successfully Updated !!' });
            if (payloads.image) {
              dispatch(updateAvatar(payloads.image));
            } else {
              dispatch({
                type: 'PROFILE_UPDATE_API_FETCHED',
                payload: { type: 'profile' },
              });
              dispatch(setUserDetailsAfterLogin(response.data));
            }
          })
          .catch((errors) => {
            dispatch({
              type: 'PROFILE_UPDATE_API_FETCHED',
              payload: { type: 'profile' },
            });
            if (errors.response.status === 422) {
              dispatch({
                type: 'ERROR',
                status: errors.response.status,
                message: errors.response.data,
              });
            } else {
              dispatch({
                type: 'ERROR',
                status: errors.response.status,
                message: 'Something Went Wrong',
              });
            }
          });
      } else {
        fd.append('area', payloads.area);
        Api.post(`api/administrators/${payloads.id}?_method=PUT`, fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            dispatch({ type: 'SUCCESS', message: 'Successfully Updated !!' });
            if (payloads.image) {
              dispatch(updateAvatar(payloads.image));
            } else {
              dispatch({
                type: 'PROFILE_UPDATE_API_FETCHED',
                payload: { type: 'profile' },
              });
              dispatch(setUserDetailsAfterLogin(response.data));
            }
          })
          .catch((errors) => {
            dispatch({
              type: 'PROFILE_UPDATE_API_FETCHED',
              payload: { type: 'profile' },
            });
            if (errors.response.status === 422) {
              dispatch({
                type: 'ERROR',
                status: errors.response.status,
                message: errors.response.data,
              });
            } else {
              dispatch({
                type: 'ERROR',
                status: errors.response.status,
                message: 'Something Went Wrong',
              });
            }
          });
      }
    });
  };
};

export const updateAvatar = (image) => {
  const fd = new FormData();
  fd.append('photo', image);
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      Api.post(`api/avatar`, fd)
        .then((response) => {
          dispatch({ type: 'RESET_VALIDATION_ERROR' });
          dispatch(setUserDetailsAfterLogin(response.data));
          dispatch({
            type: 'PROFILE_UPDATE_API_FETCHED',
            payload: { type: 'profile' },
          });
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            dispatch({
              type: 'PROFILE_UPDATE_API_FETCHED',
              payload: { type: 'profile' },
            });
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something went rrong while updating avatar',
            });
          }
        });
    });
  };
};

export const setUserDetailsAfterLogin = (payloads) => {
  return {
    type: 'SET_USER_DETAIL_AFTER_LOGIN',
    payloads,
  };
};

export const removeTaskProduct = ({ taskProductId, taskId }) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      Api.delete(`api/tasks/${taskId}/task-products/${taskProductId}`)
        .then((response) => {
          dispatch({
            type: 'SUCCESS',
            message: 'Product Deleted from Task !!',
          });
          dispatch({
            type: 'TASK_PRODUCT_REMOVED',
            payload: { taskId, taskProductId },
          });
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const updateTaskProduct = (payloads) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      Api.put(`api/tasks/${payloads.taskId}/products`, {
        product_id: payloads.productId,
        quantity: payloads.quantity,
      })
        .then((response) => {
          dispatch({
            type: 'SUCCESS',
            message: 'Successfully Updated Product !!',
          });
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else if (errors.response.status === 403) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const register = (payloads) => {
  // return {
  //     type: "REGISTER",
  //     payloads
  // }
  return (dispatch) => {
    Api.get('sanctum/csrf-cookie').then((response) => {
      const fd = new FormData();
      fd.append('photo', payloads.image);
      fd.append('name', `${payloads.firstname} ${payloads.lastname}`);
      fd.append('email', payloads.email);
      fd.append('address', payloads.address);
      fd.append('serviceArea', payloads.serviceArea);
      fd.append('password', payloads.password);
      fd.append('confirmPassword', payloads.confirmPassword);
      fd.append('token', payloads.token);
      fd.append('phone', payloads.phone);
      fd.append('city', payloads.city);
      fd.append('zip', payloads.zip);
      fd.append('vat', payloads.vat);
      fd.append('company', payloads.company);

      dispatch({ type: 'API_IS_FETCHING' });
      Api.post('api/register', fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          dispatch({ type: 'SUCCESS', message: 'Successfully Registered!' });
          dispatch({ type: 'REGISTER' });
          window.location = '/login';
        })
        .catch((errors) => {
          dispatch({ type: 'API_FETCHED' });
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const fetchNotifications = () => {
  return (dispatch) => {
    Api.get('sanctum/csrf-cookie').then((response) => {
      dispatch({ type: 'API_IS_FETCHING' });
      Api.get('api/all-notifications')
        .then((response) => {
          dispatch({ type: 'NOTIFICATIONS', payloads: response });
          dispatch({ type: 'API_FETCHED' });
        })
        .catch((errors) => {
          dispatch({ type: 'API_FETCHED' });
          dispatch({
            type: 'ERROR',
            status: errors.response.status,
            message: 'Something went wrong while fetching notifications',
          });
        });
    });
  };
};

export const requestForAttention = ({ require_attention, require_attention_desc, taskId }) => {
  return (dispatch) => {
    Api.get('sanctum/csrf-cookie').then((response) => {
      // dispatch({type: 'API_IS_FETCHING'})
      dispatch({
        type: 'REQUEST_ATTENTION_API_FETCHING',
        payload: { type: taskId },
      });
      Api.post(`api/tasks/${taskId}/attention`, {
        require_attention,
        require_attention_desc,
      })
        .then((response) => {
          // dispatch({type: 'API_FETCHED'})
          dispatch({ type: 'SUCCESS', message: 'Requested for attention !!' });
          dispatch({
            type: 'REQUEST_ATTENTION',
            payloads: { taskId, require_attention },
          });
          dispatch({
            type: 'REQUEST_ATTENTION_API_FETCHED',
            payload: { type: taskId },
          });
        })
        .catch((errors) => {
          dispatch({
            type: 'REQUEST_ATTENTION_API_FETCHED',
            payload: { type: taskId },
          });
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const markAllNotificationsRead = () => {
  return (dispatch) => {
    Api.get('sanctum/csrf-cookie').then((response) => {
      dispatch({ type: 'API_IS_FETCHING' });
      Api.get('api/mark-all-read')
        .then((response) => {
          dispatch({
            type: 'SUCCESS',
            message: 'Marked Read to all notifications !!',
          });
          dispatch({ type: 'NOTIFICATIONS', payloads: response });
          dispatch({ type: 'API_FETCHED' });
        })
        .catch((errors) => {
          dispatch({ type: 'API_FETCHED' });
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const markSingleNotificationsRead = (notificationId) => {
  return (dispatch) => {
    Api.get('sanctum/csrf-cookie').then((response) => {
      dispatch({
        type: 'SINGLE_NOTIFICATION_READ_FETCHING',
        payloads: { id: notificationId },
      });
      Api.get(`api/mark-read/${notificationId}`)
        .then((response) => {
          dispatch({ type: 'SUCCESS', message: 'Marked Read!' });
          dispatch({
            type: 'UPDATE_NOTIFICATION',
            payloads: { notificationId, data: response.data },
          });
          dispatch({
            type: 'SINGLE_NOTIFICATION_READ_FETCHED',
            payloads: { id: notificationId },
          });
        })
        .catch((errors) => {
          dispatch({
            type: 'SINGLE_NOTIFICATION_READ_FETCHED',
            payloads: { id: notificationId },
          });
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(isLoggedOut());
    Api.post('logout')
      .then(() => {
        dispatch({ type: 'SUCCESS', message: 'Successfully Logged Out !' });
      })
      .catch((errors) => {
        dispatch({
          type: 'ERROR',
          status: errors.response.status,
          message: errors.response.data,
        });
      });
  };
};

export const isLoggedOut = () => {
  return {
    type: 'IS_LOGGED_OUT',
  };
};

export const sessionExpired = () => {
  return {
    type: 'IS_SESSION_EXPIRED',
  };
};

export const invite = (payloads) => {
  return (dispatch) => {
    dispatch({ type: 'API_IS_FETCHING' });
    Api.post('api/invite', {
      email: payloads.email,
      area: payloads.area,
      is_admin: payloads.admin,
    })
      .then(() => {
        dispatch(sentInvite);
        dispatch({
          type: 'SUCCESS',
          message: 'Successfully Sent Invitation !',
        });
        dispatch({ type: 'API_FETCHED' });
      })
      .catch((errors) => {
        dispatch({ type: 'API_FETCHED' });
        if (errors.response.status === 422) {
          dispatch({
            type: 'ERROR',
            status: errors.response.status,
            message: errors.response.data,
          });
        } else {
          dispatch({
            type: 'ERROR',
            status: errors.response.status,
            message: 'Something Went Wrong',
          });
        }
      });
  };
};

export const reinvite = (email) => (dispatch) => {
  dispatch({ type: 'API_IS_FETCHING' });
  Api.post('api/re-invite', {
    email: email,
  })
    .then(() => {
      dispatch(sentInvite);
      dispatch({
        type: 'SUCCESS',
        message: 'Successfully Sent Invitation !',
      });
      dispatch({ type: 'API_FETCHED' });
    })
    .catch((errors) => {
      dispatch({ type: 'API_FETCHED' });
      dispatch({
        type: 'ERROR',
        status: errors.response.status,
        message: errors.response?.data?.message?.email ? errors.response.data.message.email[0] : 'Something Went Wrong !',
      });
    });
};

export const sentInvite = () => {
  return {
    type: 'SENT_INVITE',
  };
};

export const fetchUsers = () => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      // dispatch({type: 'API_IS_FETCHING'})
      Api.get('api/employees').then((response) => {
        dispatch(setEmployees(response.data));
        // dispatch({type: 'API_FETCHED'})
      });
    });
  };
};

export const setEmployees = (payloads) => {
  return {
    type: 'SET_EMPLOYEES',
    payloads,
  };
};

export const deleteEmployees = (employeeId) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      Api.delete(`api/users/${employeeId}`)
        .then((response) => {
          dispatch({
            type: 'SUCCESS',
            message: 'Successfully Deleted Employee !!',
          });
          dispatch({ type: 'REMOVE_EMPLOYEE_LIST', employeeId });
        })
        .catch((errors) => {
          dispatch({
            type: 'ERROR',
            status: errors.response.status,
            message: errors.response?.data?.message ? errors.response.data.message : 'Something Went Wrong',
          });
          // if (errors.response.status === 422) {
          //   dispatch({
          //     type: 'ERROR',
          //     status: errors.response.status,
          //     message: errors.response.data,
          //   });
          // } else {
          //   dispatch({
          //     type: 'ERROR',
          //     status: errors.response.status,
          //     message: 'Something Went Wrong',
          //   });
          // }
        });
    });
  };
};

export const updateProductPrice = (taskId, price, task_product_id) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      dispatch({
        type: 'PRODUCT_PRICE_API_FETCHING',
        payload: { id: task_product_id },
      });
      Api.put(`api/tasks/${taskId}/task-products`, {
        task_product_id,
        price,
      })
        .then((response) => {
          dispatch({
            type: 'SUCCESS',
            message: 'Successfully Updated Price !!',
          });
          dispatch({
            type: 'TASK_PRODUCT_PRICE_UPDATE',
            payload: { taskId, price, task_product_id },
          });
          dispatch({
            type: 'PRODUCT_PRICE_API_FETCHED',
            payload: { id: task_product_id },
          });
        })
        .catch((errors) => {
          dispatch({
            type: 'PRODUCT_PRICE_API_FETCHED',
            payload: { id: task_product_id },
          });
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const updateKilometerHoursPrice = ({ kmPrice, drivingHrsPrice, serviceHoursPrice, taskId, rowId, type }) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      dispatch({ type: 'HOURS_PRICE_API_FETCHING', payload: { type } });
      Api.post(`api/tasks/${taskId}/pricing`, {
        price_kilometers_driven: kmPrice,
        driving_hours_price: drivingHrsPrice,
        price_timing: serviceHoursPrice,
        task_kilometer_service_driving_hours_id: rowId,
      })
        .then((response) => {
          dispatch({
            type: 'SUCCESS',
            message: 'Pricing Updated Sucessfully !!',
          });
          dispatch({
            type: 'TASK_KILOMETER_HOURS_PRICE_UPDATED',
            payload: { res: response.data, rowId: rowId },
          });
          dispatch({ type: 'HOURS_PRICE_API_FETCHED', payload: { type } });
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const addTaskProduct = (payloads) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      dispatch({
        type: 'PRODUCT_PRICE_API_FETCHING',
        payload: { id: payloads.productId },
      });
      Api.post(`api/tasks/${payloads.taskId}/products`, {
        product_id: payloads.productId,
        quantity: payloads.quantity,
        comment: payloads.comment,
        task_product_id: payloads.task_product_id ? payloads.task_product_id : null,
      })
        .then((response) => {
          dispatch({
            type: 'SUCCESS',
            message: 'Successfully Added Product !!',
          });
          dispatch({
            type: 'TASK_PRODUCT_ADDED',
            payload: {
              response,
              taskId: payloads.taskId,
              quantity: payloads.quantity,
              comment: payloads.comment,
              task_product_id: payloads.task_product_id,
            },
          });
          dispatch({
            type: 'PRODUCT_PRICE_API_FETCHED',
            payload: {
              id: payloads.productId,
              taskProductId: response.data.task_product_id,
            },
          });
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            dispatch({
              type: 'PRODUCT_PRICE_API_FETCHED',
              payload: {
                id: payloads.productId,
                taskProductId: response.data.task_product_id,
              },
            });
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else if (errors.response.status === 403) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const updateTaskTime = (payloads) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      Api.post(`api/tasks/${payloads.taskId}/timings/manual`, {
        started_at: moment(payloads.started_at).utc().format('YYYY-MM-DD HH:mm:ss'),
        completed_at: moment(payloads.completed_at).utc().format('YYYY-MM-DD HH:mm:ss'),
      })
        .then((response) => {
          payloads.data = response.data;
          dispatch({
            type: 'SUCCESS',
            message: 'Successfully Updated Time !!',
          });
          dispatch({ type: 'TIME_UPDATED', payloads });
          dispatch({
            type: 'TASKID_BY_TIME',
            payload: { taskId: payloads.taskId },
          });
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else if (errors.response.status === 403) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const deleteKmServiceDriven = (payloads) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      Api.delete(`api/tasks/${payloads.taskId}/kilometer-hours/${payloads.id}`)
        .then((response) => {
          dispatch({
            type: 'DELETE_KMSERVICEDRIVEN_TASK',
            payload: { id: payloads.id, taskId: payloads.taskId },
          });
          dispatch({ type: 'SUCCESS', message: 'Successfully Deleted!!' });
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else if (errors.response.status === 403) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const markTaskComplete = (payloads) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      dispatch({ type: 'API_IS_FETCHING' });
      Api.post(`/api/tasks/${payloads.taskId}/complete`)
        .then((response) => {
          dispatch({
            type: 'SUCCESS',
            message: 'Task Completed Successfully!!',
          });
          dispatch({ type: 'TASK_COMPLETED', payloads });
          dispatch({ type: 'API_FETCHED' });
        })
        .catch((errors) => {
          dispatch({ type: 'API_FETCHED' });
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else if (errors.response.status === 403) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const updateTaskVisitsAndProducts = (payloads) => (dispatch) => {
  Api.get(`sanctum/csrf-cookie`).then(() => {
    dispatch({ type: 'TASK_VISIT_AND_PRODUCTS_ACTION_INPROGRESS' });
    Promise.all([...payloads.visitsToBeUpdated.map((visitToBeUpdated) => Api.post(`api/tasks/${payloads.taskId}/kilometer-hours`, visitToBeUpdated)), ...payloads.productsToBeUpdated.map((productToBeUpdated) => Api.post(`api/tasks/${payloads.taskId}/products`, productToBeUpdated))])
      .then(() => {
        Api.get(`api/tasks`).then((response) => {
          dispatch({ type: 'TASKS', payload: response.data });
          dispatch({
            type: 'SUCCESS',
            message: 'Task Updated Successfully !',
          });
          dispatch({ type: 'TASK_VISIT_AND_PRODUCTS_ACTION_COMPLETED' });
        });
      })
      .catch((err) => {
        dispatch({
          type: 'ERROR',
          status: err?.response?.status ?? 500,
          message: err?.response?.data ?? 'Something went wrong !',
        });
        dispatch({ type: 'TASK_VISIT_AND_PRODUCTS_ACTION_COMPLETED' });
      });
  });
};

export const updateTask = (payloads) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      dispatch({
        type: 'HOURS_PRICE_API_FETCHING',
        payload: { type: payloads.type },
      });
      Api.post(`api/tasks/${payloads.taskId}/kilometer-hours`, {
        kilometers_driven: payloads.kilometerDriven,
        service_hours: payloads.service_hours,
        driving_hours: payloads.drivingHours,
        comment: payloads.comment,
        task_kilometer_service_driving_hours_id: payloads.task_kilometer_service_driving_hours_id ? payloads.task_kilometer_service_driving_hours_id : null,
        price: payloads.price,
      })
        .then((response) => {
          dispatch({ type: 'SUCCESS', message: 'Successfully Updated !!' });
          dispatch({
            type: 'KILOMETER_HOUR_UPDATED',
            payloads: {
              data: response.data,
              taskId: payloads.taskId,
            },
          });
          dispatch({
            type: 'HOURS_PRICE_API_FETCHED',
            payload: { type: payloads.type },
          });
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else if (errors.response.status === 403) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const deleteTask = (payloads) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      dispatch({ type: 'DELETE_TASK', payload: { taskId: payloads.taskId } });
      Api.delete(`api/tasks/${payloads.taskId}`, {
        taskId: payloads.taskId,
      })
        .then((response) => {
          dispatch({ type: 'SUCCESS', message: 'Successfully Deleted!!' });
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else if (errors.response.status === 403) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};

export const sendInvoice = (payloads) => {
  return (dispatch) => {
    Api.get(`sanctum/csrf-cookie`).then((response) => {
      dispatch({ type: 'API_IS_FETCHING' });
      Api.get(`api/send-invoice/${payloads.taskId}`)
        .then((response) => {
          dispatch({ type: 'SUCCESS', message: response.data.message });
          dispatch({ type: 'API_FETCHED' });
          dispatch({
            type: 'INVOICE_SENT',
            payload: { id: payloads.taskId, status: 'Invoiced' },
          });
        })
        .catch((errors) => {
          dispatch({ type: 'API_FETCHED' });
          if (errors.response.status === 422) {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: errors.response.data,
            });
          } else {
            dispatch({
              type: 'ERROR',
              status: errors.response.status,
              message: 'Something Went Wrong',
            });
          }
        });
    });
  };
};
